<template lang="pug">
  #dashboardVentasSinActividad
    .container.box-1
      .row
        .col-sm-6
          bk-search(@toSearch="toSearch")
        .col-sm-2
          .dropdown
            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Filtrar:] {{order}}
              i(class="fa fa-angle-down" aria-hidden="true")
            ul(class="dropdown-menu" aria-labelledby="ddOrder")
              li(v-for="(value, key) in aOrder" :class="{'disabled': order == value}")
                span(:value="key" @click="changeFiltro(key,value)") {{value}}
        .col-sm-4
          .dropdown
            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="yyOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true")
              i(class="fa fa-calendar" aria-hidden="true")
              strong {{filterDate}}
              i(class="fa fa-angle-down" aria-hidden="true")
            ul(class="dropdown-menu" aria-labelledby="yyOrder")
              li(v-for="(value, key) in aFilterDate" :class="{'disabled': order == value}")
                span(:value="key" @click="changeFilterDate(key,value)") {{value}}
    .container.box-2
      .row
        .col-sm-12
          bk-grid(
            :clients="aClient"
            :message="message"
            :pages="totalPages"
            :count="clientsNum"
            :currentPage="currentPage"
            :type="typeGrid"
            @toPaginate="toPagChange")
</template>

<script>
import BkSearch from '@/components/dashboard/Search.vue'
import BkGrid from '@/components/dashboard/Listado.vue'

export default {
  name: 'DashboardVentasRanking',
  components: {BkSearch, BkGrid},
  data () {
    return {
      'query': '', // Donde se guarla la palabra a buscar.
      'message': null,
      'typeGrid': 'sinActividad',
      'aClient': [ // []
        {
          'name': '1 - NOMBRE CLIENTE',
          'objetivo': '46.000€',
          'ventas': '44.556€',
          'ventas_anteriores': '35.698€'
        },
        {
          'name': '2 - NOMBRE CLIENTE',
          'objetivo': '46.000€',
          'ventas': '44.556€',
          'ventas_anteriores': '35.698€'
        }
      ],
      'clientsNum': 2, // null,
      'totalPages': 0,
      'currentPage': 0, // Para saber porque página vamos en la paginación
      'order': this.$gettext('Todos'),
      'aOrder': {
        'order_1': this.$gettext('Todos'),
        'order_2': this.$gettext('Activos'),
        'order_3': this.$gettext('Nuevos')
      },
      'filterDate': this.$gettext('Enero 2017 - Enero 2018'),
      'aFilterDate': {
        'order_1': this.$gettext('Enero 2015 - Enero 2016'),
        'order_2': this.$gettext('Enero 2016 - Enero 2017'),
        'order_3': this.$gettext('Enero 2017 - Enero 2018')
      }
    }
  },
  methods: {
    changeFiltro (key, value) {
      console.log(`changeFiltro: ${key}`)
      this.order = value
      // this.$emit('toChangeOrdering', [key])
    },
    changeFilterDate (key, value) {
      console.log(`changeFilterDate: ${key}`)
      this.filterDate = value
      // this.$emit('toChangeOrdering', [key])
    },
    toSearch (query) {
      console.log('query:' + query)
      // this.query = query
      // this.search(this.query, 1)
    },
    toPagChange (pageNum) {
      // console.log(pageNum)
      this.search(this.query, pageNum)
    }
  }
}
</script>

<style lang="scss" scoped>
  .box-1{
    padding-top: 30px;
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    // min-width: 200px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 38px;
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: $blue-light;

      span{
        color: #494949;
      }

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }
</style>
