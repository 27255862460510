<template>
  <img v-if="type" :src="logo[type]" alt="" />
</template>

<script>
export default {
  name: "GovLogo",
  data() {
    return {
      logo: {
        prisma: require('./gov-logo-1.jpg'),
        dicas: require('./gov-logo-2.svg'),
        maripol: require('./gov-logo-2.svg')
      }
    }
  },
  computed: {
    type()  {
      return this.$config.type
    }
  }
}
</script>

<style scoped>

</style>
