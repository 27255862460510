<template lang="pug">
  .favorite-list
    .box-1
      .container
        .row
          .col-sm-12 #[translate Favorite products]: {{favoriteCount}} #[translate products]
    .box-2(v-if="!favoriteCount")
      .container
        .row
          .col-sm-12.holder-cn(:style="img.bg")
            p.img-cn
              img(:src="img.ico")
            p No favorite products
            p You can add them from the Catalog
    div(v-else)
      .box-3
        .container
          .row-flex
            .item-cn(v-for="product in favoritesItems" :key="product.obj.id")
              bk-item( :product="product.obj" @toAddCart="toAddCart" @toGoProduct="toGoProduct" @toChangeQuantity="toChangeQuantity")
      .box-4
        .container
          .row
            .col-sm-12
              p
                button(type="button" class="btn btn-cancel" @click="removeAll()") #[translate Remove all products]
                button(type="button" class="btn btn-orange" @click="AddAllCart()") #[translate Add all products to cart]
      
    bk-modal( id="modal-favorites-list" :title="modal.title" :content="modal.content" :footer="modal.footer")
    p.hidden
      button(data-toggle="modal" data-target="#modal-favorites-list" class="btn-modal" ref="showModal")
</template>
<script>
import BkItem from '@/components/catalog/Item.vue'
import BkModal from '@/components/common/Modal.vue'

export default {
  name: 'FavoritesList',
  components: { BkItem, BkModal },
  data () {
    return {
      img: {
        ico: require('@/assets/img/icos/star-empty.svg'),
        bg: `background-image:url(${require('@/assets/img/favorite-bg.svg')})`
      },
      modal: {
        // show: false,
        title: '',
        content: '',
        footer: ''
      }
    }
  },
  created () {
  },
  computed: {
    client () {
      return this.$store.getters.client
    },
    favoriteCount () {
      return this.$store.getters.favoritesCount
    },
    favoritesItems () {
      return this.$store.getters.favoritesItems
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {
    toAddCart (item) {
      // Añadir al carrito
      this.$store.dispatch('addCart', item)
    },
    toGoProduct (id) {
      // Ir al detalle del producto
      this.$router.push({'name': 'productSingle', 'params': {'id': id.toString()}})
    },
    toChangeQuantity (obj) {
      // console.log(`quantity: ${obj.quantity}, idProduct:`, obj.idProduct)
      this.favoritesItems.filter(function (item) {
        if (item.obj.id === obj.idProduct) {
          item.obj.quantity = obj.quantity
        }
      })
    },
    removeAll () {
      // Eliminar todos los favoritos.
      this.$store.dispatch('removeAllFavorites')
    },
    AddAllCart () {
      if (this.client) {
        // Añadir todos los productos al carrito y nos vamos al carro.
        // añadimos los productos a la cesta y nos vamos al carro.
        this.favoritesItems.map((item, index) => {
          // console.log(`product:`, item.obj)
          if (!item.obj.quantity) {
            item.obj.quantity = 1
          }
          this.$store.dispatch('addCart', {idProduct: item.obj.id, quantity: parseInt(item.obj.quantity), product: item.obj})
        })
        this.$router.push({'name': 'cartList'})
      } else {
        const msg = this.$gettext('To add the products you need to select a customer')
        this.showModalMsg(msg)
      }
      return false
    },
    showModalMsg (msg) {
      const labelBtn = this.$gettext('Aceptar')
      this.modal.content = `<p style="text-align:center;padding-top:20px;">${msg}</p>
                            <p style="text-align:center;padding:20px 0 10px;"><button type="button" data-dismiss="modal" class="btn btn-action">${labelBtn}</button></p>`
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModal.click()
    }
  }
}
</script>
<style lang='scss' scoped>
  .box-1{
    font-size: 18px;
    background-color: #EFEFEF;
    padding: 28px 0 18px;
  }
  .box-2{
    margin-top: 15px;
    .holder-cn{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-position: center;
      background-repeat: no-repeat;
      text-align:center;
      height: 400px;
      background-color: #FAFAFA;
    }

    .img-cn{
      background-color: #fff;
      width: 146px;
      height: 146px;
      @include roundAll100(50%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
    }

    p{
      font-size: 18px;
      line-height: 30px;
    }
  }

  .box-3{
    margin-top: 15px;
    .container{
      background-color: rgba(#EFEFEF, .35);
      padding: 10px;
    }

    .row-flex{
      justify-content: initial;
    }

    .item-cn{
      padding: 10px;
    }
  }

  .box-4{
    padding: 80px 0 40px;
    p{
      text-align: center;

    }

    button{
      margin: 0 15px;
    }
  }
</style>