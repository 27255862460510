<template lang="pug">
  #dashboardVentasResumen(v-if="oSalesSummary")
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Venta Resumen]
        .row.row-flex
          .col-sm-6
            bk-migas
          .col-sm-6.text-right
            .dropdown
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Filtrar:] {{clientStatus_name}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(item, key) in aClientStatus" :class="{'disabled': clientStatus_name == item.name}")
                  span(:value="item.value" @click="changeFiltro(item)") {{item.name}}
            date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" type="month" range :format="calendar.format" :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)


    .container.box-1
      .row.row-flex
        .col-sm-3.chart-cn.type-num
          p.title-cn #[translate Ventas Período:]
          p.value-cn {{oSalesSummary.period_sales | format-money}}€
          p.leyenda-cn #[span(v-if="oSalesSummary.period_sales_diff > 0") +]{{oSalesSummary.period_sales_diff_percentage}}% #[translate vs año anterior   ]
        .col-sm-3.chart-cn.type-euro
          p.title-cn #[translate Ventas Año anterior:]
          p.value-cn {{oSalesSummary.year_sales | format-money}}€
          // p.leyenda-cn #[strong(:class="{'red': oSalesSummary.year_sales_diff < 0}") {{oSalesSummary.year_sales_diff | format-money}}] #[span(v-if="oSalesSummary.year_sales_diff > 0") +]{{oSalesSummary.year_sales_diff_percentage}}% #[translate vs Año acumulado anterior]
        .col-sm-6
          .chart-legend
            p #[translate Ventas Período] #[translate Objetivo Período] #[translate Año anterior]
        //-.col-sm-4
          .dropdown
            button.form-control(class="btn btn-default dropdown-toggle" type="button" id="yyOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true")
              i(class="fa fa-calendar" aria-hidden="true")
              strong {{filterDate}}
              i(class="fa fa-angle-down" aria-hidden="true")
            ul(class="dropdown-menu" aria-labelledby="yyOrder")
              li(v-for="(value, key) in aFilterDate" :class="{'disabled': filterDate == value}")
                span(:value="key" @click="changeFilterDate(key,value)") {{value}}

    .container.box-2
      .row
        .col-sm-12
          bar-line(:valuesData="valuesBarLine")

    .container.box-3
      .col-sm-12.chart-cn.type-donut
        p.title-cn #[translate Ventas período seleccionado] vs #[translate Objetivo]
        .chart-data
          donut-general(:valuesData="objetivosData")
          small {{objetivosData.value}}%
        p.leyenda-cn.type-menos #[em {{oSalesSummary.period_sales | format-money}}€] vs #[em {{oSalesSummary.period_sales_target | format-money}}€]
          //-strong -4%
</template>

<script>
import appConfig from '@/config-app.js'
import DonutGeneral from '@/components/dashboard/chart/DonutGeneral'
import BarLine from '@/components/dashboard/chart/BarLineThree'
import DatePicker from 'vue2-datepicker'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardPedidos',
  components: {DonutGeneral, BarLine, DatePicker, BkMigas},
  data () {
    return {
      'idUserDashboard': null, // Id del usuario de que se mostraran los datos.
      'oSalesSummary': null, // Dónde guardamos los resultados de Srv getSalesSummary
      'aClientStatus': null, // Dónde guardamos los diferentes estados de los clientes para el selector de filtro.
      'clientStatus_name': this.$gettext('Todos'),
      'clientStatus_value': 1, // 1 = todos.

      /* 'filterDate': this.$gettext('Enero 2017 - Enero 2018'),
      'aFilterDate': {
        'order_1': this.$gettext('Enero 2015 - Enero 2016'),
        'order_2': this.$gettext('Enero 2016 - Enero 2017'),
        'order_3': this.$gettext('Enero 2017 - Enero 2018')
      }, */
      'objetivosData': {
        'value': 65,
        'color': '#f95d5d'
      },
      'proyeccionData': {
        'value': 28.5,
        'color': '#7fd04e'
      },
      'valuesBarLine': {
        'labels': [],
        'dataOne': [],
        'dataTwo': [],
        'dataThree': [],
        'dataColor': {
          'one': {
            'borderColor': '',
            'pointBackgroundColor': ''
          },
          'two': {
            'borderColor': '',
            'pointBackgroundColor': ''
          },
          'three': {
            'borderColor': '',
            'pointBackgroundColor': ''
          }
        }
      },
      'calendar': {
        'before': this.$moment().add(-1, 'months').format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().startOf('year').format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().startOf('year').format('YYYY-MM'), this.$moment().add(-1, 'months').format('YYYY-MM')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    this.getClientStatus()
    if (this.idUserDashboard) {
      this.getSalesSummary()
    }

    // console.log(`lang: ${appConfig.language}`)
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    }
  },
  methods: {
    getSalesSummary () {
      // Cogemos los datos para los gráficos de resumenes.
      // console.log(`before: ${this.calendar.before}, after: ${this.calendar.after}`)
      SrvDashboard.getSalesSummary(this.idUserDashboard, this.clientStatus_value, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data Dashboard Resumen: ', res.data)
            this.oSalesSummary = res.data

            this.valuesBarLine = {
              'labels': [],
              'dataOne': [],
              'dataTwo': [],
              'dataThree': [],
              'dataColor': {
                'one': {
                  'borderColor': '#45A5F5',
                  'pointBackgroundColor': '#45A5F5'
                },
                'two': {
                  'borderColor': 'rgba(0,0,0,0)',
                  'pointBackgroundColor': '#71c061'
                },
                'three': {
                  'borderColor': '#b6b4b6',
                  'pointBackgroundColor': '#b6b4b6'
                }
              }
            }

            let chart = this.oSalesSummary.chart
            chart.map(item => {
              // console.log('---', item) // date, num_orders, value_orders
              this.valuesBarLine.labels.push(item.date)
              this.valuesBarLine.dataOne.push(item.sales_value)
              this.valuesBarLine.dataTwo.push(item.sales_target_value)
              this.valuesBarLine.dataThree.push(item.previous_year_sales_value)
            })

            this.objetivosData.value = this.oSalesSummary.period_sales_target_percent
            this.proyeccionData.value = this.oSalesSummary.year_sales_target_percent
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getClientStatus () {
      SrvDashboard.getClientStatus()
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getClientStatus: ', res.data)
            res = res.data.filter(item => item.value !== 4)
            this.aClientStatus = res
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeFiltro (item) {
      // console.log(`changeFiltro: ${item.value} - ${item.name}`)
      this.clientStatus_name = item.name
      this.clientStatus_value = item.value
      this.getSalesSummary()
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
        this.getSalesSummary()
      }
    }/* ,
    changeFilterDate (key, value) {
      console.log(`changeFilterDate: ${key}`)
      this.filterDate = value
      // this.$emit('toChangeOrdering', [key])
    } */
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getSalesSummary()
    }
  }
}
</script>

<style lang="scss" scoped>
  #dashboardPedidos{
    padding-left: 30px;
  }

  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row-flex{
      display:flex;
      align-items: center;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }

  .box-1{
    .row-flex{
      align-items: normal;
      justify-content: space-between;

      & > *:last-child{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  .chart-data{
    position: relative;
    width: 146px;
    height: 146px;
    margin: 15px auto 24px;

    & > div{
      position: relative;
      height: 100%;
    }

    small{
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      width: 100%;
      height: 22px;
      line-height: 22px;
      margin: auto;
      color: #008bb5;
      font-size: 20px;
      font-weight: $medium;
      text-align:center;
    }
  }

  .chart-cn{
    .title-cn{
      color:#2c373f;
      font-size: 16px;
      margin-bottom: 2px;
    }

    .value-cn{
      font-size: 24px;
      font-weight: $bold;
      margin-bottom: 8px;
    }

    .leyenda-cn{
      font-size: 14px;
      color: #6a889e;

      em{
        font-size: 16px;
        color: #5d6368;
        font-style: normal;
      }

      strong{
        position: relative;
        display: inline-block;
        color: #26d119;
        font-weight: $regular;
        &:before{
          content:'▴';
          display: inline-block;
          margin-right: 4px;
        }

        &.red{
          color: #f80000;
          &:before{
            content:'▾';
          }
        }
      }

    }

    &.type-num{
      border-right: 2px solid #e6eaee;
      .value-cn{
        color: #45A5F5;
      }
    }

    &.type-euro{
      .value-cn{
        color: #2F5EC4;
      }
    }

    &.type-donut{
      text-align: center;
      border-left: 2px solid #e6eaee;
      &:first-child{
        border: none;
      }

      .title-cn{
        color: #7e7f80;
        font-size: 18px;

        span{
          color:#2c373f;
        }
      }

      .leyenda-cn{
        strong{
          display: block;
          font-size: 24px;
          font-weight: $medium;
          color: #7fd04e;
        }

        &.type-menos{
          strong{
            color: #f95d5d;
          }
        }
      }
    }

  }

  .box-2, .box-3{
    margin-top: 80px;
  }

  .box-2{
    .chart-legend{

      span{
        position: relative;
        display: inline-block;
        margin-right:16px;

        &:before{
          content:'';
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: 6px;
          vertical-align: middle;
        }

        &:nth-child(1){
          &:before{
            background-color: #45A5F5;
          }
        }
        &:nth-child(2){
          &:before{
            background-color: #71c061;
          }
        }
        &:nth-child(3){
          &:before{
            height: 2px;
            background-color: #b6b4b6;
          }
        }
      }
    }
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    // min-width: 200px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 34px;
      line-height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: #646464;
      font-size: 13px;
      font-weight: $light;

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

  .calendar-cn{
    width: 210px;
    margin-left: 18px;
  }

</style>
