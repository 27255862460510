// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
// import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getCategories () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      const isLoggedIn = Stores.getters.isLoggedIn
      let url = ''
      if (isLoggedIn) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
        url = '/categories/'
      } else {
        url = '/public_categories/'
      }

      // llamada a la API
      axios.get(url)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            // SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
