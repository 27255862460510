<template lang="pug">
  #dashboardSidebarLeft
    ul.nivel1
      li(v-for="itemN1 in aTree")
        button(@click="setItem(itemN1, 1)" :class="{'active':itemActive==itemN1.id}")
          img(:class="{rotate: itemN1.children.length}" :src="img.flecha" width="6")
          span {{itemN1.node_name}}

        ul.nivel2(v-if="itemN1.children")
          li(v-for="itemN2 in itemN1.children")
            button(@click="setItem(itemN2, 2)" data-depth="2")
              img(:class="{rotate: itemN2.children.length}" :src="img.flecha" width="6")
              span(:class="{'active':itemActive==itemN2.id}") {{itemN2.node_name}}

            ul.nivel3(v-if="itemN2.children" :id="'filterSupplier-'+itemN2.id" class="collapse" aria-expanded="true")
              li(v-for="itemN3 in itemN2.children")
                button(@click="setItem(itemN3, 3)")
                  span(:class="{'active':itemActive==itemN3.id}") {{itemN3.node_name}}
</template>

<script>
export default {
  name: 'DashboardSidebarLeft',
  props: ['aTree'],
  data () {
    return {
      itemActive: null,
      depth: null,
      depth2Current: null,
      img: {
        flecha: require('@/assets/img/icos/menu-down.svg')
      }
    }
  },
  methods: {
    setItem (item, nivel) {
      // console.log(`menuToggle - id:  ${item.id}, nivel: ${nivel}`)
      this.itemActive = item.id
      this.$emit('toTreeId', item)
      // menuToggle(itemN1.id),

      let mc = event.target
      let depth = mc.getAttribute('data-depth')
      // console.log(`mc: ${mc}, depth: ${depth}`)
      if (depth === '2' && this.depth2Current !== mc) {
        if (this.depth2Current) {
          this.depth2Current.parentElement.querySelector('.nivel3').classList.toggle('in')
          this.depth2Current.classList.toggle('open')
        }
        this.depth2Current = mc
      } else if (depth === '2' && this.depth2Current === mc) {
        this.depth2Current = null
      }

      if (depth === '2') {
        mc.parentElement.querySelector('.nivel3').classList.toggle('in')
        mc.classList.toggle('open')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  #dashboardSidebarLeft{
    margin: 0 -15px;
  }

  ul{
    margin-bottom: 12px;
  }
  li{
    list-style: none;
    button{
      position: relative;
      border:0;
      background-color: transparent;
      display:block;
      width:100%;
      text-align: left;
      padding-left:0;
      padding-right: 0;
      @include transition();

      span{
        display: block;
        padding: 4px 30px 4px 10px;
        pointer-events: none;
      }

      img{
        position: absolute;
        top: 8px;
        left: 8px;
        margin: 0;
        @include transition();
      }

      &.open{
        // background-color: transparent!important;

        .rotate{
            transform: rotate(90deg);
        }
      }

      &:hover{
        background-color: #EBF3FE;
      }
    }
  }

  .nivel1{
    padding-left: 0;

    & > li {
      border-bottom: 1px solid #ededed;

      & > button{
        color: #1C2C40;
        padding-left: 15px;
        text-transform: uppercase;
      }
    }
  }

  .nivel2{
    padding-left:0;
    & > li{
      border-top: 1px solid #ededed;
      & > button{
        color: #1C2C40;
        span {
          padding: 4px 30px 4px 24px;
          &.active{
            background-color: #EBF3FE;
          }
        }
      }
    }
  }

  .nivel3{
    padding-left:0;
    & > li{
      // border-top: 1px solid #ededed;
      // background-color: #fafafa;
      & > button{
        color: #1C2C40;
        font-size: 12px;
        padding-left: 30px;
        // background-color: #eef0f5;
        span.active{
          // background-color: #eef0f5;
          font-weight: $bold;
        }
      }
    }
  }
</style>
