<template lang="pug">
  .error404(:style="img.bg")
    h4 Opss!
    img(:src="img.ico")
    h1 404
      small Página no encontrada
    p
      router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Ir a la página de Inicio]
</template>
<script>

export default {
  name: 'Error404',
  data () {
    return {
      img: {
        ico: require('@/assets/img/404-ico.svg'),
        bg: `background-image:url(${require('@/assets/img/404-bg.svg')})`
      }
    }
  },
  created () {},
  computed: {
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
  .error404{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;

    h4{
      font-size: 32px;
      margin-bottom: 40px;
    }

    h1{
      margin: 28px 0 42px 0;
      font-size: 52px;
      font-weight: $medium;
      text-align: center;

      small{
        display: block;
        font-size: 22px;
        font-weight: regular;
      }
    }

    .btn{
      width: 242px;
    }
  }
</style>
