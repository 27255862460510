// Stores
import Stores from '@/store/index'

// Importamos mutaciones
import * as types from '@/store/mutation-types'

// Config
import appConfig from '@/config-app.js'

// importamos servicios
import SrvCatalog from '@/services/catalog'

const cart = {
  state: {
    'cart': {
      'idCart': null,
      'base': null,
      'discounts': null,
      'tax': null,
      'total': null,
      'moneda': null,
      'items': [],
      'client_discount_percentage': null,
      'client_discount': null,
      'custom_product_max_discount': null,
      'final': null,
      'catalogs_types': null,
      'catalogs_items_per_page': null,
      'is_network': false, // Para identificar si hay productos externos. Si el valor es true es que hay un producto externo.
      'min_order': null
    },
    addItemCart: {} // item añadido más reciente.
  },
  actions: {
    getCart ({commit}) {
      // alert('Store del carrito: getCart.')
      SrvCatalog.getCart()
        .then(res => {
          if (res.status === 200) {
            // console.log('SrvCatalog ---> getCart: ', res.data)
            // alert('Store del carrito: commit CART_UPDATE.')
            commit(types.CART_UPDATE, res.data)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })

      return null
    },
    addCart ({commit}, item) {
      // console.log('-- addCart item: ', item)
      // alert('Store del carrito: addCart.')

      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''
      const oLead = Stores.getters.lead
      const idLead = (oLead) ? oLead.id : ''
      const datos = {
        'client': idClient,
        'product': item.idProduct,
        'price': item.product.price,
        'quantity': item.quantity,
        'discount': item.product.discount,
        'lead': idLead,
        'billable': false
      }

      // Si son productos especiales le añadimos la descripción: 11 -> Código 00
      if (item.idProduct === appConfig.configApp.id_code_0) {
        datos.description = item.product.description
      }

      if (item.aditional_measurement_unit) {
        datos.aditional_measurement_unit = item.aditional_measurement_unit
      }

      // console.log('-- addCart datos: ', datos)

      SrvCatalog.setCart(datos)
        .then(res => {
          // console.log('res: ', res)
          // Actualizamos el carrito.
          // alert('Store del carrito: avisamos de que actualice.')
          commit(types.CART_ADD_ITEM, item)
          Stores.dispatch('getCart')
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return null
    },
    deleteCart ({commit}) {
      // vaciar carrito
      SrvCatalog.deleteCart()
        .then(res => {
          // console.log('res: ', res)
          // Actualizamos el carrito.
          Stores.dispatch('getCart')
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return null
    },
    removeCartProduct ({commit}, item) {
      SrvCatalog.removeProduct(item.id)
        .then(res => {
          // console.log(res)
          // Actualizamos el carrito.
          Stores.dispatch('getCart')
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    }
  },
  mutations: {
    [types.CART_UPDATE] (state, data) {
      // console.log('3. mutations CART_UPDATE ---> getCart: ', data)
      // console.log('Store del carrito -> state cart ini:', state.cart)

      // console.log(`idCart: ${data.id}`)
      // alert('Store del carrito: MUTATIONS CART_UPDATE.')
      let isNetwork = false
      data.items.map(item => {
        if (item.product_data.is_network) {
          isNetwork = true
        }
      })
      // console.log(`----max_discount: ${data.custom_product}`)
      state.cart.idCart = data.id
      state.cart.observations = data.observations
      state.cart.base = data.base
      state.cart.discounts = data.discounts
      state.cart.tax = data.tax
      state.cart.total = data.total
      state.cart.currency_code = data.currency_code
      state.cart.moneda = data.currency_symbol
      state.cart.items = data.items
      state.cart.client_discount_percentage = data.client_discount_percentage
      state.cart.client_discount = data.client_discount
      state.cart.custom_product_max_discount = (data.custom_product) ? parseInt(data.custom_product.max_discount) : null
      state.cart.final = data.final
      state.cart.is_network = isNetwork
      state.cart.catalogs_types = data.catalogs_types
      state.cart.catalogs_items_per_page = data.catalogs_items_per_page
      state.cart.min_order = data.min_order

      // console.log('Store del carrito -> state cart end:', state.cart)
    },
    [types.CART_ADD_ITEM] (state, data) {
      const id = data.idProduct
      /* id: 1, Producto Cargos adicionales del tipo: Código 0
      id: 2413, Producto Cargos adicionales del tipo: Portes
      id: 3960, Producto Cargos adicionales del tipo: Recogida */
      if (id !== appConfig.configApp.id_code_0 && id !== 2413 && id !== 3960) {
        state.addItemCart = data
      }
    }
  },
  getters: {
    'count': state => {
      // console.log('----------', appConfig.configApp.cart_und)
      if (appConfig.configApp.cart_und) {
        const items = state.cart.items
        let count = 0
        items.map(item => {
          count += parseInt(item.quantity)
        })
        return count
      }

      return state.cart.items.length
    },
    'cart': state => {
      return state.cart
    },
    'addItemCart': state => {
      return state.addItemCart
    }
  }
}

export default cart
