<template lang="pug">
  #catalog-product(v-if="product")
    .container.box-1
      .row
        .col-sm-5.text-center
          .holder-img( :class="{'type-outlet': product.is_outlet, 'type-eco': product.is_eco}" )
            small(v-if="product.is_eco") #[translate Eco]
            small(v-if="product.is_outlet") #[translate Outlet]
            img(:src="checkEmptyImg(productImg)")
            i.fa.fa-search
        .col-sm-7
          h3.title {{checkEmpty(product.name)}}
          .descripction-cn(v-if="description") {{description}}

          .row
            .info-cn.col-sm-7
              ul
                // li(v-if="product.supplier") #[translate Proveedor]:
                  strong {{product.supplier.name}}
                li #[translate Código]:
                  strong {{product.external_id}}
              p.doc-cn
                a(:href="product.doc_technical_url" target="_blank" class="doc-link" v-if="product.doc_technical_url")
                  img(:src="img.docDownload" width="13" alt="")
                  span #[translate Ficha Técnica]
                //a(:href="product.doc_security_url" target="_blank" class="doc-link" v-if="product.doc_security_url")
                  img(:src="img.docDownload" width="13" alt="")
                  span #[translate Ficha Seguridad]

            .col-sm-5
              bk-cotiza( :product="product" type="single" :provinces="provinces")

    .container.especificaciones-cn
      .row
        .col-sm-12(v-if="product.extra_info['Descripción']")
          h3 #[translate Descripción]
          p {{product.extra_info['Descripción']}}

        .col-sm-12(v-if="Object.keys(extraInfoCustom).length")
          h3 #[translate Especificaciones del Producto]
          ul.row-flex
            li(v-for="(value, label) in extraInfoCustom" v-if="extraInfoShow(label,value)")
              span.paddingExtraInfo(v-if="typeof value === 'object'")
               p
                 translate {{label}}:
                 ul.listItemsFieldExtraInfo
                   li(v-if="item !==''" v-for="(item) in value")
                     strong {{ item.value }}
              span.paddingExtraInfo(v-else)
                p
                  translate {{ label }}:
                  strong {{ value }}

        .col-sm-12(v-if="product.extra_info['Modo Empleo']")
          h3 #[translate Modo Empleo]
          p {{product.extra_info['Modo Empleo']}}

        .col-sm-12(v-if="product.extra_info['Dosificacion']")
          h3 #[translate Dosificación]
          div {{product.extra_info['Dosificacion']}}       

    .container.box-3(v-if="product.related_products.length")
      .row
        .col-sm-12
          h2 #[translate También te puede interesar]
      .row
        .col-sm-12.list-related-product
          div.item-cn( v-for="item in product.related_products")
            bk-item(:product="item" view="grid" @toGoProduct="toGoProduct")
</template>
<script>
import {mapGetters} from 'vuex'
import BkItem from '@/components/catalog/Item-public.vue'
import BkCotiza from '@/components/common/Cotiza.vue'

// importamos servicios Catálogo
import SrvCatalog from '@/services/catalog'
import SrvCotiza from '@/services/cotiza'

export default {
  name: 'CatalogProductPublic',
  components: { BkItem, BkCotiza },
  props: ['id'],
  data () {
    return {
      'ico1': require('@/assets/img/ficha/ico-especificaciones.png'),
      'ico2': require('@/assets/img/ficha/ico-dosificacion.png'),
      'ico3': require('@/assets/img/ficha/ico-empleo.png'),
      'ico4': require('@/assets/img/ficha/ico-seguridad.png'),
      'description': '',
      'brand': '',
      'product': null,
      'quantity': 1,
      'itemsCart': [], // productos que tiene el carro.
      'meses': [this.$gettext('Ene'), this.$gettext('Feb'), this.$gettext('Mar'), this.$gettext('Abr'), this.$gettext('May'), this.$gettext('Jun'), this.$gettext('Jul'), this.$gettext('Ago'), this.$gettext('Sep'), this.$gettext('Oct'), this.$gettext('Nov'), this.$gettext('Dic')],
      'dosificacion_info': '',
      'empleo_info': '',
      'seguridad_info': '',
      'aSeguridad': [],
      'aObligacion': [],
      'formatoItem': '',
      'aFormato': [],
      'img': {
        'docDownload': require('@/assets/img/ficha/doc-download.svg')
      },
      provinces: {},
      specialPriceType: false
    }
  },
  created () {
    window.scrollTo(0, 0)
    this.getProduct()
    this.getProvinces()
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    extraInfoCustom () {
      let extra = this.product.extra_info
      let infoCustom = {}
      if (this.role !== 'client') {
        infoCustom['Marca'] = extra['Marca']
      }
      if (extra['Eco Certificaciones']) {
        infoCustom['Eco Certificaciones'] = extra['Eco Certificaciones']
      }
      if (extra['Ficha Eco Certificación']) {
        infoCustom['Ficha Eco Certificación'] = extra['Ficha Eco Certificación']
      }
      if (extra['Certificaciones']) {
        infoCustom['Certificaciones'] = extra['Certificaciones']
      }
      if (extra['Ficha Técnica']) {
        infoCustom['Ficha Técnica'] = extra['Ficha Técnica']
      }
      if (extra['Biodegradable']) {
        infoCustom['Biodegradable'] = extra['Biodegradable']
      }
      if (extra['Compostable']) {
        infoCustom['Compostable'] = extra['Compostable']
      }
      if (extra['Ean13']) {
        infoCustom['EAN13'] = extra['Ean13']
      }
      if (extra['Gtin']) {
        infoCustom['Gtin'] = extra['Gtin']
      }
      if (extra['Unidad Mínima Venta']) {
        infoCustom['Unidad Mínima Venta'] = extra['Unidad Mínima Venta']
      }
      if (extra['Cantidad Envase']) {
        infoCustom['Cantidad Envase'] = extra['Cantidad Envase']
      }
      if (extra['Material Envase']) {
        infoCustom['Material Envase'] = extra['Material Envase']
      }
      if (extra['Tipo Plástico']) {
        infoCustom['Tipo Plástico'] = extra['Tipo Plástico']
      }
      if (extra['Tipo Embalaje']) {
        infoCustom['Tipo Embalaje'] = extra['Tipo Embalaje']
      }
      if (extra['Envases Embalaje']) {
        infoCustom['Envases Embalaje'] = extra['Envases Embalaje']
      }
      if (extra['Material Embalaje']) {
        infoCustom['Material Embalaje'] = extra['Material Embalaje']
      }
      if (extra['Peso Envase']) {
        infoCustom['Peso Envase'] = extra['Peso Envase']
      }
      if (extra['Peso Embalaje']) {
        infoCustom['Peso Embalaje'] = extra['Peso Embalaje']
      }
      if (extra['Volumen Embalaje']) {
        infoCustom['Volumen Embalaje'] = extra['Volumen Embalaje']
      }
      if (extra['Embalajes Palet']) {
        infoCustom['Embalajes Palet'] = extra['Embalajes Palet']
      }

      let keys = Object.keys(extra)
      for (let key in keys) {
        if (keys[key] === 'Marca' || keys[key] === 'Código Artículo Proveedor' || keys[key] === 'Código Producto Proveedor') {
          continue
        }
        if (keys[key] === 'Ean13' || keys[key] === 'Modo Empleo' || keys[key] === 'Dosificacion') {
          continue
        }
        if (!infoCustom[keys[key]]) {
          infoCustom[keys[key]] = extra[keys[key]]
        }
      }
      return infoCustom
    },
    total () {
      if (this.formatoItem) {
        return this.price * this.quantity * this.formatoItem.quantity
      }
      return this.price * this.quantity
    },
    price () {
      let precio = this.product.price
      if (this.product.special_price !== null) {
        precio = this.product.special_price
        this.specialPriceType = true
      }
      return precio
    },
    center () {
      return this.$store.state.center.center
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isLead () {
      return this.$store.getters.isLead
    },
    hiddenAddCart () {
      // Si no hay stock y tiene activado el limite, ocultamos el botón de comprar.
      if (!this.product.stock && this.config_app.stock_limit) {
        return true
      }
      // Ocultamos el botón de añadir el carrito cuando no tengamos stock, obsolete_limit: true y obsolete: true
      if (!this.product.stock && this.product.obsolete && this.config_app.obsolete_limit) {
        return true
      }
      return false
    },
    limitQuantity () {
      // Si tenemos activo el limite lo ponemos en cada caja de cantidad.
      if (this.config_app.stock_limit) {
        return this.product.stock
      }

      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.product.obsolete ? this.product.stock : ''
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }

  },
  methods: {
    extraInfoShow (label, value) {
      let show = false
      if (typeof value === 'string' && value !== '' && label !== 'Código Artículo Proveedor' && label !== 'Código Producto Proveedor' && label !== 'Nombre Artículo' && label !== 'Nombre Producto' && label !== 'Código Interno' && label !== 'Descripción' && label !== 'Descripción Corta') {
        show = true
      }
      if (typeof value === 'object' && value.length > 0) {
        show = true
      }
      return show
    },
    getProduct () {
      const aSlug = this.id.split('-')
      SrvCatalog.getProductPublic(aSlug[0])
        .then(res => {
          // console.log('res-----> ', res)
          if (res.status === 200) {
            // console.log('--->Producto: ', res.data.results[0])
            this.product = res.data

            // console.log('--->Producto: ', this.product.extra_info)

            // console.log('--->Producto relacionados: ', this.product.related_products)

            // Migas
            this.toSendMigas(this.product.categories)

            // Descripción
            if (this.product.extra_info['Descripción Corta'] !== undefined) {
              this.description = this.product.extra_info['Descripción Corta']
            } else {
              this.description = this.product.description
            }
            // Marcas
            if (this.product.extra_info.brand !== undefined) {
              this.brand = this.checkEmpty(this.product.extra_info.brand)
            }

            // Imagen
            if (this.config_app.extra_images && this.product.extra_images.length > 0) {
              // Mostramos la imagen del cliente (ej. primsa) en lugar de la de Orbita.
              this.productImg = this.product.extra_images[0]
            } else if (this.product.related_products.image_url !== undefined) {
              this.productImg = this.product.related_products.image_url
            } else {
              this.productImg = this.product.image_url
            }

            // formato
            this.aFormato = this.product.aditional_measurement_units
            if (this.aFormato && this.aFormato.length > 0) {
              // Unidad por defecto.
              const unit = this.productData.measurement_unit
              let formato = {'id': '', 'name': this.$gettext('Unidad'), 'quantity': 1}
              if (unit) {
                formato = {'id': '', 'name': unit.name, 'quantity': unit.quantity}
              }
              this.aFormato = [formato, ...this.aFormato]
              this.changeFormato(this.aFormato[0])
            }
            // console.log('aFormatos: ', this.aFormato)

            // Dosificación
            if (this.product.extra_info.dosage !== undefined) {
              this.dosificacion_info = this.product.extra_info.dosage
            }

            // Modo de Empleo
            if (this.product.extra_info.usage !== undefined) {
              this.empleo_info = this.product.extra_info.usage
            }

            // Seguridad
            if (this.product.extra_safety_symbols !== undefined) {
              this.seguridad_info = this.product.extra_safety_symbols

              if (this.seguridad_info) {
                this.seguridad_info.map(item => {
                  if (item.symbol_type_external_id === '2') {
                    this.aSeguridad.push(item)
                  } else if (item.symbol_type_external_id === '1') {
                    this.aObligacion.push(item)
                  }
                })
              }
            }

            // QUITAMOS EL MISMO PRODUCTO PARA LOS RELACIONADOS
            console.log(this.product.related_products, this.product)
            this.product.related_products = this.product.related_products.filter((ele) => ele.external_id !== this.product.external_id)
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    toGoProduct (id) {
      this.$emit('toGoProduct', id)
    },
    toSendMigas (migas) {
      this.$emit('toSendMigas', migas)
    },
    changeFormato (item) {
      // console.log(`id: ${item.id}, name: ${item.name}, quantity: ${item.quantity}`)
      this.formatoItem = item
    },
    getProvinces () {
      SrvCotiza.getProvinces()
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            this.provinces = data.results
          }
        }, () => {
          // console.log('----->' + error)
        })
    }
  },
  watch: {
    id () {
      window.scrollTo(0, 0)
      this.getProduct()
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-1{
    margin-top:10px;
    margin-bottom:40px;

    h3{
      color: #1C2C40;
      margin:0 0 22px;
      font-size: 18px;
      font-weight: $regular;

    }

    .descripction-cn{
      font-size: 14px;
      line-height: 24px;
      margin-bottom:40px;
    }
  }

  .holder-img{
    position:relative;
    border:1px solid #89ACE3;
    padding:10px;
    width: 100%;
    //max-width: 476px;
    height: 392px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

      img{
          max-width: 100%;
          height: auto;
          max-height: 380px;
      }

      i{
        position:absolute;
        bottom: 10px;
        right: 10px;
        font-size:30px;
        color:$grey-border;
        @include transition();
        cursor:pointer;
        display:none;

        &:hover, &:focus{
          color: $blue;
        }
      }
  }

  .info-cn{

    ul{
      margin-bottom:18px;
      padding: 0;
      min-height: 72px;

      li{
        list-style: none;
        font-size: 14px;
        line-height: 24px;
        strong{
          font-weight: $regular;
          margin-left: 8px;
        }
      }
    }

    .doc-cn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      min-height: 40px;

      a{
        display: block;
        width: 48%;
        line-height: 40px;
        height: 40px;
        text-align: center;
        border: 1px solid $blue-light;

        img{
          margin-right: 8px;
        }

        &:hover, &:focus{
          border-color: darken($blue-light, 10%);
          text-decoration: none;
        }
      }
    }
  }

  .consumo-cn{
    margin-right: 0;
  }

  .consumo-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EFEFEF;
    padding:20px 12px 13px;
    margin:0;
    text-align: center;
    font-weight: $medium;

    span{
      display: inline-block;
      margin:10px 14px;
    }
    strong, small{
      display:block;
      color:#494f58;
    }

    strong{
      font-weight: $regular;
      margin-bottom:18px;
    }
  }

  .info-addCart{
    background-color: #EBF3FE;
    padding:20px 22px 26px;

    form{
      label{
        font-size: 14px;
        font-weight: $regular;
      }
    }
  }

  .pvp{
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    strong{
      font-size: 16px;
      font-weight: $medium;
    }
  }

  .und{
    font-size:16px;
    color:#2E811A;
    margin-bottom: 16px;

    &.noStock{
      color:#ff5466;
    }
  }

  .formato{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cant{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    span{
      display: inline-block;
    }

    input{
      width:60px;
      border:none;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #E6E8E9;
      font-size: 13px;
      @include roundAll(4);
      overflow: hidden;
      padding-left: 10px;
    }
  }

  .pvpTotal{
    text-align: right;
    margin-bottom: 26px;

    span{
      display:inline-block;
      font-size: 20px;
      font-weight: $medium;
      display: inline-block;
      color: #303030;

      &.special{
        color: $color-precio-special!important;
      }

      &.obsolete{
        color: #f0bd4e;
      }

    }
  }

  .submit{
    margin-bottom:0;
  }

  .btn-orange{
    margin-bottom:0;
    width: 100%;
    text-transform: uppercase;
  }

  .especificaciones-cn{
    margin-bottom: 30px;
    h3{
      font-size: 16px;
      font-weight: $medium;
      margin-top: 30px;
      margin-bottom: 18px;
    }

    ul{
      padding:0;
      list-style: none;
      align-items: initial;

      li{
        width: 48%;
        padding:6px 20px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        // align-items: center;

        p{
          margin-bottom: 0;
          display: flex;
          width: 100%;

          & > *{
            width: 50%;
            display: inline-block;
          }

          strong{
            font-weight: $regular;
          }
        }

        span{
          p{
            margin-bottom: 0;
            display: flex;
            width: 100%;

            & > *{
              width: 50%;
              display: inline-block;
            }

            strong{
              font-weight: $regular;
            }
          }

          &:nth-child(1), &:nth-child(2){
            width: 100%;
            p{
              span{
                width: 35%;
              }
              strong{
                width: 65%;
              }
            }
          }

          
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(5), &:nth-child(6), &:nth-child(9), &:nth-child(10),
        &:nth-child(13), &:nth-child(14), &:nth-child(17), &:nth-child(18), &:nth-child(21), &:nth-child(22)
        {
          background-color: #F6F6F6;
        }
      }
    }
  }

  .box-3{
    padding-bottom:40px;
    text-align:center;

    h2{
      font-size:18px;
      font-weight: $medium;
      margin:20px 0 20px;
    }

    .list-related-product{
      padding: 0;
      background-color:#F5F5F5;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .item-cn{
      display: inline-block;
      margin:14px;
        .catalog-GridItem{
          background-color: $white;
          margin-bottom: 0;
        }
    }
  }

  .item-ico{
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }


</style>
