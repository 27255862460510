<template lang="pug">
  #dashboardAlbaranesResumen
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Albaranes Resumen]
        .row.row-flex
          .col-sm-6
            bk-migas
          .col-sm-6.text-right
            .dropdown
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Año:] {{yearActive}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(item) in yearsSelect" :key="item" :class="{'disabled': yearActive == item}")
                  span(:value="item" @click="changeYear(item)") {{item}}

    div(v-if="oAlbaranes")
      .container.box-1
        .row
          .col-sm-4.chart-cn.type-donut
            p.title-cn #[translate Objetivo Mes]
            .chart-data
              donut-general(:valuesData="objetivosData")
              small {{objetivosData.value}}%
            p.leyenda-cn #[em {{oAlbaranes.value_delivery_notes | format-money}}€] vs #[em {{oAlbaranes.target_delivery_notes | format-money}}€]
          .col-sm-4.chart-cn.type-donut
            p.title-cn #[translate Proyección Mes]
            .chart-data
              donut-general(:valuesData="proyeccionData")
              small {{proyeccionData.value}}%
            p.leyenda-cn #[em {{oAlbaranes.forecast_delivery_notes | format-money}}€] vs #[em {{oAlbaranes.target_delivery_notes | format-money}}€]

      .container.box-2
        .row
          .col-sm-12
            .chart-bar
              .chart-legend
                p #[translate Nº Albaranes / Día] #[translate € Albaranes / Día]
            bar-line(:valuesData="valuesBarLine")
</template>

<script>
import DonutGeneral from '@/components/dashboard/chart/DonutGeneral'
import BarLine from '@/components/dashboard/chart/BarLine'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvAlbaranes from '@/services/albaranes'

export default {
  name: 'DashboardPedidos',
  components: { DonutGeneral, BarLine, BkMigas },
  data () {
    return {
      'idUserDashboard': null, // Id del usuario de que se mostraran los datos.
      'oAlbaranes': null, // Dónde guardamos los resultados de Srv getAlbaranes
      'yearActive': null, // dónde guardamos el año seleccionado.
      'objetivosData': {
        'value': 0,
        'color': '#7fd04e'
      },
      'proyeccionData': {
        'value': 0,
        'color': '#f95d5d'
      },
      'valuesBarLine': {
        'labels': [],
        'dataOne': [],
        'dataTwo': []
      },
      'color_verde': '#7fd04e',
      'color_rojo': '#f95d5d'
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)

  },
  mounted () {
    if (this.idUserDashboard) {
      this.getAlbaranes()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    },
    yearsSelect () {
      let yearInit = 2018
      const currentYear = new Date().getFullYear()
      let years = []
      while (yearInit <= currentYear) {
        years.push(yearInit++)
      }
      this.yearActive = currentYear
      return years
    }
  },
  methods: {
    getAlbaranes () {
      // Cogemos los datos para los gráficos de resumenes.
      SrvAlbaranes.getAlbaranes(this.idUserDashboard, this.yearActive)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data Dashboard Resumen: ', res.data)
            this.oAlbaranes = res.data

            // reseteamos para que funcione el watch
            this.objetivosData = []
            this.proyeccionData = []
            this.valuesBarLine = {
              'labels': [],
              'dataOne': [],
              'dataTwo': []
            }

            let chart = this.oAlbaranes.chart
            chart.map(item => {
              // console.log('---', item) // date, num_orders, value_orders
              this.valuesBarLine.labels.push(item.date)
              this.valuesBarLine.dataOne.push(item.value_delivery_notes)
              this.valuesBarLine.dataTwo.push(item.num_delivery_notes)
            })

            this.objetivosData = {
              'value': this.oAlbaranes.value_delivery_notes_percentage,
              'color': (this.oAlbaranes.value_delivery_notes_percentage < 100) ? this.color_rojo : this.color_verde
            }
            this.proyeccionData = {
              'value': this.oAlbaranes.forecast_delivery_notes_percentage,
              'color': (this.oAlbaranes.forecast_delivery_notes_percentage < 100) ? this.color_rojo : this.color_verde
            }
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeYear (item) {
      // console.log(`year: ${item}`)
      this.yearActive = item
      this.getAlbaranes()
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getAlbaranes()
    }
  }
}
</script>

<style lang="scss" scoped>
  #dashboardPedidos{
    padding-left: 30px;
  }

  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }

  .box-1{
    padding-top: 50px;
  }

  .chart-data{
    position: relative;
    width: 146px;
    height: 146px;
    margin: 15px auto 24px;

    & > div{
      position: relative;
      height: 100%;
    }

    small{
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      width: 100%;
      height: 22px;
      line-height: 22px;
      margin: auto;
      color: #008bb5;
      font-size: 20px;
      font-weight: $medium;
      text-align:center;
    }
  }

  .chart-cn{
    .title-cn{
      color:#2c373f;
      font-size: 16px;
      margin-bottom: 2px;
    }

    .value-cn{
      font-size: 24px;
      font-weight: $bold;
      margin-bottom: 8px;
    }

    .leyenda-cn{
      font-size: 14px;
      color: #6a889e;

      em{
        font-size: 16px;
        color: #5d6368;
        font-style: normal;
      }

      strong{
        position: relative;
        display: inline-block;
        color: #26d119;
        font-weight: $regular;
        &:before{
          content:'▴';
          display: inline-block;
          margin-right: 4px;
        }

        &.red{
          color: #f80000;
          &:before{
            content:'▾';
          }
        }
      }

    }

    .type-num{
      border-right: 2px solid #e6eaee;
      .value-cn{
        color: #45A5F5;
      }
    }

    .type-euro{
      .value-cn{
        color: #2F5EC4;
      }
    }

    .type-donut{
      text-align: center;
      &:last-child{
        border-left: 2px solid #e6eaee;
      }

      .leyenda-cn, em{
        font-size: 20px;
      }
    }

  }

  .calendar-cn{
    width: 210px;
    margin-left: 18px;
  }

  .box-2{
    margin-top: 60px;
    .chart-bar{
      text-align: right
    }
    .chart-legend{
      text-align: right;
      margin-bottom: 30px;
      display: inline-block;

      span{
        position: relative;
        display: inline-block;
        margin-right:16px;

        &:before{
          content:'';
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: 6px;
          vertical-align: middle;
        }

        &:nth-child(1){
          &:before{
            background-color: #45A5F5;
          }
        }
        &:nth-child(2){
          &:before{
            background-color: #2F5EC4;
          }
        }
      }
    }
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    min-width: 160px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 34px;
      line-height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: $blue-light;

      span{
        color: #494949;
      }

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

</style>
