<template lang="pug">
  .item-cn(:class="{'type-favorite': isTypeFavorite}")
    .row
      .col-sm-4.product-cn
        .img-cn
          img(:src="checkEmptyImg((productData.image_url))" class="foto-img")
          bk-favorite(class="favorite-img" :data-id="productData.id")
        h3 {{productData.name}}

      .col-sm-3
          ul
            // li(v-if="product.supplier") #[translate Prov].: {{productData.supplier.name}}
            li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.] {{productData.external_id}}
            li(v-else) #[translate Código]: {{productData.external_id}}
            li {{productData.quantity_by_packet}} #[translate unidades por envase]
            li {{productData.packets_by_package}} #[translate envases por paquete]

      .col-sm-2.text-right
        p {{product.price | format-money}} {{productData.currency_symbol}}
      .col-sm-1.text-right
        p {{product.quantity}}
      .col-sm-1.text-right
        p {{productDiscount}} %
      .col-sm-1.text-right
        p {{product.base | format-money}} {{productData.currency_symbol}}
    .row(v-if="product.description")
      .col-sm-1.product-cn
      .col-sm-11
        p.descriptionCode0 {{ product.description }}
</template>
<script>
import BkFavorite from '@/components/common/favorite.vue'

export default {
  name: 'ItemSingleOferta',
  props: ['product'],
  components: { BkFavorite },
  data () {
    return {
    }
  },
  computed: {
    productData () {
      // console.log(this.product)
      return this.product.product
    },
    productDiscount () {
      if (this.product.discount) {
        const num = parseInt(this.product.discount)
        return num.toFixed(0)
      }

      return 0
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    isTypeFavorite () {
      return this.config_app.favorite
    }
  },
  created () {
    // console.log(`Oferta single Item:`, this.product)
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>
<style lang="scss" scoped>
.item-cn{
  position: relative;
  margin:0;
  padding: 20px 0 30px;
  border-bottom: 1px solid #979797;
  color:#4D4F5C;
  overflow: hidden;

  &.type-favorite{
    &:before{
        content:'';
        position:absolute;
        top: 4px;
        bottom: 4px;
        left:0;
        width: 10px;
        background-color: #FAFBFC;
        z-index: 1;
    }
    .favorite-img{
      display: block;
    }
  }

}
.row{
  display: flex;
  align-items: center;
  margin: 0;
}

.product-cn{
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 100px;
  font-size: 14px;
}
.descriptionCode0{
  background-color: #f1f4f7;
  padding:10px;
}
.img-cn{
  position:absolute;
  top:0;
  bottom:0;
  left: 15px;
  width: 64px;
  min-height:58px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: $white;

  .foto-img{
    max-width: 100%;
    max-height: 54px;
    height:auto;
  }

  .favorite-img{
    position: absolute;
    top: -14px;
    right: -14px;
    display: none;
  }
}

h3{
  color:#1C2C40;
  font-size: 13px;
  text-transform: uppercase;
  margin:0;
  font-weight: $regular;
  // @include ellipsis();
}

ul{
  list-style: none;
  padding:0;
  font-size: 12px;
}

.precio-und{
  padding-left: 0;
  padding-right: 0;
}

/*  .und{
  font-size:14px;
  color:#61c844;

  &.noStock{
    color:#ff5466;
  }
}

.item-cn{
    position:relative;
    margin-top:-1px;
    border:1px solid #eff3f6;
    padding:18px;
    text-align: left;

    &:hover, &:focus{
      @include box-shadow(all, 5px, rgba($black, 0.1) );
    }
  }

  .info-cn{
    display: inline-block;
    padding-left: 160px;
    width: 37%;
    min-height: 120px;
    vertical-align: middle;
  }

  .img-cn{
    position:absolute;
    top:18px;
    left:18px;
    display:block;
    width: 150px;
    min-height:115px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      max-width: 100%;
      max-height: 112px;
      height:auto;
      cursor: pointer;
    }
  }

  h3{
    color:$blue-light;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $medium;
    margin:0 0 10px;
    cursor:pointer;
    @include transition();

    &:hover, &:focus{
      color: $blue;
    }
  }

  ul{
    margin:0;
    padding:0;
    list-style: none;
  }

  li{
    font-size: 13px;
    color:#494f58;
  }

  input{
    width:60px;
    border: 1px solid $grey-border;
    height: 32px;
    line-height: 32px;
    text-align: center;
    @include roundAll(4);
    margin-left:8px;
  }

  .info-extra{
    display: inline-block;
    vertical-align: middle;
    width: 62%;

    .fila{
      margin-bottom: 14px;
      & > * {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: #494f58;
        margin-left:20px;
        margin-bottom: 0;

        &:first-child{
          margin-left:0;
        }
      }
    }
  }

  .stock-cn{
    width: 100%;
  }

  .btn-remove{
    float:right;
    color: $blue-light;
    font-size: 14px;
    background-color: transparent;
    border:none;
    &:hover, &:focus{
      color: $blue;
    }
  }

  .und{
    font-size:14px;
    color:#61c844;
    margin-left: 6px;

    &.noStock{
      color:#ff5466;
    }
  }

  .pvp-cn, .cantidad-cn, .descuento-cn, .total-cn{
    small{
      font-size:15px;
      display: inline-block;
      padding: 6px 10px;
      margin-left: 6px;
      border:1px solid transparent;
    }
  }

  .pvp-cn{
    small{
      font-weight: $medium;
      border:1px solid #008bb5;
      color: #008bb5;
      @include roundAll(3);

      &.special{
        color: $white;
        background-color: $blue-medium;
        border-color: $blue-medium;
      }
    }
  }

  .total-cn{
    float:right;
    small{
      border:1px solid $blue-bg;
      @include roundAll(3);
      margin-left: 0px;
      color: $blue-bg;
      font-weight: $medium;
    }
  }
*/
</style>
