<template lang="pug">
  #dashboardVentaRankingCenterListItem.container
    .row.box-1
      div.col-sm-4
        p.name {{client.name}}
          em {{client.address}}
      .col-sm-2
        p.objetivosData.text-center {{client.period_sales | format-money}}
      .col-sm-2
        p.objetivosData.text-center.diff #[span(:class="{'menos-cn': client.period_sales_diff_percentage < 0 }") {{client.period_sales_diff_percentage}}%] | #[span(:class="{'menos-cn': client.year_period_sales_diff_percentage < 0 }") {{client.year_period_sales_diff_percentage}}%]
      .col-sm-2
        p.ventas.text-center {{client.year_sales | format-money}}
      .col-sm-1
        p.ventas.text-center.diff(:class="{'menos-cn': client.year_sales_diff_percentage < 0 }") {{client.year_sales_diff_percentage}}%
      .col-sm-1
        p.text-center
          img(:src="img.ver" class="lupa" width="18" alt="" @click="goOrderCenter(client.valid_id)" v-if="client.valid_id")
</template>
<script>

// srv
import SrvCenters from '@/services/center'

export default {
  name: 'ClientListItem',
  props: ['client', 'index'],
  data () {
    return {
      'img': {
        'ver': require('@/assets/img/icos/ver_pedido.svg')
      }
    }
  },
  methods: {
    goOrderCenter (id) {
      // console.log(`goOrderCenter - center ID: ${id}`)
      SrvCenters.getCenter(id)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard VentasRankingCenter Item -> Center: ', res.data)
            // Enviamos a Store Center los datos del Centro seleccionado
            this.$store.dispatch('setCenter', res.data)
            // Lo pasamos a su State
            this.$store.commit('SET_CENTER')

            // Nos vamos a pedidos.
            this.$router.push({name: 'pedidosList'})
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    display: flex;
    align-items: center;
    padding:12px 0;
    color: #4D4F5C;
    font-size: 14px;
  }

  p{
    position: relative;
    margin: 0;

    &.name{
      text-transform: uppercase;
    }

    em{
      display: block;
      font-size: 14px;
      font-style: normal;
    }
    &.diff{
      color: #71c061;
    }
    &.menos-cn, .menos-cn{
      color: #f95d5d;
    }
  }

  .lupa{
    cursor: pointer;
    @include transition();

    &:hover, &:focus{
      opacity: .8
    }
  }
</style>
