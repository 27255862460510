// Stores
import Stores from '@/store/index'
// Importamos mutaciones
import * as types from '@/store/mutation-types'

// Config
import appConfig from '@/config-app.js'

// importamos servicios
import SrvUsu from '@/services/users'

import axios from 'axios'
// console.log(`Config --> api: ${appConfig.api}`)
axios.defaults.baseURL = appConfig.api
// axios.defaults.headers.common['Authorization'] = localStorage.getItem('_token')

const user = {
  state: {
    isLoggedIn: !!window.localStorage.getItem('_token'), // Si no esta el token en False y si lo esta es trueb
    user: null,
    pending: false,
    info: {
      'validable_orders': 0,
      'validable_offers': 0,
      'country': null,
      'only_authorized_products': false
    }
  },
  actions: {
    login ({commit}, user) {
      commit(types.PENDING) // Para mostrar el preload

      return new Promise((resolve, reject) => {
        axios.post('/api-token-auth/', user)
          .then(res => {
            // console.log('Store res: ', res)
            resolve(res)
          })
          .catch(error => {
            commit(types.NOT_PENDING)
            // console.log('+++' + error)
            reject(error.response)
          })
      })
    },
    autoLogin ({commit, dispatch}) {
      commit('PENDING') // Para mostrar el preload
      SrvUsu.getDetails()
        .then(res => {
          if (res.status === 200) {
            const user = res.data
            dispatch('setUser', user)
            commit('LOGIN_SUCCESS')
            commit('NOT_PENDING') // Para quitar el preload
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    logout ({commit}) {
      // console.log('--- logout ---')
      window.localStorage.removeItem('_token')
      // Reseteamos el país
      commit(types.UPDATE_USER_COUNTRY, null)
      commit(types.LOGOUT)
    },
    updateValidableOrders ({commit}) {
      // console.log(`updateValidableOrders ----> ${this.getters.isLoggedIn}`)
      if (this.getters.isLoggedIn) {
        SrvUsu.getUserInfo()
          .then(res => {
            if (res.status === 200) {
              // console.log('------> ', res.data)
              commit(types.UPDATE_VALIDABLE_ORDERS, res.data.validable_orders)
              commit(types.UPDATE_USER_ONLY_AUTORIZED_PRODUCTS, res.data.only_authorized_products)
            } else {
              // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              commit(types.UPDATE_VALIDABLE_ORDERS, 0)
            }
          }, () => {
            // console.log('----->' + error)
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            commit(types.UPDATE_VALIDABLE_ORDERS, 0)
          })
      }
    },
    updateValidableOffers ({commit}) {
      // console.log(`updateValidableOrders ----> ${this.getters.isLoggedIn}`)
      if (this.getters.isLoggedIn) {
        SrvUsu.getUserInfo()
          .then(res => {
            if (res.status === 200) {
              // console.log('------> ', res.data)
              commit(types.UPDATE_VALIDABLE_OFFERS, res.data.validable_offers)
              commit(types.UPDATE_USER_ONLY_AUTORIZED_PRODUCTS, res.data.only_authorized_products)
            } else {
              // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              commit(types.UPDATE_VALIDABLE_OFFERS, 0)
            }
          }, () => {
            // console.log('----->' + error)
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            commit(types.UPDATE_VALIDABLE_OFFERS, 0)
          })
      }
    },
    updateCountry ({commit}, country) {
      Stores.dispatch('removeClient')
      commit(types.UPDATE_USER_COUNTRY, country)
    },
    setUser ({commit}, data) {
      commit(types.SET_USER, data)
    }
  },
  mutations: {
    [types.LOGIN_SUCCESS] (state) {
      state.isLoggedIn = true
    },
    [types.LOGOUT] (state) {
      state.isLoggedIn = false
    },
    [types.SET_USER] (state, data) {
      state.user = data
    },
    [types.PENDING] (state) {
      state.pending = true
    },
    [types.NOT_PENDING] (state) {
      state.pending = false
    },
    [types.UPDATE_VALIDABLE_ORDERS] (state, data) {
      state.info.validable_orders = data
    },
    [types.UPDATE_VALIDABLE_OFFERS] (state, data) {
      state.info.validable_offers = data
    },
    [types.UPDATE_USER_COUNTRY] (state, data) {
      state.info.country = data
    },
    [types.UPDATE_USER_ONLY_AUTORIZED_PRODUCTS] (state, data) {
      state.info.only_authorized_products = data
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    user: state => {
      return state.user
    },
    userInfo: state => {
      return state.info
    },
    role: state => {
      let userRole = false
      if (state.user) {
        userRole = state.user.role
      }
      return userRole
    },
    countries: state => {
      const country = {'id': 'eu', 'label': 'Europe'}
      let countries = [country]
      if (state.user && state.user.countries) {
        countries = [country, ...state.user.countries]
      }

      return countries
    }
  }
}

export default user
