<template lang="pug">
  #catalog-migas
    p(v-if="migas.length > 0")
      button(v-if="!showHidenDetails" v-for="miga in migas" :key="miga.id" @click="setCat(miga.id)")
        span {{miga.name}}
        small(v-if="miga.count") ({{miga.count}})
</template>
<script>

export default {
  name: 'CatalogMigasList',
  props: ['migas', 'showHidenDetails'],
  created () {
    // console.log('migas --->', this.migas)
  },
  methods: {
    setCat (v) {
      // console.log('migas acual: ', this.migas)
      // console.log(`Migas, setCat: ${v}`)
      let pos = this.migas.findIndex(item => item.id === v) // para saber la posicion en la que esta el objeto seleccionado.
      if (pos > -1) {
        let num = this.migas.length
        this.migas.splice(pos + 1, num) // Eliminamos los objetos posteriores al seleccionado.
      }
      // console.log(pos + '---------', this.migas)

      if (this.$route.name !== 'productSingle' && this.$route.name !== 'leadProductSingle' && this.$route.name !== 'productSinglePublic') {
        // Si estamos en el listado.
        this.$emit('toFilterCategory', {'id': v})
      } else {
        // si estamos en el detalle del producto.
        let aMigasSlug = ''
        this.migas.map((item) => {
          // console.log(`item migas: ${item.id}`)
          aMigasSlug += (aMigasSlug === '') ? item.id : '-' + item.id
        })
        // console.log('---------aMigasSlug: ', aMigasSlug)
        // Enviamos a la store  el slug de las migas.
        this.$store.dispatch('setCatalogueMigasSlug', aMigasSlug)

        if (this.$route.name === 'productSingle') {
          // Y nos vamos al listado del catálogo.
          this.$router.push({'name': 'catalogoList'})
        } else if (this.$route.name === 'leadProductSingle') {
          // Y nos vamos al listado del catálogo.
          this.$router.push({'name': 'leadOferta'})
        } else if (this.$route.name === 'productSinglePublic') {
          // Y nos vamos al listado del catálogo.
          this.$router.push({'name': 'catalogoListPublic'})
        }
      }
    },
    back () {
      this.$emit('toHidenDetails', '')
    }
  },
  watch: {
    migas () {
      // console.log('--------->migas:', this.migas)
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-migas{
    min-height: 40px;
    padding:10px 0 10px 18px;
  }

  p{
    margin:0;
  }

  button{
    position: relative;
    font-size: 12px;
    line-height: 14px;
    color: #A1A1A1;
    border: none;
    font-weight: $regular;
    padding: 0;
    background: transparent;

    &:not(:last-child){
      color: $blue-light;

      &:after{
        content: '/';
        margin: 0 6px;
      }
    }
  }
</style>
