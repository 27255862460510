<template>
  <div class="tw-w-flex tw-justify-center">
    <div class="tw-w-full tw-relative">
      <div
        class="tw-flex tw-justify-center tw-absolute tw-w-full -tw-top-1 -tw-z-0 tw-pointer-events-none"
      >
        <div
          id="tickmarks"
          class="tw-flex tw-justify-between tw-text-[#979797] tw-font-extralight tw-text-[18px] tw-w-full tw-max-w-[calc(100%-21px)]"
        >
          <div>
            <span class="tw-absolute -tw-top-6 tw-flex tw-justify-center">
              <span
                class="tw-absolute -tw-top-6 tw-w-10 tw-flex tw-justify-center"
                >-100%</span
              >
              <span>|</span>
            </span>
            <span class="tw-absolute tw-top-[0.7rem]">|</span>
            <span class="tw-text-transparent">|</span>
          </div>
          <div>
            <span class="tw-absolute -tw-top-6 tw-flex tw-justify-center">
              <span
                class="tw-absolute -tw-top-6 tw-w-10 tw-flex tw-justify-center"
                >-50%</span
              >
              <span>|</span>
            </span>
            <span class="tw-absolute tw-top-[0.7rem]">|</span>
            <span class="tw-text-transparent">|</span>
          </div>
          <div>
            <span class="tw-absolute -tw-top-6 tw-flex tw-justify-center">
              <span
                class="tw-absolute -tw-top-6 tw-w-10 tw-flex tw-justify-center"
                >0%</span
              >
              <span>|</span>
            </span>
            <span class="tw-absolute tw-top-[0.7rem]">|</span>
            <span class="tw-text-transparent">|</span>
          </div>
          <div>
            <span class="tw-absolute -tw-top-6 tw-flex tw-justify-center">
              <span
                class="tw-absolute -tw-top-6 tw-w-10 tw-flex tw-justify-center"
                >+50%</span
              >
              <span>|</span>
            </span>
            <span class="tw-absolute tw-top-[0.7rem]">|</span>
            <span class="tw-text-transparent">|</span>
          </div>
          <div>
            <span class="tw-absolute -tw-top-6 tw-flex tw-justify-center">
              <span
                class="tw-absolute -tw-top-6 tw-w-10 tw-flex tw-justify-center"
                >+100%</span
              >
              <span>|</span>
            </span>
            <span class="tw-absolute tw-top-[0.7rem]">|</span>
            <span class="tw-text-transparent">|</span>
          </div>
        </div>
      </div>
      <input
        v-model="value"
        @change="onChange"
        @input="onChange"
        type="range"
        list="tickmarks"
        value="0"
        min="-100"
        max="100"
        class="tw-w-full"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RangeInput",
  data() {
    return {
      value: 0,
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="range"] {
  -webkit-appearance: none;
  -webkit-border-radius: 10px;
  background-color: white;
  height: 6px;
}

input[type="range"]::-webkit-slider-container {
  -webkit-appearance: none;
  -webkit-border-radius: 10px;
  background-color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: #35a7fa;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
}
</style>
