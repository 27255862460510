<template lang="pug">
  #ClientListGrid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado centros].
    .holder-cn(v-if="count")
      .container
        .row.box-1
          .col-sm-5
            p.name #[translate Seleccionar centro]
              small(:class="{'fade-in': count}")  ({{count}} #[translate centros])
          .col-sm-1.text-center
            p #[translate Identificador]
          .col-sm-3
            p #[translate Dirección]
          .col-sm-3
            p #[translate Localidad - Provincia]
      .box-2
        bk-item(v-for="(center, index) in centers" :key="center.id" :center="center" :index="index")
      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")

</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/center/Item-simple.vue'

export default {
  name: 'CenterGridSimple',
  props: ['centers', 'message', 'pages', 'count', 'currentPage'],
  data () {
    return {
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      }
    }
  },
  components: { BkItem, EkPaginate },
  computed: {
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    }
  },
  watch: {
    centers () {
      // console.log(`watch center Grid modal -> pages: ${this.pages}, count: ${this.count}, currentPage: ${this.currentPage}`, this.centers)
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-1{
    padding-top:4px;
    font-size:14px;
    color:#494f58;
    font-weight:$light;

    small{
      font-size:14px;
    }
  }

  .name{
    font-weight: $medium;
  }

  .alert{
    margin-top:40px;
  }

  .box-2{
    max-height: 50vh;
    overflow-y: auto;
    border-top: 1px solid #F1F1F3;
    border-bottom: 1px solid #F1F1F3;

    & > *{
      &:nth-child(odd){
        background-color: #F1F4F7;
      }
    }
  }

</style>
