import { Bar } from 'vue-chartjs'
// import {formatMoney} from '@/filters/format-money'

export default {
  extends: Bar,
  props: ['valuesBar'],
  data () {
    return {
    }
  },
  mounted () {
    // console.log('--', this.datasets)
    this.renderChart(this.datasets, this.options)
  },
  computed: {
    datasets () {
      return {
        'labels': [this.valuesBar.label_bar_one, this.valuesBar.label_bar_two, this.valuesBar.label_bar_three],
        'datasets': [
          {
            'fill': true,
            'backgroundColor': [this.valuesBar.color_bar_one, this.valuesBar.color_bar_two, this.valuesBar.color_bar_three],
            'data': [this.valuesBar.num_bar_one, this.valuesBar.num_bar_two, this.valuesBar.num_bar_three]
          }
        ]
      }
    },
    options () {
      return {
        'responsive': true,
        'maintainAspectRatio': false,
        'legend': {
          'display': false // Oculta el Label del Dataset
        },
        'tooltips': {
          'enabled': false
        },
        'hover': {
          'animationDuration': 1
        },
        'animation': {
          'duration': 1,
          'onComplete': function () {
            var chartInstance = this.chart
            var ctx = chartInstance.ctx
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'
            ctx.font = '16px Arial'

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i)
              meta.data.forEach(function (bar, index) {
                // console.log('kk', bar._model.backgroundColor)
                var color = dataset.backgroundColor[index]
                var data = dataset.data[index]
                // var data = this.$options.filters.formatMoney(dataset.data[index])

                var formatData = Number(data).toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 })

                ctx.fillStyle = color
                ctx.fillText(`${formatData}`, bar._model.x, bar._model.y - 10)
              })
            })
          }
        },
        'scales': {
          'yAxes': [{
            'display': true,
            'ticks': {
              'display': false,
              'min': 0,
              'max': this.getNumMax() + 240
            },
            'gridLines': {
              'display': true,
              'drawBorder': false, // línea inicial
              // 'tickMarkLength': 100
              'lineWidth': 1, // Grosor líneas
              'color': '#ffffff', // Color Líneas
              'drawTicks': false
            }
          }],
          'xAxes': [{
            'stacked': true,
            'display': true,
            'barThickness': 40, // altura barra,
            'position': 'bottom',
            'ticks': {
              'display': true,
              'stepSize': 100,
              'fontColor': '#c4c5c7',
              'fontSize': 14
              // 'padding': 10, // Espacio entre la línea y el texto de su label
            },
            'gridLines': {
              'display': false // lineas
            }
          }]
        }
      }
    }
  },
  methods: {
    getNumMax () {
      let num = Math.max(this.valuesBar.num_bar_one, this.valuesBar.num_bar_two)
      num = (num * 2) / 1.5
      return num
    }
  },
  watch: {
    /* datasets () {
      // console.log('++++', this.datasets)
      this.renderChart(this.data, this.options)
    }, */
    valuesBar () {
      // console.log(`change---> ${this.valuesData.dataOne[0]} , ${this.datasets.datasets[0].data[0]}`)
      this._data._chart.destroy()
      this.renderChart(this.datasets, this.options)
    }
  }
}
