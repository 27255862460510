<template>
  <table role="table" class="tw-min-w-full tw-divide-y tw-divide-gray-200">
    <thead class="tw-bg-white">
    <tr role="row" class="tsm-ranking-table-header">
      <th scope="col" class="tw-select-none tw-border-r last:tw-border-0 tw-group tw-pl-3 tw-pr-2 tw-w-[240px] tw-max-w-[240px] tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader">
        <div class="tw-flex tw-justify-between tw-text-[14px] tw-font-light tw-uppercase">Nº Centro</div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-pb-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Emisiones Total Kg CO<sub>2</sub>e</div>
          <div class="tw-opacity-0 tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" src="./grid-arrow-up-active.svg" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-pb-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">Pedidos totales</div>
          <div class="tw-opacity-0 tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" src="./grid-arrow-up-active.svg" alt="Filter">
          </div>
        </div>
      </th>
      <th scope="col" class="tw-cursor-pointer tw-border-r last:tw-border-0 tw-group tw-pl-2 tw-pr-2 tw-pb-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" colspan="1" role="columnheader" title="Toggle SortBy">
        <div class="tw-flex tw-gap-1 tw-text-[12px] tw-font-light tw-uppercase">
          <div class="tw-leading-4">PEDIDOS Mensuales</div>
          <div class="tw-opacity-0 tw-flex tw-items-end">
            <img class="tw-min-w-[11px] tw-min-h-[9px]" src="./grid-arrow-up-active.svg" alt="Filter">
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody role="rowgroup" class="tw-bg-white tw-divide-y tw-divide-gray-200">
      <tr v-if="data && data.length" v-for="(item, index) in data" role="row" class="odd:tw-bg-[#FAFBFC] even:tw-bg-white tsm-ranking-table-rows">
      <td role="cell" class=" tw-font-light tw-flex tw-gap-3 last:tw-border-0 tw-border-r tw-pl-3 tw-pr-2 tw-py-2 tw-w-[240px] tw-max-w-[240px] tw-break-words">
        <span>{{ index + 1 }}</span>
        <span class="tw-uppercase">{{ item[0] }}</span>
      </td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item[1] }} t CO2</td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item[2] }}</td>
      <td role="cell" class="tw-text-right tw-font-light tw-border-r last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap">{{ item[3] }}</td>
    </tr>
      <tr v-show="data && data.length === 0" role="row" class="odd:tw-bg-[#FAFBFC] even:tw-bg-white tsm-ranking-table-rows">
        <td role="cell" class=" tw-font-light tw-flex tw-gap-3 last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-w-[240px] tw-max-w-[240px] tw-break-words">
          <span>Sin datos</span>
          <span class="tw-uppercase"></span>
        </td>
        <td role="cell" class="tw-text-right tw-font-light last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap"></td>
        <td role="cell" class="tw-text-right tw-font-light last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap"></td>
        <td role="cell" class="tw-text-right tw-font-light last:tw-border-0 tw-pl-3 tw-pr-2 tw-py-2 tw-whitespace-nowrap"></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'ranking-table',
  props: ['data']
}
</script>
