<template lang="pug">
  .favorite-common(v-if="isTypeFavorite" @click="changeState()")
    img(v-if="$route.name === 'favoritesList'" :src="img.remove" width="12")
    img(v-else :src="imgStar" width="14")
</template>

<script>
export default {
  name: 'FavoriteCommon',
  props: ['dataId'],
  data () {
    return {
      'img': {
        'star': require('@/assets/img/icos/star.svg'),
        'starActive': require('@/assets/img/icos/star-active.svg'),
        'remove': require('@/assets/img/icos/basura-01.svg')
      }
    }
  },
  computed: {
    imgStar () {
      if (this.state) {
        return this.img.starActive
      }
      return this.img.star
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isTypeFavorite () {
      return this.config_app.favorite
    },
    favoritesItems () {
      return this.$store.getters.favoritesItems
    },
    state () {
      const ref = this
      const id = this.favoritesItems.filter(function (item) {
        return item.obj.id === ref.dataId
      })
      return id[0]
    }
  },
  created () {
  },
  methods: {
    changeState () {
      // console.log(`id: ${this.dataId}, state: `, this.state)
      if (this.state === undefined) {
        // añade con el id del producto
        this.$store.dispatch('addItemFavorite', this.dataId)
      } else {
        // elimina con e ID del favorito.
        this.$store.dispatch('removeItemFavorite', this.state.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .favorite-common{
    position: absolute;
    top: 0;
    right: 4px;
    cursor:pointer;
    @include transition();

    &:hover, &:focus{
      opacity: .8;
    }
  }
</style>
