<template lang="pug">
  #dasboardVentasRankingSearch
    form(@submit.prevent="toSearch" @keyup.enter="toSearch")
      .input-group
        input(ref="search" id="ranking-search-query" class="form-control" type="search" v-model="q" :placeholder="placeholder.search" value="")
        span.input-group-btn
          button.btn.btn-action(type="submit")
            i.fa.fa-search(title="Search")
</template>
<script>
export default {
  name: 'ClientSearch',
  data () {
    return {
      q: null,
      placeholder: {
        'search': this.$gettext('Buscar')
      }
    }
  },
  methods: {
    toSearch () {
      let query = this.q

      this.$emit('toSearch', [query])
    }
  }
}
</script>
<style lang="scss" scoped>

  .input-group{
    max-width: 780px;
    display: inline-table;
    vertical-align: middle;

    .btn{
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  #ranking-search-query{
    line-height: 40px;
    height: 40px;
    border-color: #ccc;
  }


</style>
