<template lang="pug">
  .catalog-GridItem(v-if="product" :class="{'type-grid': view=='grid', 'type-outlet': product.is_outlet, 'type-eco': product.is_eco}" )
      small(v-if="product.is_eco") #[translate Eco]
      small(v-if="product.is_outlet") #[translate Outlet]
      span.img-cn
        img(:src="checkEmptyImg(product.image_url)" @click="goProduct()")
      h3.text-center(@click="goProduct()")
        span {{checkEmpty(product.name)}}
      .group-item
        p.code-cn ID: {{product.external_id}}
      .text-right
        bk-cotiza(:product="product" type="list" :provinces="provinces")

</template>
<script>
import {mapGetters} from 'vuex'

import BkCotiza from '@/components/common/Cotiza.vue'

export default {
  name: 'CatalogGridItemPublic',
  props: ['product', 'view', 'provinces'],
  components: { BkCotiza },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  methods: {
    goProduct () {
      // this.$router.push({name: 'catalogProduct', params: {id: this.product.id}})
      const slug = this.product.id + '-' + this.$options.filters.slugable(this.product.name)
      this.$emit('toGoProduct', [slug])
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>
<style lang="scss" scoped>

  .catalog-GridItem{
    position: relative;
    margin-bottom: 12px;
    border:1px solid #ffffff;
    background-color: #ffffff;
    padding:9px;
    text-align: left;
    width:218px;
    @include transition();

    &.type-new{
      border-color: rgba(#8D22A7, .77);

      & > small{
        background-color: #4467C8;
      }
    }

    &.type-eco{
      border-color: #7eb833;

      & > small{
        background-color: #7eb833;
      }
    }

    &.type-outlet{
      border-color: #f0bd4e;

      & > small{
        background-color: #f0bd4e;
      }
    }

    &:hover, &:focus{
      border-color: #1592E6;
    }

    & > small{
      position: absolute;
      z-index: 9;
      top:0;
      left:0;
      display: block;
      padding: 4px;
      color: $white;
      text-transform: uppercase;
      font-size:10px;
    }
  }
  .img-cn{
    position:relative;
    width: 100%;
    min-height:160px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px 0 14px;
    overflow: hidden;

    img{
      max-width: 100%;
      max-height: 160px;
      height:auto;
      cursor: pointer;
    }
  }

  h3{
    color:#303030;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $regular;
    margin:0 0 10px;
    cursor:pointer;
    @include transition();
    min-height: 56px;
    @include multiEllipsis (56px, 3);
    -webkit-box-orient: vertical;
  }

  .group-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .code-cn{
    font-size: 14px;
    color: #ADADAD;
    margin: 0 0 10px;
  }

  .action{
    width:100%;
    margin-top: -6px;

    .Precio-box{
      p{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .box-2{
      text-align: right;
      margin-top: 14px;
      min-height: 40px;
      strong{
        float:left;
        margin-top:4px;
      }
    }

    .cant{
      display:inline-block;
      width:110px;
      border: 1px solid #d5d8dc;
      @include roundAll(4);
      overflow: hidden;
    }

    input{
      width:60px;
      border:none;
      height: 32px;
      line-height: 32px;
      text-align: center;
    }

    i{
      width:46px;
      background-color:#eff3f6;
      color: $orange;
      text-align: center;
      height: 32px;
      line-height: 32px;
      border-left:1px solid #d5d8dc;
      cursor:pointer;
      @include transition();

      &:hover, &:focus{
        background-color:$orange;
        color:$white;
      }
    }
  }

  .cotiza-common.text-right{
    width: 100%;
  }

</style>
