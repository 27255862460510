<template lang="pug">
  .ofertas-validar-single
    
    .box-search
      .container
        .row
          .col-sm-12
            a(href="#" @click="goBack()") #[translate Volver]
            // translate Oferta:
            // strong {{number}}
    .container(v-if="messageError")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-warning fade in" role="alert") {{messageError}}

    .container(v-if="messageOk")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-success fade in" role="alert") {{messageOk}}

    div(v-if="!messageError")

      .box-1
        .container
          .row
            .col-sm-12
              .date-cn
                span(v-if="isTypeApp === 'vario'") #[translate Fecha de la oferta]:&nbsp;
                span(v-else) #[translate Fecha de envío de la oferta]:&nbsp;
                strong {{ date | moment("DD-MM-YYYY")}}

      .container.table-1(:class="{'full-cn': isTypeApp === 'vario'}")
        .row.table-head
          .holder-cn(v-if="isTypeApp !== 'vario'")
            h3 #[translate Centro]
          .holder-cn
            h3  #[translate Cliente]

        .row.table-grid
          .holder-cn(v-if="center && isTypeApp !== 'vario'")
            p {{center.name}}
            p {{center.address}}
            p ({{center.postal_code}}, {{center.locality}})
          .holder-cn(v-if="center")
            p {{client.name}}
            p {{client.address}}
            p ({{client.postal_code}}, {{client.locality}})

      .container.table-2
        .row.table-head(v-if="isTypeApp === 'vario'")
          .col-sm-3 {{numItems}} #[translate productos]
          .col-sm-3 &nbsp;
          .col-sm-1.text-right.precio-und #[translate Precio Unidad]
          .col-sm-1.text-right #[translate Cantidad]
          .col-sm-1.text-right #[translate Format]
          .col-sm-1.text-right #[translate Descuento]
          .col-sm-1.text-right #[translate Margin]
          .col-sm-1.text-right #[translate total]
        .row.table-head(v-else)
          .col-sm-4 &nbsp;
          .col-sm-2.text-right #[translate Precio Unidad]
          .col-sm-1.text-right #[translate Cantidad]
          .col-sm-1.text-right #[translate Descuento]
          .col-sm-1.text-right #[translate total]

        .table-grid
          bk-item(v-for="item in items" :key="item.id" :product="item")
      // Ponemos 'kk' para ocultar el bloque a todos los ecom, así lo podemos activar si lo necesitan.
      .box-payment(v-if="isTypeApp === 'kk'")
        .container
          .row
            .col-sm-7
              img(:src="img.payment")
              translate Payment
            .col-sm-3
              strong #[translate Payment options]:
              em  XXXX
            .col-sm-2
              strong #[translate Discount]
              em -X,XX {{moneda}}

      .box-3
        .container
          .row(v-if="isTypeApp === 'vario'")
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li #[translate subtotal]: #[small {{base | format-money}} {{moneda}}]
                li #[translate Discounts]: #[small {{discounts | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]
          .row(v-else)
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]

      
      .box-4
        .container
          .row
            .col-sm-8.text-right.pull-right
              p(v-if="user && user.can_validate_offers && type !== 'validados'")
                button.btn.btn-cancel(@click="anular()" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Anular]
                  span(v-if="enviando") #[translate Anulando]
                button.btn.btn-action(@click="validar()" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Validar]
                  span(v-if="enviando") #[translate Validando]
              p(v-if="type == 'validados'")
                button.btn.btn-cancel(@click="goBack()") #[translate Volver]
              p &nbsp;

</template>
<script>
import BkItem from '@/components/ofertas/Item-single.vue'
// importamos servicios
import SrvValidarOfertas from '@/services/validar-ofertas'

export default {
  name: 'OfertasValidarSingle',
  props: ['id', 'type'],
  components: { BkItem },
  data () {
    return {
      messageOk: null,
      messageError: null,
      number: null,
      date: null,
      base: null,
      discounts: null,
      tax: null,
      total: null,
      moneda: null,
      details: null,
      center: null,
      client: null,
      items: [],
      enviando: false
    }
  },
  computed: {
    numItems () {
      if (this.items) {
        return this.items.length
      }
      return 0
    },
    user () {
      return this.$store.getters.user
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  created () {
    this.getOferta()
    console.log(`id: ${this.id}, type: ${this.type}`)
  },
  methods: {
    getOferta () {
      SrvValidarOfertas.getOferta(this.id)
        .then(res => {
          // console.log('getOferta: ', res.data)
          if (res.status === 200) {
            const data = res.data
            this.number = data.number
            this.date = data.offer_datetime
            this.base = res.data.base
            this.discounts = res.data.discounts
            this.tax = res.data.tax_total
            this.total = res.data.total
            this.moneda = data.currency_symbol
            this.items = res.data.entries
            this.details = data.details
            this.client = res.data.client
            this.center = res.data.center
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    anular () {
      if (!this.enviando) {
        this.enviando = true
        SrvValidarOfertas.validarAnular(this.id, 'True')
          .then(res => {
            if (res.status === 200) {
              // console.log(`Anular: `, res.data)
              this.messageError = this.$gettext('Oferta anulada.')
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            // actualizamos el valor de los pedidos pendientes.
            this.$store.dispatch('updateValidableOrders')
            this.enviando = false
          }, () => {
            // console.log('----->' + error)
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            this.enviando = false
          })
      }
    },
    validar () {
      if (!this.enviando) {
        this.enviando = true
        SrvValidarOfertas.validarAnular(this.id, 'false')
          .then(res => {
            if (res.status === 200) {
              // console.log(`Anular: `, res.data)
              this.messageOk = this.$gettext('Oferta validada.')
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            // actualizamos el valor de los pedidos pendientes.
            this.$store.dispatch('updateValidableOffers')
            this.enviando = false
          }, (error) => {
            // console.log('----->' + error)
            if (error.data.code === '100') {
              this.messageError = this.$gettext('La oferta pasa a estar pendiente de validación.')
            } else if (error.data.code === '102') {
              this.messageError = error.data.message
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            window.scrollTo(0, 0)
            this.enviando = false
          })
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .ofertas-validar-single{
    padding:0 0 200px;
  }

  .box-search{
    padding: 14px 0;
    background-color: #EFEFEF;

    span{
      margin-left: 18px;
      font-weight: $medium;
    }

    strong{
      margin-left: 6px;
      font-weight: $medium;
    }

  }

  .alert{
    margin-top: 40px;
    &.alert-success{
      background-color: #dff0d8;
      color: #3c763d;
      border:1px solid #d6e9c6;
    }
  }

  .box-1{
    padding: 26px 0 20px;
    font-size: 16px;
    small{
      font-size: 16px;
    }

    .date-cn{
      span{
        font-weight: $bold;
      }
    }

    strong{
      font-weight: $regular;
    }
  }

  .table-1{
    &.full-cn{
      .table-head, .table-grid{
        .holder-cn{
          width: 100%;
        }
      }
    }
    
    .table-head{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;

      .holder-cn{
        width: 48%;

        &:nth-child(3){
          h3 {
            font-weight: $medium;
          }
        }
      }

      h3{
        border-bottom: 1px solid #1C2C40;
        font-size: 14px;
        font-weight: $regular;
        padding-bottom: 6px;
      }

      p{
        margin: 0;
      }
    }

    .table-grid{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;
      font-size: 14px;
      color:#4D4F5C;

      .holder-cn{
        width: 48%;
        padding: 20px 18px;
        background-color: #F1F4F7;
      }

      strong{
        min-width: 130px;
        display: inline-block;
        font-weight: $regular;
      }
    }
  }

  .table-2{
    .table-head{
      margin: 6px 0;
      & > *{
        padding-bottom: 6px;
        border-bottom: 1px solid #979797;
        font-size: 14px;

        &:first-child{
          font-weight: $medium;
        }
      }

      .precio-und{
        padding-left: 0;
        padding-right: 0;
      }

      p{
        margin: 0;
      }
    }
  }

  .box-3{
    margin:24px 0 30px;

    .row{
      border-bottom: 2px solid #17296E;
      padding-bottom:16px;
      margin: 0;
    }

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 16px;
      margin-bottom: 14px;

      small{
        font-size: 16px;
        float: right;
      }

      &:last-child{
        font-size: 18px;
        font-weight: $medium;
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }

  .Select-bk{
    width: 240px!important;

    .dropdown-toggle{
      height: 42px!important;
      line-height: 42px!important;

      i{
        line-height: 40px!important;
      }
    }
  }

  .box-payment{
    .row{
      margin: -1px 0 0;
      padding: 30px 0;
      border-top: 2px solid #17296E;
      border-bottom: 2px solid #17296E;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > * {
        font-size: 14px;
        color: #1C2C40;

        &:first-child{
          padding-left:0;
          font-size: 16px;

          img{
            margin-right: 20px;
          }
        }

        &:last-child{
          text-align: right;
          padding-right: 0;

          em{
            background-color: #E2E2E2;
            min-width: 70px;
            height: 34px;
            line-height: 34px;
            text-align: right;
            display: inline-block;
            @include roundAll(2);
            padding: 0 8px;
            vertical-align: middle;
          }
        }

        strong{
          font-weight: $regular;
          margin-right: 20px;
        }

        em{
          font-style: normal;
          color: #646464;
        }
      }
    }
  }

  .box-4{
    p{
      font-size: 18px;
      color: #494f58;
    }

    .btn{
      font-size: 16px;
      font-weight: $regular;
      margin-left: 20px;
    }
  }
</style>
