<template lang="pug">
  #catalog-grid
    .container
      .row.grid-head
        div(:class="{'col-sm-3':client, 'col-sm-5':!client}") #[translate Producto por consumo]
        .col-sm-2(v-if="isTypeApp === 'pedrosa'") #[translate Ref.]
        .col-sm-2(v-else) #[translate Código]
        .col-sm-4.text-center.consumo-hd
          div(v-if="center")
            p #[translate Consumo]
            p #[translate Ver]
              button(@click="changeConsumptions(6)" :class="{'active': monthlyConsumption == 6}") #[translate 6 meses]
              button(@click="changeConsumptions(24)" :class="{'active': monthlyConsumption == 24}") #[translate 24 meses]
          div(v-else) &nbsp;
        .col-sm-1.precio-box.text-right(v-if="showPrice") #[translate Precio]
        .col-sm-1.precio-box.text-right(v-else) &nbsp;
        .col-sm-2.text-center(v-if="client") #[translate Añadir al  carro]
      .row#grid-cn
        .col-sm-12
          bk-item(v-for="product in aProduct" :key="product.id" :product="product" :monthlyConsumption="monthlyConsumption" @toAddCart="toAddCart" @toOpenPopupProduct="toOpenPopupProduct"  @showMessage="showModalMsgStockOnRequest")
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .container(v-if="count == 0")
      .row
        .col-sm-12
          .msg-holder(:style="{backgroundImage: `url('${img.opssBg}')`}" role="alert")
            p
              img(:src="img.opss" alt="")
            div(v-if="category && $route.query.cat && $route.name === 'pedidosComprarSearch'")
              p #[translate No hemos encontrado ningún producto con ese nombre dentro de ]
                strong {{nameCat}}.
              p #[translate Si quieres puedes buscar en todas las categorías].
              p
                a(@click="goCatalogoList" class="btn btn-action" )
                  translate Buscar en todas las categorías

            div(v-else-if="complete === 'True' || client.only_authorized_products === true")
              p #[translate No se han encontrado productos].
            div(v-else)
              p #[translate No hemos encontrado ningún producto con ese nombre].
              p #[translate Si quieres puedes buscar en el catálogo completo]
              p
                button(class="btn btn-action" @click="changeFilterProductAll()") #[translate Buscar en todo el catálogo]

    .container(v-if="totalPages > 1")
      .row
        .col-sm-12.text-center
          ek-paginate(:page-count="totalPages"
            :page-range="4"
            :margin-pages="2"
            :click-handler="pagChange"
            :prev-text="paginationTxt.prev"
            :next-text="paginationTxt.next"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :initial-page="currentPage-1"
            v-show="totalPages")
    bk-popup(ref="popupProduct")
    bk-modal(ref="modalCommon" :title="modal.title" :content="modal.content" :footer="modal.footer" )
    p.hidden
      button(data-toggle="modal" data-target="#modal-common" class="btn-modal" ref="showModal")
</template>
<script>
import {mapGetters} from 'vuex'
import BkItem from '@/components/order/buy/Item.vue'
import BkPopup from '@/components/order/buy/Item-popup.vue'
import EkPaginate from 'vuejs-paginate'
import BkModal from '@/components/common/Modal.vue'
import commonMixins from '@/mixins/commons.js'
// importamos servicios Catálogo
import SrvCatalog from '@/services/catalog'

export default {
  name: 'CatalogGrid',
  components: { EkPaginate, BkItem, BkPopup, BkModal },
  props: ['query', 'ordering', 'typeProduct', 'filterByType', 'category', 'categories'],
  mixins: [commonMixins],
  data () {
    return {
      message: null,
      aProduct: [],
      count: null,
      totalPages: 0,
      currentPage: 1,
      complete: '', // para mostrar precios especiales si son autorizados o no --> a True muestra todo autorizados y no autorizados
      add_extra_products: '', // para mostrar los productos extra de orbis.
      only_usual_products: '', // para mostrar los artículos más consumidos.
      monthlyConsumption: 24, // donde indicamos el consumo mensesula que mostraremos.
      orderby: '', // para ordenar los resultados, vacío se ordena Alfabéticamente.
      is_outlet: '', // para mostrar los artículos outlet.
      is_eco: '', // para mostrar los artículos eco.
      is_covid: '', // para mostrar los artículos covid.
      is_disinfection: '',
      is_innovative: '',
      paginationTxt: {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      setTimeoutToSearch: null,
      img: {
        'opss': require('@/assets/img/opss.png'),
        'opssBg': require('@/assets/img/opss_fondo.png')
      },
      'modal': {
        'title': '',
        'content': '',
        'footer': ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    country () {
      return this.$store.getters.userInfo.country
    },
    center () {
      return this.$store.getters.center
    },
    client () {
      return this.$store.getters.client
    },
    has_special_prices () {
      if (this.$store.getters.client) {
        return this.$store.getters.client.has_special_prices
      }
      return false
    },
    catalogueOptions () {
      return this.$store.state.client.catalogueOptions
    },
    nameCat () {
      // console.log(`------------ ${this.category}`, this.categories)
      if (this.category) {
        const idCat = this.category
        const cat = this.categories[0].children.filter(item => item.id === parseInt(idCat))
        return cat[0].name
      }
      return ''
    }
  },
  created () {
  },
  mounted () {
    // console.log(`Mounted grid catálogo -> Complete: ${this.complete} , has_special_prices: ${this.has_special_prices}`)
  },
  methods: {
    showModalMsgStockOnRequest (titulo, msg) {
      const labelBtn = this.$gettext('Aceptar')
      this.modal.title = titulo
      this.modal.content = `<p style="text-align:center;padding-top:20px;">${msg}</p>
                            <p style="margin-top:40px;text-align:center;"><button type="button" data-dismiss="modal" class="btn btn-action">${labelBtn}</button></p>`
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      console.log(this.$refs.showModal)
      this.$refs.showModal.click()
    },
    pagChange (pageNum) {
      // console.log(pageNum)
      this.toSearch(this.query, pageNum)
    },
    toSearch (query, page) {
      // para evitar hacer varias peticiones al mismo tiempo
      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        self.getProduct(query, page)
      }, 500)
    },
    toSearchAll () {
      // buscar en todo el catálogo.
      // console.log(`toSearchAll: ${this.query}`)
      this.toSearch(this.query, 1)
    },
    getProduct (query, page) {
      // console.log(`getProduct ----> complete: ${this.complete}, has_special_prices ${this.has_special_prices}, typeProduct: ${this.typeProduct}`)
      // Si no tiene productos autorizados/especiales le indicamos que saque todos los productos.
      // console.log(`--->toSearch: ${query}, page: ${page}, supplier: ${this.suppliers}, category: ${this.category}, complete: ${this.complete}, orden: ${this.orderby}, center: ${this.center}`)
      if (!this.typeProduct) {
        this.complete = (!this.has_special_prices) ? 'True' : this.complete
      }
      window.scrollTo(0, 0)
      this.count = null
      SrvCatalog.getProductsBuy(query, page, this.complete, this.orderby, this.only_usual_products, this.category, this.is_outlet, this.is_eco, this.is_disinfection, this.is_innovative, this.add_extra_products)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Producto: ', res.data.results)
            this.aProduct = res.data.results
            this.count = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
            if (this.count === 0) {
              // console.log(`complete: ${this.complete}, only_usual_products: ${this.only_usual_products}`)
            }
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeFilterProductAll () {
      this.catalogueOptions.filterViewProducts = 'all'
      // enviamos la actualización a la store.
      this.$store.dispatch('setCatalogueOptions', this.catalogueOptions)
    },
    toAddCart (item) {
      // console.log(`Component Grid -> id product: ${item.idProduct}, quantity: ${item.quantity}`)
      this.$emit('toAddCart', item)
    },
    toGoProduct (id) {
      this.$emit('toGoProduct', id)
    },
    toOpenPopupProduct (id) {
      // aProduct
      // console.log('toOpenPopupProduct: ' + id)
      this.$refs.popupProduct.openModal(id)
    },
    changeConsumptions (v) {
      // console.log(`meses: ${v}`)
      this.monthlyConsumption = v
    },
    goCatalogoList () {
      this.count = null // Para que se quite el mensaje de que no hay resultados.
      // repetimos la búsqueda sin la categoría.
      this.$router.push({'name': 'pedidosComprarSearch', 'query': { 'q': this.query }})
    }
  },
  watch: {
    country () {
      // actualizamos el valor de los pedidos pendientes.
      this.currentPage = 1 // Inicializamos la páginación
      this.toSearch(this.query, this.currentPage)
    },
    query () {
      // console.log(`Cambio category: ${this.category}`)
      this.currentPage = 1 // Inicializamos la páginación
      this.toSearch(this.query, this.currentPage)
    },
    center () {
      // console.log('cambio center:', this.center)
      this.currentPage = 1 // Inicializamos la páginación
      // console.log(`toSearch center`)
      this.toSearch(this.query, this.currentPage)
    },
    client () {
      // console.log('----cambio cliente:', this.client)
      this.currentPage = 1 // Inicializamos la páginación
      // console.log(`toSearch center`)
      this.toSearch(this.query, this.currentPage)
    },
    ordering () {
      // console.log(`GRID cambio ordering: ${this.ordering}`)

      const orderBy = this.ordering.toString()

      switch (orderBy) {
        case 'price_asc':
          // Precio: bajo a alto
          this.orderby = 'final_price'
          break
        case 'price_desc':
          // Precio: alto a bajo
          this.orderby = '-final_price'
          break
        case 'consumption_asc':
          // Consumo: bajo a alto
          if (this.center) {
            this.orderby = 'total_consumption'
          }
          break
        case 'consumption_desc':
          // Consumo: alto a bajo
          if (this.center) {
            this.orderby = '-total_consumption'
          }
          break
        default:
          this.orderby = orderBy
      }

      this.currentPage = 1 // Inicializamos la páginación
      // console.log(`toSearch Ordering`)
      this.toSearch(this.query, this.currentPage)
    },
    typeProduct () {
      // console.log(`watch grid catálogo --> typeProduct: ${this.typeProduct}`)
      let type = this.typeProduct.toString()
      // PRODUCTOS DE ORBITA SOLO EN ORBISH (FUNCTION aFilterProductList FILTER-TOP.vue)
      if (type === 'add_extra_products') {
        this.complete = 'false'
        this.add_extra_products = 'True'
      } else if (type === 'special_price') {
        this.complete = 'false'
        this.add_extra_products = 'false'
      } else if (type === 'all' || !this.has_special_prices) {
        this.complete = 'True'
        this.add_extra_products = 'false'
      }
      this.currentPage = 1 // Inicializamos la páginación
      // console.log(`toSearch type Product -> complete: ${this.complete}`)
      this.toSearch(this.query, this.currentPage)
    },
    filterByType () {
      let type = this.filterByType.toString()
      if (type === 'only_usual_products') {
        this.only_usual_products = 'True' // filtrar por productos más consumidos.
        this.is_outlet = '' // filtar por outlet.
        this.is_eco = '' // filtar por eco.
        this.is_disinfection = ''
        this.is_innovative = ''
      } else if (type === 'is_outlet') {
        this.only_usual_products = 'false'
        this.is_outlet = 'True'
        this.is_eco = ''
        this.is_disinfection = ''
        this.is_innovative = ''
      } else if (type === 'is_eco') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = 'True'
        this.is_disinfection = ''
        this.is_innovative = ''
      } else if (type === 'is_disinfection') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_covid = ''
        this.is_disinfection = 'True'
        this.is_innovative = ''
      } else if (type === 'is_innovative') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_covid = ''
        this.is_disinfection = ''
        this.is_innovative = 'True'
      } else {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
        this.is_disinfection = ''
        this.is_innovative = ''
      }
      this.currentPage = 1 // Inicializamos la páginación
      // console.log(`toSearch type Product -> complete: ${this.complete}`)
      this.toSearch(this.query, this.currentPage)
    },
    category () {
      // console.log(`watch category`)
      this.currentPage = 1 // Inicializamos la páginación
      this.toSearch(this.query, this.currentPage)
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-grid{
    padding-bottom:40px;
  }

  .grid-head{
    margin: 6px 0;

    & > *{
      padding-bottom: 6px;
      border-bottom: 1px solid #1C2C40;
      font-size: 14px;

      &:first-child{
        font-weight: $medium;
      }
    }
  }

  .catalog-GridItem{
    @include transition();
    &:hover, &:focus{
      background-color: #EEF2F6;
    }

    &:nth-child(odd){
      background-color: #F5F7F9;

      &:hover, &:focus{
        background-color: #EEF2F6;
      }
    }

    &:last-child{
        border-bottom: 1px solid #1C2C40;
    }
  }

  .precio-box{
    padding-left: 0;
    padding-right: 0;
  }

  .consumo-hd{
    p{
      display: inline-block;
      margin-bottom: 0;

      &:first-child{
        margin: 0 14px 0 40px;
      }

      &:last-child{
        font-size: 13px;
        color: #979797;
      }
    }

    button{
      position: relative;
      background-color: transparent;
      border: none;
      line-height: normal;
      padding-left: 30px;

      &.active{
        &:after{
          opacity: 1;
        }
      }

      &:before{
        content:'';
        position: absolute;
        top:1px;
        left:10px;
        width: 16px;
        height: 16px;
        border: 1px solid #E1E1E1;
        @include roundAll100(50%);
      }

      &:after{
        content:'';
        position: absolute;
        top:4px;
        left:13px;
        width: 10px;
        height: 10px;
        background-color: #00ADFC;
        border: 1px solid #00ADFC;
        @include roundAll100(50%);
        opacity: 0;
        @include transition();
      }
    }
  }

  .msg-holder{
    text-align: center;
    min-height: 600px;
    margin: 0 -32px;
    padding: 80px 0 130px;
    overflow: hidden;
    background-color: #FAFAFA;

    img{
      margin-bottom: 30px;
    }

    div{
      p{
        font-size: 18px;

        &:last-child{
          margin-top: 30px;
        }
      }
    }
  }
</style>
