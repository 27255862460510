<template lang="pug">
  #dashboardResumen
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Resumen]
        .row.row-flex
          .col-sm-6
            bk-migas
          .col-sm-6.text-right
            .dropdown
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Año:] {{yearActive}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(item) in yearsSelect" :key="item" :class="{'disabled': yearActive == item}")
                  span(:value="item" @click="changeYear(item)") {{item}}

    div(v-if="oSummary")
      h3 #[translate Pedidos]
      .container.chart-box.type-horizontalBar
        .row
          .col-sm-6
            .row
              .col-sm-3.c-l
                p &nbsp;
              .col-sm-9.c-r
                p #[translate Mes en curso]
                strong {{oSummary.orders.orders_month | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.orders.orders_month_percentage")
          .col-sm-6.border-left
            .row
              .col-sm-9.c-r
                p #[translate Proyección mes]
                strong {{oSummary.orders.forecast_month | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.orders.forecast_month_percentage")

      h3 #[translate Ventas Año]
      .container.chart-box.type-horizontalBar
        .row
          .col-sm-6
            .row
              .col-sm-3.c-l
                p #[translate Todos]
              .col-sm-9.c-r
                p #[translate Ventas acumuladas]
                strong {{oSummary.sales.sales.all | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales.sales.all_percentage")
            .row
              .col-sm-3.c-l
                p #[translate Activos]
              .col-sm-9.c-r
                strong {{oSummary.sales.sales.active | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales.sales.active_percentage")
            .row
              .col-sm-3.c-l
                p #[translate Nuevos]
              .col-sm-9.c-r
                strong {{oSummary.sales.sales.new | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales.sales.new_percentage")

          .col-sm-6.border-left
            .row
              .col-sm-9.c-r
                p #[translate Proyección anual]
                strong {{oSummary.sales.forecasts.all | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales.forecasts.all_percentage")
            .row
              .col-sm-9.c-r
                strong {{oSummary.sales.forecasts.active | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales.forecasts.active_percentage")
            .row
              .col-sm-9.c-r
                strong {{oSummary.sales.forecasts.new | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales.forecasts.new_percentage")


      h3 #[translate Clientes Nuevos vs Perdidos (acumulado año)]
      .container.chart-box.type-verticalBar
        .row
          .col-sm-6
            .row
              .col-sm-6.c-l
                p #[translate Nº Clientes Nuevos]
                strong {{oSummary.lost_clients.num_new_clients | format-money}}
                p #[translate Nº Clientes Perdidos]
                strong {{oSummary.lost_clients.num_lost_clients | format-money}}
              .col-sm-6.c-r
                .chart-bar-vertical
                  bar-two-chart(:valuesBar="getDataChartClient")
          .col-sm-6.border-left
            .row
              .col-sm-6.c-l
                p #[translate € Clientes Nuevos]
                strong {{oSummary.lost_clients.total_new | format-money}}€
                p #[translate € Clientes Perdidos]
                strong {{oSummary.lost_clients.total_lost | format-money}}€
              .col-sm-6.c-r
                .chart-bar-vertical
                  bar-two-chart(:valuesBar="getDataChartClientsOrders")

      h3 #[translate Ventas proveedores preferentes]
      .container.chart-box.type-horizontalBar
        .row
          .col-sm-6
            .row
              .col-sm-3.c-l
                p #[translate Todos]
              .col-sm-9.c-r
                p #[translate Ventas acumuladas]
                strong {{oSummary.sales_preferred.sales_year | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales_preferred.sales_target_percentage")
          .col-sm-6.border-left
            .row
              .col-sm-9.c-r
                p #[translate Proyección anual]
                strong {{oSummary.sales_preferred.forecast_year | format-money}}€
                .chart-bar
                  horizontal-bar-chart(:valueBar="oSummary.sales_preferred.forecast_year_percentage")
    p(v-else) {{message}}
</template>

<script>
import HorizontalBarChart from '@/components/dashboard/chart/HorizontalBar'
import BarTwoChart from '@/components/dashboard/chart/BarTwo'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardResumen',
  components: { HorizontalBarChart, BarTwoChart, BkMigas },
  data () {
    return {
      idUserDashboard: null, // Id del usuario de que se mostraran los datos.
      oSummary: null, // Dónde guardamos los resultados de Srv getSummary
      yearActive: null, // dónde guardamos el año seleccionado.
      message: ''
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
  },
  mounted () {
    // do something after mounting vue instance
    if (this.idUserDashboard) {
      this.getSummary()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    },
    getDataChartClient () {
      return {
        'label_bar_one': this.$gettext('Nº Nuevos'),
        'num_bar_one': this.oSummary.lost_clients.num_new_clients,
        'color_bar_one': '#7fd04e',
        'label_bar_two': this.$gettext('Nº Perdidos'),
        'num_bar_two': this.oSummary.lost_clients.num_lost_clients,
        'color_bar_two': '#f95d5d'
      }
    },
    getDataChartClientsOrders () {
      return {
        'label_bar_one': this.$gettext('€ Nuevos'),
        'num_bar_one': this.oSummary.lost_clients.total_new,
        'color_bar_one': '#7fd04e',
        'label_bar_two': this.$gettext('€ Perdidos'),
        'num_bar_two': this.oSummary.lost_clients.total_lost,
        'color_bar_two': '#f95d5d'
      }
    },
    yearsSelect () {
      let yearInit = 2018
      const currentYear = new Date().getFullYear()
      let years = []
      while (yearInit <= currentYear) {
        years.push(yearInit++)
      }
      this.yearActive = currentYear

      return years
    }
  },
  methods: {
    getSummary () {
      // Cogemos los datos para los gráficos de resumenes.
      // console.log(`yearActive: ${this.yearActive}`)
      this.message = ''
      SrvDashboard.getSummary(this.idUserDashboard, this.yearActive)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            this.oSummary = null // Reset para el wtch de los graficos.
            this.oSummary = res.data
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, (error) => {
          const status = error.response.status
          // console.log('----->' + status)
          this.oSummary = null
          if (status === 500) {
            // console.log('Datos no disponibles.')
            this.message = this.$gettext('Datos no disponibles.')
          }
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeYear (item) {
      // console.log(`year: ${item}`)
      this.yearActive = item
      this.getSummary()
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getSummary()
    }
  }
}
</script>

<style lang="scss" scoped>
  #dashboardResumen{
    background-color: $white;
    padding: 24px 28px;
    overflow: hidden;
    margin-bottom: 40px;
    min-height: 600px;
  }

  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }

  h3{
    margin: 40px 0 28px;
    font-weight: $medium;
    color: #494f58;
    font-size: 16px;

    &:nth-of-type(1){
      margin-top: 0;
    }

  }

  .chart-box{
    position: relative;
    padding-bottom: 20px;

    &:after{
      content:'';
      position: absolute;
      bottom: 0;
      left: -30px;
      width: 150%;
      height: 1px;
      background-color: #e6eaee;
    }
  }

  .col-sm-6{
    & > .row{
      padding-bottom: 20px;
      margin-bottom: 6px;
    }
  }

  .border-left{
    & > .row{
        border-left: 2px solid #e6eaee;
    }
    .c-r{
        margin-left: 36px!important;
    }
  }

  .type-horizontalBar{

    &:after{
      display: none;
    }
    .c-l, .c-r{
      float:none;
      display: inline-block;
      vertical-align: bottom;
    }
    .c-l{
      text-align: center;
      font-size: 18px;
      color: #494f58;
      padding-bottom: 36px;
    }
    .c-r{
      margin-left: -5px;
      p{
        color: #2c373f;
        font-size: 16px;
        padding-left: 10px;
        margin-bottom: 0px;
      }

      strong{
        font-size: 24px;
        font-weight: $bold;
        color: #008bb5;
        display: block;
        margin-bottom: 4px;
        padding-left: 10px;
      }
    }
  }

  .type-verticalBar{

    .c-l{
      padding-left: 20px;

      p{
        margin-bottom: 0;
        font-size: 16px;
        color: #2c373f;
      }

      strong{
        display: block;
        margin: 0px 0 18px;
        font-size: 24px;
        font-weight: $bold;
        color: #7fd04e;

        &:last-child{
          color: #f95d5d;
        }
      }
    }

    .c-r{
      margin-left: 0!important;
    }
  }

  .chart-bar{
    & > div{
      position: relative;
      height: 90px;
    }
  }

  .chart-bar-vertical{
    & > div{
      position: relative;
      height: 264px;
    }
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    min-width: 160px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 34px;
      line-height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: $blue-light;

      span{
        color: #494949;
      }

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

</style>
