<template lang="pug">
  #dashboardVentasRanking
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Venta Ranking de Clientes]
        .row
          .col-sm-6
            bk-migas

    // Mostramos Zona listado.
    div(v-if="$route.name !== 'dashboardVentasRankingCenter'")
      .container.box-1
        .row
          .col-sm-6
            bk-search(@toSearch="toSearch")
          .col-sm-6.text-right
            .dropdown
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Filtrar:] {{clientStatus_name}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(item, key) in aClientStatus" :class="{'disabled': clientStatus_name == item.name}")
                  span(:value="item.value" @click="changeFiltro(item)") {{item.name}}
            date-picker(class="calendar-cn" v-model="calendar.date" lang="es" :lang="calendar.lang" :format="calendar.format" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)
      .container.box-2
        .row
          .col-sm-12
            bk-grid(
              :clients="aClient"
              :message="message"
              :pages="totalPages"
              :count="count"
              :currentPage="currentPage"
              @toPaginate="toPagChange"
              @toOrdering="toOrdering")

    // Mostramos el Ranking de centros.
    div(v-if="$route.name == 'dashboardVentasRankingCenter'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>

<script>
import appConfig from '@/config-app.js'
import BkSearch from '@/components/dashboard/Search.vue'
import BkGrid from '@/components/dashboard/Listado.vue'
import DatePicker from 'vue2-datepicker'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardVentasRanking',
  components: {BkSearch, BkGrid, DatePicker, BkMigas},
  data () {
    return {
      'idUserDashboard': null, // Id del usuario de que se mostraran los datos.
      'oSalesSummary': null, // Dónde guardamos los resultados de Srv getSalesSummary
      'query': '', // Donde se guarla la palabra a buscar.
      'ordering': '', // Ordenar por nombre ascendente, los valores de ordering son: 'period_sales', 'year_sales'
      'message': null,
      'aClient': [],
      'count': null,
      'totalPages': 0,
      'currentPage': 1, // Para saber porque página vamos en la paginación
      'aClientStatus': null, // Dónde guardamos los diferentes estados de los clientes para el selector de filtro.
      'clientStatus_name': this.$gettext('Todos'),
      'clientStatus_value': 1, // 1 = todos.
      'setTimeoutToSearch': null,
      'calendar': {
        'before': this.$moment().add(-1, 'months').format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().startOf('year').format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().startOf('year').format('YYYY-MM'), this.$moment().add(-1, 'months').format('YYYY-MM')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    }
  },
  created () {
    this.getClientStatus()
    if (this.idUserDashboard) {
      this.getSalesRanking()
    }
  },
  methods: {
    getSalesRanking () {
      SrvDashboard.getSalesRanking(this.idUserDashboard, this.query, this.currentPage, this.clientStatus_value, this.ordering, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getSalesRanking: ', res.data)
            this.aClient = res.data.results
            this.count = res.data.count
            this.totalPages = res.data.total_pages
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getClientStatus () {
      SrvDashboard.getClientStatus()
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getClientStatus: ', res.data)
            res = res.data.filter(item => item.value !== 4)
            this.aClientStatus = res
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeFiltro (item) {
      // console.log(`changeFiltro: ${item.value} - ${item.name}`)
      this.clientStatus_name = item.name
      this.clientStatus_value = item.value
      this.getSalesRanking()
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        this.getSalesRanking()
      }
    },
    toSearch (query) {
      // console.log('query:' + query)

      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        self.resetGrid()
        self.query = query
        self.getSalesRanking()
      }, 500)
    },
    toPagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.getSalesRanking()
    },
    toOrdering (value) {
      // console.log('---' + value)
      this.currentPage = 1
      this.ordering = value
      if (this.idUserDashboard) {
        this.getSalesRanking()
      }
    },
    resetGrid () {
      // reseteamos los parámetros del grid, paginación, orden.
      this.currentPage = 1
      this.ordering = ''
      this.query = ''
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getSalesRanking()
    }
  }
}
</script>

<style lang="scss" scoped>
  #dashboardVentasRanking{
    min-height: 500px;
  }
  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row-flex{
      display:flex;
      align-items: center;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }

  .box-1{
    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    #dasboardVentasRankingSearch{
      margin-left: -15px;
    }
  }

  .box-2{
    padding: 0;
    width: auto;
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    // min-width: 200px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 34px;
      line-height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: #646464;
      font-size: 13px;
      font-weight: $light;

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }

  .calendar-cn{
    width: 210px;
    margin-left: 18px;
  }
</style>
