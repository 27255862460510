// Importamos mutaciones
import * as types from '@/store/mutation-types'

const center = {
  state: {
    isCenter: !!window.localStorage.getItem('_center'),
    center: null
  },
  actions: {
    setCenter ({commit}, data) {
      window.localStorage.setItem('_center', JSON.stringify(data))
      commit(types.SET_CENTER)
      commit(types.CENTER_SUCCESS)
    },
    removeCenter ({commit}) {
      window.localStorage.removeItem('_center')
      commit(types.REMOVE_CENTER)
    }
  },
  mutations: {
    [types.CENTER_SUCCESS] (state) {
      state.isCenter = true
    },
    [types.REMOVE_CENTER] (state) {
      state.center = null
      state.isCenter = false
    },
    [types.SET_CENTER] (state) {
      state.center = JSON.parse(window.localStorage.getItem('_center'))
      // console.log('-->state.center: ' + state.center)
    }
  },
  getters: {
    isCenter: state => {
      return state.isCenter
    },
    center: state => {
      return state.center
    }
  }
}

export default center
