<template lang="pug">
  
  .item-cn.row(v-if="type !== 'budgetwof'")
    .product-cn.col-sm-7
      picture.img-cn
        img(:src="checkEmptyImg(product.image_url)")
      h3 {{product.name}}

    .col-sm-3
      ul
        // li(v-if="product.supplier" class="prov-cn") #[translate Prov].: {{product.supplier.name}}
        li {{product.external_id}}

    .col-sm-2.text-right(v-if="isTypeApp === 'maripol'")
      div(v-if="type !== 'rwopawd' && type !== 'cwopawd'")
        p(v-if="product.currency_symbol") {{priceTotal | format-money}} {{product.currency_symbol}}
        p(v-else) {{priceTotal | format-money}} €
    .col-sm-2.text-right(v-else)
      p(v-if="product.currency_symbol") {{priceTotal | format-money}} {{product.currency_symbol}}
      p(v-else) {{priceTotal | format-money}} €
  .item-cn.row(v-else)
    .product-cn.col-sm-5
      picture.img-cn
        img(:src="checkEmptyImg(product.image_url)")
      h3 {{product.name}}

    .col-sm-1
      ul
        li {{product.external_id}}
    .col-sm-1
      ul
        li {{ itemFormato}}
    .col-sm-1.text-center
      ul
        li {{quantity}}

    .col-sm-2.text-right
      p(v-if="product.currency_symbol") {{price | format-money}} {{product.currency_symbol}}
      p(v-else) {{price | format-money}} €

    .col-sm-2.text-right
      p(v-if="product.currency_symbol") {{priceTotal | format-money}} {{product.currency_symbol}}
      p(v-else) {{priceTotal | format-money}} €


</template>
<script>
// 'budgetwof' nos dice si es presupuesto actualmente solo lo tiene Pedrosa
export default {
  name: 'PedidoSingleItem',
  props: ['entries', 'billable', 'type'],
  data () {
    return {
      isFacturable: false,
      imgDefault: require('@/assets/img/no_disponible.png')
    }
  },
  computed: {
    product () {
      return this.entries.product
    },
    price () {
      // return this.entries.price * this.entries.quantity
      return this.entries.price
    },
    priceTotal () {
      let quantity = 1
      if (this.entries.quantity !== null) {
        quantity = this.entries.quantity
      }
      return this.entries.price * quantity
    },
    quantity () {
      return this.entries.quantity
    },
    itemFormato () {
      if (this.entries.aditional_measurement_unit) {
        return this.entries.aditional_measurement_unit
      } else {
        return this.entries.measurement_unit
      }
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // console.log(`entries:`, this.entries)
    this.isFacturable = this.billable
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>
<style lang="scss" scoped>
  .item-cn{
    margin:0;
    padding: 20px 0 30px;
    border-bottom: 1px solid #979797;
    color:#4D4F5C;
  }
  .row{
    display: flex;
    align-items: center;
  }

  .product-cn{
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 100px;
    font-size: 14px;
  }

  .img-cn{
    position:absolute;
    top:0;
    bottom:0;
    left:6px;
    width: 78px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }
  }

  h3{
    color:#1C2C40;
    font-size: 13px;
    text-transform: uppercase;
    margin:0;
    font-weight: $regular;
    // @include ellipsis();
  }

  ul{
    list-style: none;
    padding:0;
    font-size: 12px;
  }

  .und{
    font-size:14px;
    color:#61c844;

    &.noStock{
      color:#ff5466;
    }
  }
</style>
