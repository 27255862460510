<template lang="pug">
  .item-cn(v-if="productData" :class="{error: !product.is_valid_product && !isCode0, 'type-dragdrop': isTypeDragdrop}")
    span.dragdrop
      img(:src="img.dragdrop" width="8")
    .big(:class="{'no-formato': !config_app.formato}")
      picture.img-cn(:class="{'type-outlet': productData.is_outlet}" )
        img(:src="checkEmptyImg((productData.image_url))")
        // small(v-if="productData.is_eco") #[translate Eco]
        small(v-if="productData.is_outlet") #[translate Outlet]
        bk-favorite(class="favorite-img" :data-id="productData.id" )

      h3 {{productData.name}}
      p(v-if="product.description") {{product.description}}
      ul
        // li(v-if="product.supplier") #[translate Prov].: {{productData.supplier.name}}
        li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.] {{productData.external_id}}
        li(v-else) #[translate Código]: {{productData.external_id}}
        li(v-if="!isCode0 && productData.quantity_by_packet") {{productData.quantity_by_packet}} #[translate unidades por envase]
        li(v-if="!isCode0 && productData.packets_by_package") {{productData.packets_by_package}} #[translate envases por paquete]
        li.stock-cn(v-if="config_app.stock_availability")
          .und(class="normalStock") To be deslisted XXX
        li.stock-cn(v-else)
          //li.stock-cn(v-if="isTypeApp !== 'orbishigiene' && isTypeApp !== 'ghgorbis' && isTypeApp !== 'arablau'")
          span.und(v-if="config_app.on_demand && productData.is_ondemand" :class="{'noStock': !productData.stock}")
            span.bajoPedido(v-if="!isCode0") #[translate Bajo pedido] &nbsp;
            span.gray(v-if="!isCode0") &nbsp; (#[translate no admite devolución])
          span.und(v-else) 
            span(v-if="role !== 'client'") {{productData.stock}} #[translate en stock]
            span(v-else) #[translate stock]
    .other.text-center.quantity
      .dropdown.Select-bk(v-if="role != 'client' && productData.is_pvp_price == true && productData.price_rate_products.length > 1")
        button.form-control(class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{PriceRateItem.price_rate_name}} ({{PriceRateItem.price}})
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddPriceRate")
          li(v-for="price_rate in productData.price_rate_products" :class="{'disabled': PriceRateItem.price_rate_id == price_rate.price_rate_id}")
            span(:value="price_rate.price_rate_product_id" @click="changePriceRate(price_rate)") {{price_rate.price_rate_name}} ({{price_rate.price | format-money}})
      p.Precio-box(v-else)
        span.pvp(v-if="showPrice" :class="{'special': product.is_special_price, 'no-stock': !productData.stock, 'obsolete': productData.obsolete}") {{product.price | format-money}} {{moneda}}
      p
        input(type="number" min="1" :max="limitQuantity" placeholder="0" v-model="product.quantity" @change="changeItem()")

    .other.text-center.formato-cn(v-if="config_app.formato")
      .dropdown.Select-bk(v-if="aFormato && aFormato.length > 1")
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddFormato" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{formatoItem.name}} ({{formatoItem.quantity}})
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddFormato")
          li(v-for="item in aFormato" :class="{'disabled': formatoItem.id == item.id}")
            span(:value="item.id" @click="changeFormato(item)") {{item.name}}
              span(v-if="item.id") &nbsp;#[translate de] {{item.quantity}} #[translate uds].
      p(v-else) -

    .other.text-center
      p(v-if="product.is_special_price || role == 'client' || productMaxDiscount < 1 || productData.external_id === 'PORTES1' || productData.external_id === 'RECOGIDA'  ") &nbsp;
      p(v-if="!product.is_special_price && (role === 'admin' || role === 'sales_manager') && productMaxDiscount > 0 && productData.external_id !== 'PORTES1' && productData.external_id !== 'RECOGIDA' ")
        input(type="number" min="0" :max="productMaxDiscount" :placeholder="productMaxDiscount" v-model="productDiscount" @change="changeItem()")
    .other.text-center
      p(v-if="isTypeApp === 'kkk'") xx %
      p(v-if="!hasFacturable && isTypeApp !== 'vario'") &nbsp;
      transition(name="fade")
        p.facturar-cn(v-if="hasFacturable")
          span.group
            small(:class="{'active on':isFacturable}" @click="changeFacturable(true)") #[translate Si]
            small(:class="{'active':!isFacturable}" @click="changeFacturable(false)") #[translate No]
    .other.text-right
      p.total-cn.Precio-box
        span.pvp(v-if="showPrice" :class="{'special': product.is_special_price, 'no-stock': !productData.stock, 'obsolete': productData.obsolete}") {{ product.total | format-money}} {{moneda}}
      p
        button.btn-remove(@click="removeProduct()")
          img(:src="img.basura" width="16")

</template>
<script>
import {mapGetters} from 'vuex'
import commonMixins from '@/mixins/commons.js'

import BkFavorite from '@/components/common/favorite.vue'

export default {
  name: 'ItemCartList',
  props: ['product', 'hasFacturable', 'moneda'], // si hasFacturable=True se muestra la opción de facturable o no facturable
  components: { BkFavorite },
  mixins: [commonMixins],
  data () {
    return {
      'isFacturable': false,
      'timeQuantity': null, // El temporizador para actualizar la cantidad.
      'discount': '',
      'formatoItem': '',
      'PriceRateItem': '',
      'img': {
        'basura': require('@/assets/img/icos/basura-01.svg'),
        'dragdrop': require('@/assets/img/icos/dragdrop.svg')
      }
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    isCode0 () {
      return this.product.product_data.id === this.config_app.id_code_0
    },
    productData () {
      return this.product.product_data
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    productMaxDiscount () {
      // console.log(`max_discount: ${this.productData.max_discount}`)
      const num = parseInt(this.productData.max_discount)
      return num.toFixed(0)
    },
    productDiscount: {
      get () {
        if (this.product.discount) {
          const num = parseInt(this.product.discount)
          return num.toFixed(0)
        }

        return 0
      },
      set (v) {
        this.product.discount = v
      }
    },
    limitQuantity () {
      // Si tenemos activo el limite lo ponemos en cada caja de cantidad.
      if (this.config_app.stock_limit) {
        return this.productData.stock
      }

      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.productData.obsolete ? this.productData.stock : ''
    },
    aFormato () {
      // opciones del select.
      let aTmp = this.productData.aditional_measurement_units
      if (aTmp) {
        // Unidad por defecto.
        const unit = this.productData.measurement_unit
        let formato = {'id': '', 'name': this.$gettext('Unidad'), 'quantity': 1}
        if (unit) {
          formato = {'id': '', 'name': unit.name, 'quantity': unit.quantity}
        }
        aTmp = [formato, ...aTmp]
      }

      // Unidad selecciona por el usuario.
      let item = this.product.aditional_measurement_unit_data
      if (item) {
        this.formatoItem = item
      } else if (aTmp) {
        this.formatoItem = aTmp[0]
      }

      return aTmp
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    isTypeDragdrop () {
      return this.config_app.sort_cart_drag
    }
  },
  created () {
    this.isFacturable = this.product.billable
    // console.log(`producto: `, this.product)
    this.getPriceRateItem()
  },
  mounted () {
    // this.changeItem()
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    changeFacturable (v) {
      // console.log(`changeFacturable: ${v}`)
      this.isFacturable = v
      this.product.billable = v
      this.$emit('toUpdateProduct', this.product)
    },
    removeProduct () {
      this.$emit('toRemoveProduct', this.product)
    },
    changeItem () {
      // console.log(`Change Item quantity: ${this.product.quantity}, discount:${this.product.discount}, productMaxDiscount: ${this.productMaxDiscount}`)
      // si el descuento que inserta el usuario es mayor que el max, ponemos el máximo.
      if (this.product.discount > parseInt(this.productMaxDiscount)) {
        this.product.discount = this.productMaxDiscount
      }

      if (this.product.quantity < 1) {
        this.product.quantity = 1
      }

      this.checkQuanty()

      this.$emit('toUpdateProduct', this.product)
    },
    changeFormato (item) {
      // console.log(`id: ${item.id}, label: ${item.name}, value: ${item.quantity}`)
      this.formatoItem = item
      this.product.aditional_measurement_unit_tmp = item
      this.$emit('toUpdateProduct', this.product)
    },
    checkQuanty () {
      // console.log(`obsolete: ${this.productData.obsolete}, obsolete_limit: ${this.config_app.obsolete_limit}, quantity: ${this.product.quantity}`)
      // obsolete_limit: true y product obsolete: true no dejamos que pase el limite del stock.
      if (this.productData.obsolete && this.config_app.obsolete_limit) {
        this.product.quantity = Math.max(Math.min(Math.round(this.product.quantity), this.limitQuantity), 0)
      }
    },
    getPriceRateItem () {
      if (this.product.is_price_rate) {
        var priceRateChoices = this.product.product_data.price_rate_products
        if (priceRateChoices.length > 0) {
          var priceRateItem = priceRateChoices.filter(priceRate => priceRate.price_rate_product_id === String(this.product.price_rate_product))
          if (priceRateItem.length === 1) {
            this.PriceRateItem = priceRateItem[0]
          } else {
            console.log('ERROR: This should not happens.')
          }
        }
      }
    },
    changePriceRate (item) {
      this.PriceRateItem = item
      this.product.price_rate_product = item.price_rate_product_id
      this.$emit('toUpdateProduct', this.product)
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.quantity{
  .Select-bk{
    &.dropdown{
      margin-left: 0;
    }
  }
}
  .item-cn{
    position:relative;
    padding:18px 0 8px;
    display: flex;
    background-color: #ffffff;
    border-bottom:1px solid #979797;
    // align-items: center;

    &.error{
      &:before{
        content:'';
        position: absolute;
        top: 6px;
        bottom: 7px;
        left: 5px;
        right:0;
        pointer-events: none;
        border: 2px solid $red;
        z-index: 11;
        @include box-shadow(bottom, 6px, rgba(#000, .3));
      }
    }

    span.dragdrop{
      display: none;
    }

    &.type-dragdrop{
      cursor: move;

      span.dragdrop{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 0;
        width: 16px;
        padding:0;
        background-color: #FAFBFC;
        z-index: 10;
      }

      &.flip-list-move {
        transition: transform 0.5s;
      }
      &.no-move {
        transition: transform 0s;
      }
      &.ghost {
        opacity: 0.4;
      }
    }

    & > *{
      width: 16%;
      padding: 0 10px 10px;
    }

    p{
      margin:0;
    }

    .big{
      position: relative;
      padding-left: 100px;
      padding-right: 0;
      width: 40%;

      &.no-formato{
        width: 48%;
      }

      p{
        margin: -10px 0 10px;
      }
    }
  }

  .img-cn{
    position:absolute;
    top:-12px;
    bottom:0;
    left:6px;
    width: 80px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;
    border: 1px solid $white;

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }

    & > small{
      position: absolute;
      z-index: 9;
      top:0;
      left:0;
      display: block;
      padding: 4px;
      color: $white;
      width: 57px;
      text-align: center;
      text-transform: uppercase;
      font-size:10px;
    }

    &.type-new{
      border-color: rgba(#8D22A7, .77);

      & > small{
        background-color: #4467C8;
      }
    }

    &.type-eco{
      border-color: #7eb833;

      & > small{
        background-color: #7eb833;
      }
    }

    &.type-outlet{
      border-color: #f0bd4e;

      & > small{
        background-color: #f0bd4e;
      }
    }
  }

  h3{
    color:#1C2C40;
    font-size: 14px;
    text-transform: uppercase;
    margin:12px 0 10px;
    font-weight: $regular;
    // @include ellipsis();
  }



  ul{
    margin:0;
    padding:0;
    list-style: none;
  }

  li{
    font-size: 12px;
    color:#494f58;
    &:last-child{
      // font-size:14px;
      // padding-top: 8px;
    }
  }

  input{
    width:58px;
    border: 1px solid $grey-border;
    height: 32px;
    line-height: 32px;
    text-align: center;
    @include roundAll(4);
  }

  .other{
    &.formato-cn{
      padding: 0;
      .Select-bk.dropdown{
        margin: 48px 0 0;
        width: 100px;
      }
      .dropdown-toggle{
        height: 32px;
        line-height: 32px;
        padding-right: 30px;
        @include roundAll(4);
        border: 1px solid #E1E1E1;

        i{
          line-height: 32px;
        }
      }
    }
    p{
      margin-bottom: 12px;
      line-height: 32px;
    }
  }

  .meta-info{
    display: flex;
    justify-content: space-between;
    // align-items: flex-end;
    color: #494f58;
    padding-left: 15px;
    .col-1{
      width: 25%;
    }
    .col-2{
      width: 28%;
    }
    .col-3{
      width: 24%;
    }
    .col-4{
      width: 23%;
      align-items: flex-end;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
  }

  .stock-cn{
    width: 100%;
  }

  .und{
    margin-top:16px;
    display: inline-block;
    color:#61c844;

    &.normalStock{
      color: $color-text;
    }

    &.noStock{
      color:#ff5466;
    }
    span.bajoPedido span, span.bajoPedido span:first-child{
      color:#FE9213;
    }
    .gray{
      color:#979797;
    }
  }

  .total-cn{
    font-size: 16px;
    font-weight: $medium;
    line-height: 32px;

    span{
      font-size: 16px;
    }

    .pvp{
      padding: 0 6px;
    }
  }

  .facturar-cn{
    min-height: 34px;
    .group{
      @include roundAll(4);
      border: 1px solid $grey-border;
      overflow: hidden;
      width:66px;
      text-align: center;
      margin-left:8px;
      display: inline-block;
      vertical-align: middle;

      small{
        float: left;
        width: 50%;
        color: #d5d8dc;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        font-size: 14px;
        @include transition();

        &.active{
          background-color: $grey-false;
          color: $white;

          &.on{
            background-color: $green-true;
          }
        }
      }

    }
  }

  .btn-remove{
    color: $blue-light;
    font-size: 14px;
    background-color: transparent;
    border:none;
    padding: 0;
    text-align: right;
    line-height: 34px;

    &:hover, &:focus{
      color: $blue;
    }
  }

</style>
