<template lang="pug">
  #EstadisticasCentros
    .Loader-cn(:class="{'show': apiSend}")
      span.Loader-spinner

    .box-1
      .container
        .row
          .col-sm-12
            h2 #[translate Ranking de centros]
        .row
          .col-sm-12
            bk-migas
        .row
          .col-sm-12.text-right
            a.btn.btn-white(v-if="pagination.count" @click="urlDownloadXls('client_center_purchases')")
              img(:src="img.download" width="14")
              translate Descargar xlsx
            filter-ceco
            span.combo-common(v-if="filterCatList.length > 0")
              label #[translate Categoría]:
              .Select-bk.dropdown
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddCat" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterCatItem.name}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddCat")
                  li(v-for="(item, key) in filterCatList" :class="{'disabled': filterCatList == item}")
                    span(:value="item.id" @click="changeFilterCat(item)") {{item.name}}
            date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="month" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

    .container.box-3
      .row(v-if="pagination.count === 0")
        .col-sm-12
          p #[translate No hay datos para la consulta]
      .row(v-if="pagination.count")
        .col-sm-1.text-center
          strong #[translate Nº]
        .col-sm-2
          strong #[translate Centro]
        .col-sm-1.text-right
          strong #[translate CECO]
        .col-sm-2.text-right
          strong #[translate Compras €]
        .col-sm-2.text-right
          strong #[translate Compras € a.a.]
        .col-sm-2.text-right
          strong #[translate € vs a.a. ]
        .col-sm-2.text-right
          strong #[translate % vs a.a. ]
        

    .container.box-4
      .row.row-cn(v-for="(item, index) in oData")
        .col-sm-1.text-center
          p {{positionRanking(index)}}
        .col-sm-2
          p {{item.name}}
        .col-sm-1.text-right
          p {{item.cost_center}}
        .col-sm-2.text-right
          p {{number(item.period_purchases)}} €
        .col-sm-2.text-right
          p {{number(item.previous_period_year_purchases)}} €
        .col-sm-2.text-right
          p( :class="{'positive': item.period_purchases_diff > 0, 'negative': item.period_purchases_diff < 0 }")
            span(v-if="item.period_purchases_diff > 0") +
            span(v-if="item.period_purchases_diff < 0") &nbsp;
            span {{item.period_purchases_diff}} €
        .col-sm-2.text-right
          p( :class="{'positive': item.period_purchases_diff_percentage > 0, 'negative': item.period_purchases_diff_percentage < 0 }")
            span(v-if="item.period_purchases_diff_percentage > 0") +
            span(v-if="item.period_purchases_diff_percentage < 0") &nbsp;
            span {{item.period_purchases_diff_percentage}} %

    .container.pagintation-cn(v-if="pagination.totalPages > 1")
      .row
        .col-sm-12.text-center
          bk-paginate(:page-count="pagination.totalPages"
            :page-range="4"
            :margin-pages="2"
            :click-handler="pagChange"
            :prev-text="pagination.paginationTxt.prev"
            :next-text="pagination.paginationTxt.next"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :initial-page="pagination.currentPage - 1"
            v-show="pagination.totalPages")

</template>
<script>
import appConfig from '@/config-app.js'
import * as types from '@/store/mutation-types'
import BkMigas from '@/components/client/estadisticas/migas'
import DatePicker from 'vue2-datepicker'
import BkPaginate from 'vuejs-paginate'
import commonMixins from '@/mixins/commons.js'
// importamos servicios estadisticas
import SrvEstadisticas from '@/services/estadisticas'
import FilterCeco from '@/components/dashboard/FilterCECO'
export default {
  name: 'EstadisticasCentros',
  components: {BkMigas, DatePicker, BkPaginate, FilterCeco},
  mixins: [commonMixins],
  data () {
    return {
      'apiSend': false, // lo ponemos en true cuando enviemos peticiones a la API para mostrar el preload.
      'oData': null, // Objeto dónde guardamos los datos de la API
      'pagination': {
        'count': null,
        'totalPages': 0,
        'currentPage': 1,
        'pageSize': 20,
        'paginationTxt': {
          'prev': this.$gettext('Anterior'),
          'next': this.$gettext('Siguiente')
        }
      },
      'img': {
        download: require('@/assets/img/icos/ficha-01.svg')
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    this.getClientPurchasesCenter(this.pagination.currentPage)
  },
  computed: {
    client () {
      return this.$store.getters.client
    },
    migasId () {
      // return  ['Total', 'Barcelona', 'ARGENTONA', '40638']
      return this.$store.state.client.clientDashboardOptions.migasId
    },
    calendar () {
      let before = this.$store.state.client.clientDashboardDate.before
      let after = this.$store.state.client.clientDashboardDate.after
      // console.log(`-----> periodo desde ${after} hasta ${before}`)
      return {
        'before': before,
        'after': after,
        'date': [after, before],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    },
    filterCatList () {
      return this.$store.getters.filterCatList
    },
    filterCatItem () {
      let item = this.$store.getters.filterCatItem
      let list = this.$store.getters.filterCatList
      if (item.name === '' && list.length > 0) {
        return list[0]
      }
      return item
    },
    filterCECO () {
      return this.$store.getters.getFilterCECO
    }
  },
  methods: {
    getClientPurchasesCenter (page) {
      this.apiSend = true // mostramos el preload
      // Generamos el menu dle sidebar izquierdo
      SrvEstadisticas.getClientPurchasesCenter(this.migasId, page, this.calendar.before, this.calendar.after, this.filterCatItem.id)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data estadísticas Ranking artículos: ', res.data)
            this.oData = res.data.results
            this.pagination.count = res.data.count
            this.pagination.totalPages = res.data.total_pages
            this.pagination.currentPage = page
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          this.apiSend = false // ocultamos el preload
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        let after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        let before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_DATE, {'after': after, 'before': before})
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
        this.getClientPurchasesCenter(this.pagination.currentPage)
      }
    },
    changeFilterCat (item) {
      // console.log(`id: ${item.id}, name: ${item.name}`)
      this.$store.dispatch('setFilterCatItem', item)
    },
    pagChange (pageNum) {
      // console.log(`página: ${pageNum}`)
      this.getClientPurchasesCenter(pageNum)
      window.scrollTo(0, 0)
    },
    positionRanking (index) {
      // console.log(`index: ${index}, currentPage: ${this.pagination.currentPage}, pageSize: ${this.pagination.pageSize}`)
      let position = (this.pagination.currentPage === 1) ? index + 1 : (index + 1) + (this.pagination.pageSize * (this.pagination.currentPage - 1))
      return position
    },
    number (num) {
      if (Number.isInteger(num)) {
        // sin decimales
        return this.$options.filters.formatNumber(num)
      } else {
        // con decimales
        return this.$options.filters.formatMoney(num)
      }
    }
  },
  watch: {
    migasId () {
      this.getClientPurchasesCenter(this.pagination.currentPage)
    },
    filterCatItem (newItem, oldItem) {
      // console.log(`watch filterCatItem - id: ${newItem.id}`)
      this.getClientPurchasesCenter(this.pagination.currentPage)
    },
    filterCECO () {
      this.getClientPurchasesCenter(this.pagination.currentPage)
    }
  }
}
</script>
<style lang="scss" scoped>
  #EstadisticasCentros{
    position: relative;
    background-color: $white;
    min-height: 60vh;
    padding: 24px 28px 80px;
  }

  .container{
    padding: 0;
  }

  .box-1{
    position: relative;
    padding-bottom: 10px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row{
      display: flex;
      align-items: center;
    }

    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }

    label{
      font-weight: regular;
    }
    .btn-white{
      font-size: 13px;
      height: 34px;
      line-height: 34px;
      border-color: #E2E2E2;
      padding: 0 20px;
      margin-right: 14px;
      color: rgba(100, 100, 100, 0.7);
      img{
        margin-right: 6px;
      }
    }
  }

  .box-3{
    margin-top: 20px;
    padding-bottom: 20px;

    h4{
      font-weight: normal;
      font-size: 16px;

      strong{
        font-weight: bold;
        color: $blue-light;
        display:block;
        font-size: 24px;
        margin:6px 0 30px;
      }
    }
  }

  .box-4{
    .positive{
      color: $green;
    }
    .negative{
      color: $red;
    }

    .row-cn{
      &:nth-child(odd){
        background-color: #F6FAFD;
      }

      & > *{
        &:first-child{
          // color: $blue-light;
          font-weight: $medium;
        }
      }

      p{
        margin: 10px 0;
      }
    }

  }
</style>
