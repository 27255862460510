<template lang="pug">
  .catalog-GridItem
    .container
        .row
          .product-cn(:class="{'col-sm-3': isClient, 'col-sm-5': !isClient}")
            // small(v-if="product.is_eco") #[translate Eco]
            small(v-if="product.is_outlet") #[translate Outlet]
            p.img-cn
              img(:src="checkEmptyImg(product.image_url)")
              tag-soste(v-if="appConfig.featureFlags.infoSoste" :slim="true" :index="product.sustainability_index" :productId="product.id")
              span( data-toggle="modal" data-target="#buyItemPopup" @click="openPopup()") +
              bk-favorite(class="favorite-img" :data-id="product.id")
            h3(@click="goProduct()") {{checkEmpty(product.name)}}
          .col-sm-2.info-cn
            ul
              // li.prov-cn(v-if="product.supplier") #[translate Prov].: {{product.supplier.name}}
              li {{product.external_id}}
              li {{product.quantity_by_packet}} #[translate unidades por envase]
              li {{product.packets_by_package}} #[translate envases por paquete]
          .col-sm-4.text-center.consumos
            p(v-if="center")
              span(v-for="(item, index) in aConsumption" :class="{'type-6': monthlyConsumption == 6 }")
                strong(v-if="index < 12") {{meses[item.month-1]}}
                small {{checkEmpty(item.value)}}
          .col-sm-1.Precio-box.text-right
            div
              p.pvp(v-if="showPrice && product.price !== null" :class="{'special':specialPriceType}" ) {{ price | format-money}} {{product.currency_symbol}}
              bk-cotiza(v-if="product.price === null" :product="product" type="list")
              p.und(v-if="config_app.stock_availability" class="normalStock") to be delisted XXXX
              p.und(v-else-if="config_app.stock && !product.is_network" :class="{'noStock': !product.stock}") 
                span(v-if="config_app.on_demand && product.is_ondemand" class="bajoPedido") #[translate Bajo pedido]
                span.und(v-else) 
                  span(v-if="role !== 'client'") {{product.stock}} #[translate en Stock]
                  span(v-else) #[translate Stock] 
          .col-sm-2.Carro-box.text-center(v-if="isClient")
            .formato(v-if="config_app.formato &&  aFormato.length > 0")
              .dropdown.Select-bk
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddFormato" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{formatoItem.name}} ({{formatoItem.quantity}})
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddFormato")
                  li(v-for="item in aFormato" :class="{'disabled': formatoItem.id == item.id}")
                    span(:value="item.id" @click="changeFormato(item)") {{item.name}}
                      span(v-if="item.id") &nbsp;#[translate de] {{item.quantity}} #[translate uds].

            form.cant(v-if="product.price !== null" @submit.prevent="addCart()")
              input(type="number" placeholder="0" min="0" :max="limitQuantity" v-model="quantity")
              i(class="fa fa-shopping-cart" aria-hidden="true" @click="addCart()")
</template>
<script>
import {mapGetters} from 'vuex'
import BkFavorite from '@/components/common/favorite.vue'
import BkCotiza from '@/components/common/Cotiza.vue'
import commonMixins from '@/mixins/commons.js'
import TagSoste from '@/components/catalog/Tag-Soste.vue'
import appConfig from '@/config-app.js'

export default {
  name: 'CatalogGridItem',
  props: ['product', 'view', 'monthlyConsumption'],
  components: { BkFavorite, BkCotiza, TagSoste },
  mixins: [commonMixins],
  data () {
    return {
      appConfig: appConfig,
      'formatoItem': {'name': '', 'quantity': 1},
      'quantity': 1,
      'meses': [this.$gettext('Ene'), this.$gettext('Feb'), this.$gettext('Mar'), this.$gettext('Abr'), this.$gettext('May'), this.$gettext('Jun'), this.$gettext('Jul'), this.$gettext('Ago'), this.$gettext('Sep'), this.$gettext('Oct'), this.$gettext('Nov'), this.$gettext('Dic')],
      'specialPriceType': false, // Si tiene precio especial
      'center_consumptions': [ // consumo de 24 meses para pruebas
        {'value': 0, 'month': 6},
        {'value': 0, 'month': 7},
        {'value': 0, 'month': 8},
        {'value': 0, 'month': 9},
        {'value': 0, 'month': 10},
        {'value': 0, 'month': 11},
        {'value': 0, 'month': 12},
        {'value': 0, 'month': 1},
        {'value': 0, 'month': 2},
        {'value': 0, 'month': 3},
        {'value': 0, 'month': 4},
        {'value': 0, 'month': 5},
        {'value': 0, 'month': 6},
        {'value': 0, 'month': 7},
        {'value': 0, 'month': 8},
        {'value': 0, 'month': 9},
        {'value': 0, 'month': 10},
        {'value': 0, 'month': 11},
        {'value': 0, 'month': 12},
        {'value': 0, 'month': 1},
        {'value': 0, 'month': 2},
        {'value': 0, 'month': 3},
        {'value': 0, 'month': 4},
        {'value': 0, 'month': 5}
      ]
    }
  },
  created () {
    // console.log('---', this.product)
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    price () {
      let precio = this.product.price
      // Si tiene precio especial lo cogemos y lo indicamos en el front
      if (this.product.special_price !== null) {
        precio = this.product.special_price
        this.specialPriceType = true
        if (this.type_app === 'pedrosa' && this.role === 'client') {
          this.specialPriceType = false
        }
      }
      if (this.formatoItem) {
        precio = precio * this.formatoItem.quantity
      }
      return precio
    },
    total () {
      if (this.formatoItem) {
        return this.price * this.quantity * this.formatoItem.quantity
      }
      return this.price * this.quantity
    },
    center () {
      return this.$store.state.center.center
    },
    isClient () {
      return this.$store.getters.isClient
    },
    isLead () {
      return this.$store.getters.isLead
    },
    hiddenAddCart () {
      if (this.type_app !== 'pedrosa' && this.type_app !== 'maripol') {
        // Ocultamos el botón de añadir el carrito cuando no tengamos stock y obsolete: true
        return (!this.product.stock && this.product.obsolete) === true
      }
      return false
    },
    limitQuantity () {
      // Si obsolete: true ponemos el liminte del stock a quantity
      return this.product.obsolete ? this.product.stock : ''
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    aConsumption () {
      const aConsum = this.product.center_2y_consumptions
      if (this.monthlyConsumption === 6) {
        return aConsum.filter((item, index) => index > 17)
      }
      return aConsum
    },
    aFormato () {
      // formato
      let aFormatos = this.product.aditional_measurement_units
      if (aFormatos && aFormatos.length > 0) {
        const unit = this.product.measurement_unit
        const formato = {'id': '', 'name': unit.name, 'quantity': unit.quantity}
        if (aFormatos && aFormatos.length > 0) {
          aFormatos = [formato, ...aFormatos]
          this.changeFormato(aFormatos[0])
        }
        return aFormatos
      }
      return []
    }
  },
  methods: {
    openPopup () {
      this.$emit('toOpenPopupProduct', [this.product.id])
    },
    addCart () {
      //  console.log(`---id product: ${this.product.id}, quantity: ${this.quantity}`)
      if (this.quantity > 0) {
        let item = {idProduct: this.product.id, quantity: this.quantity, product: this.product}
        if (this.config_app.formato && this.aFormato.length > 0) {
          item.aditional_measurement_unit = this.formatoItem.id
        }
        this.$store.dispatch('addCart', item)
        if (this.config_app.on_demand && this.product.is_ondemand) {
          let cuerpo = this.$gettext('Producto añadido al carro.') + '<br /><br />' + this.$gettext('Este producto se sirve bajo pedido y no admite devolución.')
          this.$emit('showMessage', this.$gettext('Producto bajo pedido'), cuerpo)
        }
      }
    },
    goProduct () {
      // this.$router.push({name: 'catalogProduct', params: {id: this.product.id}})
      // this.$emit('toGoProduct', [this.product.id])
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    changeFormato (item) {
      // console.log(`id: ${item.id}, name: ${item.name}, quantity: ${item.quantity}`)
      this.formatoItem = item
    }
  }
}
</script>
<style lang="scss" scoped>

  .catalog-GridItem{
    padding:10px 0 14px;
    text-align: left;

    & > .container{
      position: relative;

      & > .row{
        display: flex;
        align-items: center;
      }
    }

    &:hover, &:focus{
      // @include box-shadow(all, 5px, rgba($black, 0.1) );
    }
  }

  .product-cn{
    position: initial;
    display: flex;
    align-items: center;
    padding-left: 100px;

    & > small{
      position: absolute;
      z-index: 9;
      top:-2px;
      left:7px;
      display: block;
      padding: 4px;
      color: $white;
      text-transform: uppercase;
      font-size:10px;
    }

    &.type-new{
      .img-cn{
        border-color: rgba(#8D22A7, .77);
      }

      & > small{
        background-color: #4467C8;
      }
    }

    &.type-eco{
      .img-cn{
        border-color: #7eb833;
      }

      & > small{
        background-color: #7eb833;
      }
    }

    &.type-outlet{
      .img-cn{
        border-color: #f0bd4e;
      }

      & > small{
        background-color: #f0bd4e;
      }
    }

  }

  .img-cn{
    position:absolute;
    top:0;
    bottom:0;
    left:6px;
    width: 78px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;
    @include roundAll(4);
    overflow: hidden;
    border: 1px solid #efefef;
    cursor: pointer;

    span{
      position:absolute;
      top:0;
      bottom:0;
      left: 0;
      right:0;
      background-color: rgba($black, .4);
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      @include transition();

      &:before{
        content:'';
        position: absolute;
        top:0;
        bottom:0;
        left: 0;
        right:0;
        width: 20px;
        height: 20px;
        border: 1px solid $white;
        margin: auto;
        @include roundAll100(50%);
        opacity: 0;
        @include transition();
      }

      &:hover, &:focus{
        opacity: 1;

        &:before{
          opacity: 1;
        }
      }
    }

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }
  }

  h3{
    color:#1C2C40;
    font-size: 13px;
    text-transform: uppercase;
    margin:0;
    font-weight: $regular;
    // @include ellipsis();
  }

  ul{
    margin:0;
    padding:0;
    list-style: none;
  }

  li{
    font-size: 12px;
    color:#4D4F5C;
  }

  .consumos{
    text-align: center;
    padding-left:0;
    padding-right: 0;

    p{
      margin:0;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      flex-wrap: wrap;
    }

    span{
      display: inline-block;
      width: 8%;
      padding: 4px 0;

      &.type-6{
        width: 16%;
      }

      &:nth-child(odd){
        // background-color: #F8FBFF;
      }
    }
    strong, small{
      display:block;
      color:#1C2C40;
      font-size:12px;
      font-weight: $regular;
      margin:0;
      height: 20px;
    }
  }

  .formato {
      .Select-bk.dropdown{
        margin: 0 0 6px;
      }

      .dropdown-toggle{
        @include roundAll(4);
        line-height: 32px;
        height: 32px;
        border: 1px solid #E6E8E9;

        i{
          line-height: 32px;
        }

        &:hover, &:focus{
          background-color: #fff;
        }
      }
  }

  .Carro-box{
    .cant{
      width: 122px;
    }
    i{
      border: none;
      width: 65px;
    }
  }

  .Precio-box{
    padding-right: 0;

    .und{
      padding: 2px 8px;
      span.bajoPedido span, span.bajoPedido span:first-child{
          color:#FE9213;
        }
    }
  }



</style>
