<template lang="pug">
  #dashboardVentasEficiencia
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Venta Eficiencia]
        .row
          .col-sm-6
            bk-migas
    .container.box-1
      .row
        .col-sm-6
          h2  #[translate Número de clientes nuevos]
          table
            thead
              tr
                th &nbsp;
                th #[translate Nuevos]
                th #[translate Objetivo]
                th #[translate Perdidos]
            tbody
              tr
                td #[translate Mes anterior]
                td {{aData.num_new_clients | format-number}}
                td {{aData.target_new_clients | format-number}}
                td {{aData.num_lost_clients | format-number}}
              tr
                td #[translate Acumulado año]
                td {{aData.year_num_new_clients | format-number}}
                td {{aData.year_target_new_clients | format-number}}
                td {{aData.year_num_lost_clients | format-number}}
        .col-sm-6
          h2  #[translate Ventas a clientes nuevos]
          table
            thead
              tr
                th &nbsp;
                th #[translate Nuevos]
                th #[translate Objetivo]
            tbody
              tr
                td #[translate Mes anterior]
                td {{aData.sales_new | format-number}} €
                td {{aData.target_new | format-number}} €
              tr
                td #[translate Acumulado año]
                td {{aData.year_sales_new | format-number}} €
                td {{aData.year_target_new | format-number}} €
    .container.box-2
      .row
        .col-sm-6
          h3 #[translate Acumulado año]
          div.chart-bar
            bar-three-chart(:valuesBar="getDataChartLeft")
        .col-sm-6
          h3 #[translate Acumulado año]
          div.chart-bar
            bar-two-chart(:valuesBar="getDataChartRight")
</template>

<script>
import BarTwoChart from '@/components/dashboard/chart/BarTwo'
import BarThreeChart from '@/components/dashboard/chart/BarThree'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardVentasEficiencia',
  components: { BarTwoChart, BarThreeChart, BkMigas },
  data () {
    return {
      idUserDashboard: null, // Id del usuario de que se mostraran los datos.
      aData: {
        'num_new_clients': 0,
        'num_lost_clients': 0,
        'year_num_new_clients': 0,
        'year_num_lost_clients': 0,
        'sales_new': 0,
        'year_sales_new': 0
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    if (this.idUserDashboard) {
      this.geteEfficiency()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    },
    getDataChartLeft () {
      return {
        'label_bar_one': this.$gettext('Nuevos'),
        'num_bar_one': this.aData.year_num_new_clients,
        'color_bar_one': '#7fd04e',
        'label_bar_two': this.$gettext('Objetivos'),
        'num_bar_two': this.aData.year_target_new_clients,
        'color_bar_two': '#005ec8',
        'label_bar_three': this.$gettext('Perdidos'),
        'num_bar_three': this.aData.year_num_lost_clients,
        'color_bar_three': '#f95d5d'
      }
    },
    getDataChartRight () {
      return {
        'label_bar_one': this.$gettext('Nuevos'),
        'num_bar_one': this.aData.year_sales_new,
        'color_bar_one': '#7fd04e',
        'label_bar_two': this.$gettext('Objetivos'),
        'num_bar_two': this.aData.year_target_new,
        'color_bar_two': '#005ec8'
      }
    }
  },
  methods: {
    geteEfficiency () {
      SrvDashboard.geteEfficiency(this.idUserDashboard)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard geteEfficiency: ', res.data)

            // Reset
            this.aData = {'num_new_clients': 0, 'num_lost_clients': 0, 'year_num_new_clients': 0, 'year_num_lost_clients': 0, 'sales_new': 0, 'year_sales_new': 0, 'year_target_new': 0, 'year_target_new_clients': 0}

            this.aData = res.data
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.geteEfficiency()
    }
  }
}
</script>

<style lang="scss" scoped>
.head-box{
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;

  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: -30px;
    right: -30px;
    border-bottom: 1px solid #E8E8E8;
  }

  .row-flex{
    display:flex;
    align-items: center;
  }
  .container{
    padding:0;
  }
  h2{
    margin: 0 0 8px;
    color: #1C2C40;
    font-size: 15px;
    font-weight: $medium;
  }
}

.box-1{
  margin-top: 20px;

  h2{
    text-align: center;
    color: #494f58;
    font-size: 18px;
    font-weight: $medium;
    margin-bottom: 34px;
  }

  table{
    width: 100%;
  }

  thead{
    tr{
      border-bottom: 1px solid #e5e5e5;
    }
    th{
      padding: 8px 10px;
      color: #2c373f;
      font-size: 14px;
      text-align:center;
      font-weight: $medium;
    }
  }

  tbody{
    tr{
      border-bottom: 1px solid #e5e5e5;

      td{
        &:nth-child(2), &:nth-child(3), &:nth-child(4){
          @include opacity(0.6);
        }
      }

      &:first-child{
        td{
          &:nth-child(2), &:nth-child(3), &:nth-child(4){
            @include opacity(1);
          }
        }
      }
    }

    td{
      padding: 8px 10px;

      &:nth-child(1){
        color: #2c373f;
        font-size: 14px;
      }
      &:nth-child(2){
        color: #60a635;
        font-size: 18px;
        font-weight: $bold;
        text-align: center;
      }
      &:nth-child(3){
        color: #005ec8;
        font-size: 18px;
        font-weight: $bold;
        text-align: center;
      }
      &:nth-child(4){
        color: #f95d5d;
        font-size: 18px;
        font-weight: $bold;
        text-align: center;
      }
    }

  }
}

.box-2{
  padding-top: 40px;

  h3{
    text-align: center;
    color: #2c373f;
    font-size: 14px;
    font-weight: $regular;
    margin-top:0;
    margin-bottom: 22px;
  }

  .chart-bar{
    & > div{
      position: relative;
      height: 210px;
    }
  }
}
</style>
