<template lang="pug">
  #dashboardVentasPerdidos
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Venta Clientes perdidos]
        .row
          .col-sm-6
            bk-migas

    // Mostramos Zona listado.
    div(v-if="$route.name == 'dashboardVentasPerdidos'")
      .container.box-1
        .row
          .col-sm-6
            bk-search(@toSearch="toSearch")

      .container.box-2
        .row
          .col-sm-12
            bk-grid(
              :clients="aClient"
              :message="message"
              :pages="totalPages"
              :count="count"
              :currentPage="currentPage"
              @toPaginate="toPagChange"
              @toOrdering="toOrdering")

    // Mostramos el Ranking de centros.
    div(v-if="$route.name !== 'dashboardVentasPerdidos'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>

<script>
import BkSearch from '@/components/dashboard/Search.vue'
import BkGrid from '@/components/dashboard/Grid-ventas-perdidos.vue'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardVentasPerdidos',
  components: {BkSearch, BkGrid, BkMigas},
  data () {
    return {
      'idUserDashboard': null,
      'oSalesSummary': null, // Dónde guardamos los resultados de Srv getSalesSummary
      'query': '', // Donde se guarla la palabra a buscar.
      'ordering': '', // Ordenar por nombre ascendente, los valores de ordering son: 'period_sales', 'year_sales'
      'message': null,
      'aClient': [],
      'count': null,
      'totalPages': 0,
      'currentPage': 1, // Para saber porque página vamos en la paginación
      'aClientStatus': null, // Dónde guardamos los diferentes estados de los clientes para el selector de filtro.
      'clientStatus_name': this.$gettext('Todos'),
      'clientStatus_value': 1, // 1 = todos.
      'setTimeoutToSearch': null
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    if (this.idUserDashboard) {
      this.getSalesLosts()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    }
  },
  methods: {
    getSalesLosts () {
      SrvDashboard.getSalesLosts(this.idUserDashboard, this.query, this.currentPage, this.ordering)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getSalesLosts: ', res.data)
            this.aClient = res.data.results
            this.count = res.data.count
            this.totalPages = res.data.total_pages
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    toSearch (query) {
      // console.log('query:' + query)

      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        self.resetGrid()
        self.query = query
        self.getSalesLosts()
      }, 500)
    },
    toPagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.getSalesLosts()
    },
    toOrdering (value) {
      // console.log('---' + value)
      this.currentPage = 1
      this.ordering = value
      if (this.idUserDashboard) {
        this.getSalesLosts()
      }
    },
    resetGrid () {
      // reseteamos los parámetros del grid, paginación, orden.
      this.currentPage = 1
      this.ordering = ''
      this.query = ''
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.resetGrid()
      this.getSalesLosts()
    }
  }
}
</script>

<style lang="scss" scoped>
  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row-flex{
      display:flex;
      align-items: center;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }

  .box-1{

  }
</style>
