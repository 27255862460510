<template lang="pug">
  .ofertas-list
    // Mostramos Zona listado.
    div(v-if="$route.name !== 'ofertaSingle'")
      .box-2
        .container
          .row
          .col-sm-9
            bk-search(@toSearch="toSearch")
          .col-sm-3.text-right
            button(class="btn btn-action" @click="createOferta()") #[translate Crear nueva Oferta]

      .box-3
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Ofertas]
                small(:class="{'fade-in': itemsNum}")  ({{itemsNum | format-number}} #[translate ofertas])

      bk-grid(
        :items="aItems"
        :message="message"
        :pages="totalPages"
        :count="itemsNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")

    // Mostramos el detalle de la oferta.
    div(v-if="$route.name == 'ofertaSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
import BkSearch from '@/components/ofertas/Search.vue'
import BkGrid from '@/components/ofertas/Grid.vue'

// importamos servicios Ofertas
import SrvOfertas from '@/services/ofertas'

export default {
  name: 'OfertasList',
  components: { BkSearch, BkGrid },
  data () {
    return {
      message: null,
      aItems: [],
      itemsNum: null,
      totalPages: 0,
      currentPage: 0, // Para saber porque página vamos en la paginación
      query: '' // Donde se guarla la palabra a buscar.
    }
  },
  created () {
    // llamamos a la API una vez se crea la instancia.
    this.search('', 1)
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.search(this.query, pageNum)
    },
    toSearch (query) {
      this.query = query
      this.search(this.query, 1)
    },
    search (query, page) {
      // console.log(`---> Search: ${query}`)
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvOfertas.getOfertas(query, page)
        .then(res => {
          if (res.status === 200) {
            // console.log('---> Ofertas: ', res.data.results)
            // console.log('---> Ofertas: ', res.data.results[0])
            this.aItems = res.data.results
            this.itemsNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, error => {
          // console.log('----->' + error)
          let code = error.toString()
          // console.log(code + '-------------' + code.indexOf('403'))
          if (code.indexOf('403')) {
            // console.log('redirect')
            // this.$router.push('/')
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        })
    },
    createOferta () {
      // console.log('crear oferta')
      this.$router.push({'name': 'clientList'})
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-2{
    background-color: #EFEFEF;
    padding: 14px 0;

    .text-right{
      button{
        font-size: 14px;
      }
    }
  }

  .box-3{
    padding: 26px 0 20px;

    .row{
      display: flex;
      align-items: center;
    }

    .info-cn{
      font-size: 16px;
      margin: 0;

      small{
        font-size: 16px;
      }
    }
  }
</style>
