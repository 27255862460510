<template lang="pug">
  #dashboardVentas
    .box-1
      ul.nav
        li
          router-link( :to="{ name: 'dashboardVentasResumen'}" ) #[translate Resumen]
        li
          router-link( :to="{ name: 'dashboardVentasRanking'}" ) #[translate Ranking de Clientes]
        //--li
          router-link( :to="{ name: 'dashboardVentasSinActividad'}" ) #[translate Sin actividad]
        li
          router-link( :to="{ name: 'dashboardVentasEficiencia'}" ) #[translate Eficiencia]
        li
          router-link( :to="{ name: 'dashboardVentasPerdidos'}" ) #[translate Clientes Perdidos]
    .container.box-2
      .row
        .col-sm-12
          transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
            router-view
</template>

<script>
export default {
  name: 'DashboardVentas',
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .box-1{
    .nav{
      margin-right: -14px;
      padding: 0 0 4px 14px;

      li{
        display: inline-block;
        margin-right:20px;
      }

      a{
        display: block;
        color:#494f58;
        font-size: 13px;
        font-weight: $light;
        @include transition();

        &:before{
          content:'';
          position:absolute;
          bottom:0;
          left:0;
          right:0;
          margin:0 auto;
          width:0%;
          height:3px;
          @include transition();
        }

        &:hover, &:focus{
          background-color: transparent;
          color: $black;

          &:before{
            width:100%;
            background-color: rgba($black, 0.2);
          }

        }

        &.router-link-active{
          color: $black;
          font-weight: $regular;

          &:before{
            width:100%;
            background-color: $blue-light;
          }
        }
      }
    }
  }

  .box-2{
    background-color: $white;
    padding: 24px 28px;
    overflow: hidden;
    margin-bottom: 40px;
  }
</style>
