<template lang="pug">
  #dashboardAlbaranesRanking
    .head-box
      .container
        .row
          .col-sm-12
            h2 #[translate Albaranes Ranking de Clientes]
        .row.row-flex
          .col-sm-6
            bk-migas
          .col-sm-6.text-right

    // Mostramos Zona listado.
    div(v-if="$route.name !== 'dashboardAlbaranesRankingCenter'")
      .container.box-1
        .row
          .col-sm-6
            bk-search(@toSearch="toSearch")
          .col-sm-6.text-right
            .dropdown
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Filtrar:] {{clientStatus_name}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(item, key) in aClientStatus" :class="{'disabled': clientStatus_name == item.name}")
                  span(:value="item.value" @click="changeFiltro(item)") {{item.name}}
            .dropdown
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") #[translate Año:] {{yearActive}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddOrder")
                li(v-for="(item) in yearsSelect" :key="item" :class="{'disabled': yearActive == item}")
                  span(:value="item" @click="changeYear(item)") {{item}}
      .container.box-2
        .row
          .col-sm-12
            bk-grid(
              :clients="aClient"
              :year="yearActive"
              :message="message"
              :pages="totalPages"
              :count="count"
              :currentPage="currentPage"
              @toPaginate="toPagChange"
              @toOrdering="toOrdering")

    // Mostramos el Ranking de centros.
    div(v-if="$route.name == 'dashboardAlbaranesRankingCenter'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>

<script>
import BkSearch from '@/components/dashboard/Search.vue'
import BkGrid from '@/components/dashboard/Grid-delivery-ranking.vue'
import BkMigas from '@/components/dashboard/migas'

// Srv
import SrvAlbaranes from '@/services/albaranes'
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardVentasRanking',
  components: { BkSearch, BkGrid, BkMigas },
  data () {
    return {
      'idUserDashboard': null, // Id del usuario de que se mostraran los datos.
      'oSalesSummary': null, // Dónde guardamos los resultados de Srv getSalesSummary
      'yearActive': null, // dónde guardamos el año seleccionado.
      'query': '', // Donde se guarla la palabra a buscar.
      'ordering': '', // Ordenar por nombre ascendente, los valores de ordering son: 'period_sales', 'year_sales'
      'message': null,
      'aClient': [],
      'count': null,
      'totalPages': 0,
      'currentPage': 1, // Para saber porque página vamos en la paginación
      'aClientStatus': null, // Dónde guardamos los diferentes estados de los clientes para el selector de filtro.
      'clientStatus_name': this.$gettext('Todos'),
      'clientStatus_value': 1, // 1 = todos.
      'setTimeoutToSearch': null
    }
  },
  created () {
    this.getClientStatus()
  },
  mounted () {
    if (this.idUserDashboard) {
      this.getDeliveryRanking()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    },
    yearsSelect () {
      let yearInit = 2018
      const currentYear = new Date().getFullYear()
      let years = []
      while (yearInit <= currentYear) {
        years.push(yearInit++)
      }

      this.yearActive = currentYear

      return years
    }
  },
  methods: {
    getDeliveryRanking () {
      if (!this.yearActive) {
        return false
      }
      SrvAlbaranes.getDeliveryRanking(this.idUserDashboard, this.query, this.currentPage, this.clientStatus_value, this.ordering, this.yearActive)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getDeliveryRanking: ', res.data)
            this.aClient = res.data.results
            this.count = res.data.count
            this.totalPages = res.data.total_pages
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getClientStatus () {
      SrvDashboard.getClientStatus()
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getClientStatus: ', res.data)
            res = res.data.filter(item => item.value !== 4)
            this.aClientStatus = res
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeFiltro (item) {
      // console.log(`changeFiltro: ${item.value} - ${item.name}`)
      this.clientStatus_name = item.name
      this.clientStatus_value = item.value
      this.getDeliveryRanking()
    },
    toSearch (query) {
      // console.log('query:' + query)

      window.clearTimeout(this.setTimeoutToSearch)
      var self = this
      this.setTimeoutToSearch = window.setTimeout(function () {
        self.resetGrid()
        self.query = query
        self.getDeliveryRanking()
      }, 500)
    },
    toPagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.getDeliveryRanking()
    },
    toOrdering (value) {
      // console.log('---' + value)
      this.currentPage = 1
      this.ordering = value
      if (this.idUserDashboard) {
        this.getDeliveryRanking()
      }
    },
    resetGrid () {
      // reseteamos los parámetros del grid, paginación, orden.
      this.currentPage = 1
      this.ordering = ''
      this.query = ''
    },
    changeYear (item) {
      // console.log(`year: ${item}`)
      this.yearActive = item
      this.getDeliveryRanking()
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getDeliveryRanking()
    }
  }
}
</script>

<style lang="scss" scoped>

  .head-box{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }
    .container{
      padding:0;
    }
    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }
  }

  .box-1{
    padding: 30px 0 0;
  }

  .box-2{
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }

  .dropdown{
    display:inline-block;
    vertical-align: top;
    min-width: 142px;
    margin-left: 20px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 34px;
      line-height: 34px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;
      color: $blue-light;

      span{
        color: #494949;
      }

      strong{
        color: #68889e;
        font-weight: $regular;
      }

      i.fa-calendar{
        position: relative;
        color: #494949;
        background-color: transparent;
        font-size: 14px;
        width: 16px;
        margin-right: 6px;
      }

      i.fa-angle-down{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }
</style>
