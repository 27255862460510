<template lang="pug">
  .pedidos-cn
    .Loader-cn(:class="{'show': isPdfLoading}")
      span.Loader-spinner
    // Mostramos listado
    div(v-if="$route.name !== 'albaranSingle'")
      .box-2
        .container
          .row
            .col-sm-8
              bk-search(@toSearch="toSearch" :num="ordersNum")

      .box-3
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Albaranes]
                small(:class="{'fade-in': ordersNum}")  ({{ordersNum | format-number}} #[translate albaranes])

      bk-grid(
        :orders="aOrders"
        :message="message"
        :pages="totalPages"
        :count="ordersNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")

    // Mostramos el detalle del pedido.
    div(v-if="$route.name == 'albaranSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
import BkSearch from '@/components/albaran/Search.vue'
import BkGrid from '@/components/albaran/Listado.vue'
import DatePicker from 'vue2-datepicker'
// importamos servicios Catálogo
import SrvOrder from '@/services/albaranes'

export default {
  name: 'AlbaranesList',
  props: [],
  components: { BkSearch, BkGrid, DatePicker },
  data () {
    return {
      isPdfLoading: false,
      aOrders: '',
      ordersNum: null,
      message: '',
      totalPages: 0,
      init: false, // Para comprobar si es la primera vez que llamamos a la API
      currentPage: 0, // Para saber porque página vamos en la paginación
      query: '' // Donde se guarla la palabra a buscar.
    }
  },
  created () {
    this.query = ''
    this.currentPage = 1
    this.search()
  },
  computed: {
    center () {
      return this.$store.state.center.center
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.search()
    },
    toSearch (query) {
      this.query = query
      this.currentPage = 1
      this.search()
    },
    search (query, page) {
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvOrder.getAlbaranesList(this.query, this.currentPage)
        .then(res => {
          if (res.status === 200) {
            this.aOrders = res.data.results
            this.ordersNum = res.data.count
            this.totalPages = res.data.total_pages
            this.currentPage = page
            // console.log(`ordersNum: ${this.ordersNum}`)
            // console.log('order:', res.data.results[0])
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    center () {
      // console.log('cambio center:', this.center)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    }
  }
}
</script>
<style lang="scss" scoped>

.box-2{
  background-color: #EFEFEF;
  padding: 14px 0;

  .text-right{
    button{
      font-size: 14px;
    }
  }
}

.box-3{
  padding: 26px 0 20px;

  .row{
    display: flex;
    align-items: center;
  }

  .info-cn{
    font-size: 16px;
    margin: 0;

    small{
      font-size: 16px;
    }
  }
}

</style>
