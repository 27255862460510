import Vue from 'vue'

const slugable = {}

slugable.install = function (vue) {
  Vue.filter('slugable', (str) => {
    str = str.replace(/^\s+|\s+$/g, '')
    // Make the string lowercase
    str = str.toLowerCase()
    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
    const l = from.length
    for (let i = 0; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
      // Collapse whitespace and replace by -
      .replace(/\s+/g, '-')
      // Collapse dashes
      .replace(/-+/g, '-')

    return str
  })
}

// ej. para utilizarlo:  this.$options.filters.slugable(this.product.name)
export default slugable
