<template lang="pug">
  #CenterItemSimple.container
    .row.box-1(:class="{'par': index%2}")
      div(:class="{'col-sm-12': typeSearch === 2, 'col-sm-6': typeSearch !== 2}" @click="setItem" data-dismiss="modal")
        p {{item.name}}
          br
          | #[translate Identificador]: {{item.external_id}}
      .col-sm-3(v-if="typeSearch !== 2" @click="setItem" data-dismiss="modal")
        p {{item.address}}
      .col-sm-3(v-if="typeSearch !== 2" @click="setItem" data-dismiss="modal")
        p {{item.locality}}

</template>
<script>
// importamos servicios Center
// import SrvCenter from '@/services/item'

export default {
  name: 'CenterListItemSimple',
  props: ['item', 'index', 'typeSearch', 'products'],
  data () {
    return {
    }
  },
  created () {
    // console.log('', this.item)
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    setItem () {
      // Eliminamos el Cliente seleccionado si existe;
      if (this.typeSearch === 2) {
        // Leads
        // Enviamos a Store Lead los datos del Lead
        this.$store.dispatch('setLead', this.item)
        // Lo pasamos a su State
        this.$store.commit('SET_LEAD')
      } else {
        // cliente
        // eliminamos el Cliente seleccionado si existe;
        this.$store.dispatch('removeCenter')
        this.$store.commit('REMOVE_CENTER')
        // Enviamos a Store Client los datos del cliente seleccionado
        this.$store.dispatch('setClient', this.item)
        // Lo pasamos a su State
        this.$store.commit('SET_CLIENT')
        // Redireccionamos al Carro
      }
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>

  .CenterItemSimple{
    color:#494f58;
  }
  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;
    }
  }
  .name, .adress, .loca{
    text-transform: uppercase;
    @include ellipsis();
    cursor: pointer;
    // margin-left:-15px;
  }

  .name{
    margin-left: 34px;
    display:block;
    @include transition();
  }


</style>
