<template lang="pug">
  .ClientListItem
    .container

      .row.box-1
        .div.col-sm-3(data-toggle="collapse"
            :data-target="'#demo-'+index" @click="actionShowDetalle"
          )
          span.img-cn
            img(:src="img.derecha" width="14" class="img-rotate")
          p.name(v-if="catalog.client" :title="catalog.client.name") {{catalog.client.name}}

        .col-sm-3.ellipsis(data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p(v-if="catalog.name")  {{catalog.name}}
        
        .col-sm-1.num-cn(data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p {{catalog.created | moment("DD/MM/YYYY") }}
        
        .col-sm-2.ellipsis( data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p {{catalog.type_name }}
        
        .col-sm-2.ellipsis( data-toggle="collapse" :data-target="'#demo-'+index" @click="actionShowDetalle")
          p {{catalog.items_per_page_name }}


        .col-sm-1.total-cn
          p.text-right
            img(:src="img.verCentro" width="14" @click="setCatalog(catalog.id)")
      .row.box-2(:id="`demo-${index}`" class="collapse")
        .col-sm-12
          bk-grid(:id="catalog.id" :showDetalle="showDetalle")
</template>
<script>
import BkGrid from '@/components/catalogpdf/desplegable/listado-desplegable-grid.vue'

export default {
  name: 'CatalogListItem',
  props: ['catalog', 'index'],
  components: { BkGrid },
  data () {
    return {
      showDetalle: false, // para que el componente BKGrid no haga la petición y traíga el detalle.
      img: {
        derecha: require('@/assets/img/icos/derecha.svg'),
        verCentro: require('@/assets/img/icos/ver-centro.svg')
      }
    }
  },
  created () {
  },
  computed: {
    isClient () {
      return this.$store.getters.isClient
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    openPopup () {
      // this.$emit('toOpenPopupOrderCenter', [this.catalog.center, this.order.client])
    },
    actionShowDetalle () {
      this.showDetalle = true
    },
    setCatalog (idCatalog) {
      // Para ir al detalle de la orden
      // console.log('Order seleccionada: ', this.order.id)
      this.$router.push({name: 'catalogGeneratorSingle', params: {id: idCatalog}})
    }
  }
}
</script>
<style lang="scss" scoped>
  .blue{
    color:#00ADFC;
  }
  .ClientListItem{
    color:#494f58;
  }

  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;

      &.name{
        text-transform: uppercase;
        padding-left: 18px;
        @include ellipsis();
        cursor: pointer;
        // margin-left:-15px;
        @include transition();
      }
    }
  }


  .client-cn{
    @include ellipsis();
  }

  .img-cn{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    height: 22px;
    margin: auto;
    padding-right: 18px;
  }

  .img-rotate{
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 6px;
    height: 16px;
    margin: auto;
    @include transition();
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  [aria-expanded="true"]{
      .img-rotate{
        -moz-transform: rotate(90deg) translate(0px);
        -o-transform: rotate(90deg) translate(0px);
        -ms-transform: rotate(90deg) translate(0px);
        -webkit-transform: rotate(90deg) translate(0px);
        transform: rotate(90deg) translate(0px);
      }
  }

  .date-cn, .num-cn{
    padding-left:0;
    padding-right: 0;
  }

  .date-cn{
    p{
      @include ellipsis();
      display: block;
    }
  }
  .ellipsis{
    p{
      @include ellipsis();
      display: block;
    }
  }

  .num-cn{
    p:empty{
      &:before{
        content: '-';
        margin-right: 60px;
      }
    }
  }

  .total-cn{
    strong{
      font-weight: $medium;
    }
  }

  .status-cn{
    color:$orange;
    &.normal{
      color: $color-text;
    }
    &.SERVIDO, &.S{
      color: $green;
    }
  }

  .box-2{
    padding: 16px 0 50px;
    background-color: #fff;
    border-bottom: 1px solid #F5F6FA;
    color: #4D4F5C;
    font-weight: $light;

    &.in{
      border-top: 1px solid $blue-light;
      border-bottom: 1px solid $blue-light;
    }

    strong{
      font-weight: $regular;
    }
  }

</style>
