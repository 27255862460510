<template lang="pug">
  #catalog-filterTop

    .combo-common.hidden
      label #[translate Sección]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" :class="{'disabled': aCatNivel1.length === 1}" type="button" id="ddCat1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{catNivel1Active.name}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddCat1")
          li(v-for="item in aCatNivel1" :class="{'disabled': catNivel1Active.id == item.id}")
            span(:value="item.id" @click="changeCat(item, 'n1')") {{item.name}}

    .combo-common.hidden
      label #[translate Categorías]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" :class="{'disabled': catNivel1Active.id === 0}" type="button" id="ddCat2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{catNivel2Active.name}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddCat2")
          li(v-for="item in aCatNivel2" :class="{'disabled': catNivel2Active.id == item.id}")
            span(:value="item.id" @click="changeCat(item, 'n2')") {{item.name}}

    .combo-common(v-if="aFilterByType && !isPageCatalogoPublic && numFilterByType > 1")
      label #[translate Ver Productos]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddAutorizado" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterByTypeItem}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddAutorizado")
          li(v-for="(value, key) in aFilterByType" :class="{'disabled': filterByTypeItem == value}")
            span(:value="key" @click="changeFilterByType(key,value)") {{value}}

    .combo-common(v-if="aFilterProductList && !isPageCatalogoPublic && numFilterProductList > 1 && !user.only_authorized_products")
      label #[translate Filtrar]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddAutorizado" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterProductItem}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddAutorizado")
          li(v-for="(value, key) in aFilterProductList" :class="{'disabled': filterProductItem == value}")
            span(:value="key" @click="changeTypeProduct(key,value)") {{value}}

    .combo-common
      label #[translate Ordenar por]:
      .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddOrder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{order}}
          i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddOrder")
          li(v-for="(value, key) in aOrder" :class="{'disabled': order == value}")
            span(:value="value" @click="changeOrderProduct(key,value)") {{value}}
</template>
<script>
import * as types from '@/store/mutation-types'
import SrvCategory from '@/services/category'

export default {
  name: 'CatalogFilterTop',
  props: [],
  data () {
    return {
      aCat: [],
      // aCatNivel1: [],
      catNivel1Active: {'children': [], 'id': 0, 'name': this.$gettext('Todas')},
      // aCatNivel2: [],
      catNivel2Active: {'children': [], 'id': 0, 'name': this.$gettext('Todas')},
      filterProductItem: '',
      numFilterProductList: 0, // todos o autorizados.
      filterByTypeItem: '',
      numFilterByType: 0, // Más consumidos, outlet y eco.
      order: this.$gettext('Alfabéticamente'),
      aOrderElastic: {
        'elastic_relevance': this.$gettext('Relevancia')
      },
      aOrderDefault: {
        // 'name_desc': this.$gettext('Alfabéticamente'),
        'name': this.$gettext('Alfabéticamente'),
        'category': this.$gettext('Orden por familia')
      },
      aOrderConsumo: {
        'consumption_asc': this.$gettext('Consumo: bajo a alto'),
        'consumption_desc': this.$gettext('Consumo: alto a bajo')
      },
      aOrderPrice: {
        'price_asc': this.$gettext('Precio: bajo a alto'),
        'price_desc': this.$gettext('Precio: alto a bajo')
      }
    }
  },
  computed: {
    catalogueOptions () {
      // return this.$store.state.client.catalogueOptions
      return this.$store.getters.catalogueOptions
    },
    center () {
      return this.$store.state.center.center
    },
    client () {
      return this.$store.state.client.client
    },
    user () {
      return this.$store.getters.user
    },
    aOrder () {
      let aOrder = this.aOrderDefault
      if (this.center && !this.isPageCatalogoPublic) {
        aOrder = {...this.aOrderDefault, ...this.aOrderPrice, ...this.aOrderConsumo}
      }

      if (this.config_app.elastic_search && this.$route.name === 'pedidosComprarSearch') {
        aOrder = {...this.aOrderElastic, ...aOrder}
      }

      return aOrder
    },
    aFilterProductList () {
      if (!this.client) {
        return null
      }
      let obj = {}
      if (this.client.has_special_prices) {
        obj.special_price = this.$gettext('Autorizados')
      }

      // console.log('obj', obj)
      if (this.client.only_authorized_products && this.$config.type === 'orbishigiene') {
        obj.add_extra_products = this.$gettext('Todos')
      } else if (this.client.has_special_prices) {
        this.filterProductItem = Object.values(obj)[0]
        obj.all = this.$gettext('Todos')
      }

      this.numFilterProductList = Object.keys(obj).length

      return obj
    },
    aFilterByType () {
      let obj = {}

      obj.all = this.$gettext('Todos')

      if (this.client && this.client.has_usual_products) {
        obj.only_usual_products = this.$gettext('Artículos más consumidos')
      }

      if (this.config_app.is_outlet) {
        obj.is_outlet = this.$gettext('Outlet')
      }

      if (this.config_app.is_eco) {
        obj.is_eco = this.$gettext('Eco')
      }

      if (this.config_app.is_innovative) {
        obj.is_innovative = this.$gettext('Innovaciones')
      }

      if (this.config_app.is_disinfection) {
        obj.is_disinfection = this.$gettext('Desinfección')
      }

      this.filterByTypeItem = Object.values(obj)[0]
      this.numFilterByType = Object.keys(obj).length
      return obj
    },
    aCatNivel1 () {
      return this.aCat
    },
    aCatNivel2 () {
      let aTodas = {'children': [], 'id': 0, 'name': this.$gettext('Todas')}
      // this.aCat = [...catNivel1Active, aTodas]
      // this.aCat = this.sortedArrayById(this.aCat)
      return [aTodas, ...this.catNivel1Active.children]
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isPageCatalogoPublic () {
      if (this.config_app.catalogo_public && (this.$route.name === 'catalogoListPublic' || this.$route.name === 'productSinglePublic')) {
        return true
      }

      return false
    }
  },
  created () {
    this.getCategories()
  },
  mounted () {
    // console.log('mounted')
    this.orderFilterViewProductsState()
  },
  methods: {
    orderFilterViewProductsState () {
      // miramos en la store las opciones de 'ordenar por' y 'ver producto' para aplicarlo o no.
      if (this.$route.query.q) {
        // Si volvemos a la pag de búsqueda.
        this.orderRelevancia()
      } else if (this.catalogueOptions.ordering) {
        // si existe un orden lo aplicamos.
        this.changeOrderProductManual(this.catalogueOptions.ordering)
      } else {
        this.changeOrderProductManual('')
      }

      // todos y autorizados
      if (this.catalogueOptions.filterViewProducts) {
        // console.log('Si existe en la store "ver producto" lo aplicamos.')
        this.changeTypeProductManual(this.catalogueOptions.filterViewProducts)
      } else {
        // console.log('No existe en la store "ver producto" lo aplicamos.')
        if (this.aFilterProductList && Object.keys(this.aFilterProductList).length > 0) {
          this.changeTypeProduct(Object.keys(this.aFilterProductList)[0], Object.values(this.aFilterProductList)[0])
        }
      }

      // mas consumo, outlet y eco.
      // console.log('------filterByType:', this.catalogueOptions.filterByType)
      if (this.catalogueOptions.filterByType) {
        // console.log('Si existe en la store "filterByType" lo aplicamos.')
        this.changeFilterByTypeManual(this.catalogueOptions.filterByType)
      } else {
        // console.log('No existe en la store "filterByType" lo aplicamos.')
        if (this.aFilterByType && Object.keys(this.aFilterByType).length > 0) {
          this.changeFilterByTypeManual(Object.keys(this.aFilterByType)[0], Object.values(this.aFilterByType)[0])
        }
      }
    },
    changeFilterByType (key, value) {
      // console.log(`Filter-top ---> filterByType: k: ${key}, v: ${value}`)
      // Modificamos el ver productos
      this.catalogueOptions.filterByType = key
      // enviamos la actualización a la store.
      this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)

      this.filterByTypeItem = value
      this.$emit('toChangeFilterByType', [key])
    },
    changeFilterByTypeManual (key) {
      let value = null
      if (this.aFilterByType && key) {
        value = this.aFilterByType[key]
      } else if (this.aFilterByType && Object.keys(this.aFilterByType).length > 0) {
        key = Object.keys(this.aFilterByType)[0]
        value = Object.values(this.aFilterByType)[0]
        // console.log(`No key - changeTypeProductManual ---> k: ${key}, v: ${value}, client: `, this.client)
      }

      if (key && value) {
        this.changeFilterByType(key, value)
      }
    },
    changeTypeProduct (key, value) {
      // console.log(`Filter-top ---> changeTypeProduct: k: ${key}, v: ${value}`)
      // Modificamos el ver productos
      this.catalogueOptions.filterViewProducts = key
      // enviamos la actualización a la store.
      this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)

      this.filterProductItem = value
      this.$emit('toChangeFilterProduct', [key])
    },
    changeTypeProductManual (key) {
      let value = null

      if (this.aFilterProductList && key) {
        value = this.aFilterProductList[key]
      } else if (this.aFilterProductList && Object.keys(this.aFilterProductList).length > 0) {
        key = Object.keys(this.aFilterProductList)[0]
        value = Object.values(this.aFilterProductList)[0]
        // console.log(`No key - changeTypeProductManual ---> k: ${key}, v: ${value}, client: `, this.client)
      }

      // console.log(`-------changeTypeProductManual ---> k: ${key}, v: ${value}`)
      if (key && value) {
        this.changeTypeProduct(key, value)
      }
    },
    changeOrderProduct (key, value) {
      // console.log(`changeOrderProduct -> key: ${key}, value: ${value} `)
      // Modificamos el orden
      this.catalogueOptions.ordering = key
      // enviamos la actualización a la store.
      this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)

      this.order = value
      this.$emit('toChangeOrdering', [key])
    },
    changeOrderProductManual (key) {
      let value = null

      if (key) {
        value = this.aOrder[key]
      } else {
        key = 'name'
        value = this.aOrder[key]
      }
      // console.log(`changeOrderProductManual -> key: ${key}, value: ${value} ---`, this.aOrder)
      this.changeOrderProduct(key, value)
    },
    getCategories () {
      SrvCategory.getCategories()
        .then(res => {
          // console.log('-----', res.data)
          if (res.status === 200) {
            const aTodas = {'children': [], 'id': 0, 'name': this.$gettext('Todas')}
            if (res.data.length > 1) {
              this.aCat = [...res.data, aTodas]
              this.aCat = this.sortedArrayById(this.aCat)
            } else {
              this.aCat = res.data
              this.catNivel1Active = this.aCat[0]
            }
            // Enviamos las categorías al padre.
            this.$emit('toGetCat', this.aCat)
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          // console.log('-----', this.aCat)
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCat (item, nivel) {
      // console.log(`category--> id: ${item.id}, name: ${item.name}`)
      if (nivel === 'n1') {
        this.catNivel1Active = item
        this.catNivel2Active = {'children': [], 'id': 0, 'name': this.$gettext('Todas')}
      } else if (nivel === 'n2') {
        this.catNivel2Active = item
      }
      this.$emit('toChangeCat', [item.id])
    },
    sortedArrayById (array) {
      return array.sort((a, b) => a.id - b.id)
    },
    orderConsumoDesc () {
      this.changeOrderProductManual('consumption_desc')
    },
    orderRelevancia () {
      this.changeOrderProductManual('elastic_relevance')
    }
  },
  watch: {
    '$route' (to, from) {
      // react to route changes...
      if (to.name === 'pedidosComprarSearch' && this.$route.query.q) {
        // Si volvemos a la pag de búsqueda.
        this.orderRelevancia()
      } else if (to.name === 'pedidosComprar') {
        // console.log('watch list, aOrder: ', this.aOrder)
        // console.log('watch list, catalogueOptions: ', this.catalogueOptions)
        this.order = Object.values(this.aOrder)[0]
        this.catalogueOptions.ordering = Object.keys(this.aOrder)[0]
        this.$store.commit(types.UPDATE_CATALOGUE_OPTIONS, this.catalogueOptions)
      }
    },
    center () {
      // console.log(`---ordering: ${this.catalogueOptions.ordering}, center:,` + this.center)
      if (!this.center) {
        // Si quitamos el centro, lo ponemos que ordene por el nombre.
        this.changeOrderProductManual('name')
      } else if (this.order !== this.$gettext('Consumo: alto a bajo') && this.center) {
        // si está el centro le decimos qu eordene por consumo.
        this.orderConsumoDesc()
      }
    },
    catalogueOptions () {
      // console.log(`---> filterViewProducts: ${this.catalogueOptions.filterViewProducts}`)
      this.changeTypeProductManual(this.catalogueOptions.filterViewProducts)
      this.changeFilterByTypeManual(this.catalogueOptions.filterByType)
    }
  }
}
</script>
<style lang="scss" scoped>
  #catalog-filterTop{
    text-align: right;

    & > *{
      display:inline-block;
      margin-bottom:0;
      vertical-align: middle;

      &:not(:last-child){
        margin-right: 18px;
      }
    }
  }

  label{
    color: #494f58;
    font-size: 14px;
    font-weight: $regular;
    line-height: 38px;
  }

  .Select-bk.dropdown{
    display:inline-block;
    vertical-align: top;
    min-width: 210px;
    margin-left:6px;

    li, button{
      color: #494f58;
      font-size: 14px;
    }

    .dropdown-toggle{
      height: 38px;
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 50px;

      i{
        width:40px;
        line-height: 36px;
        background-color: transparent;
        color: $blue-light;
      }
    }
  }
</style>
