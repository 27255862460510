<template lang="pug">
  #dashboardOfertas
    .container.box-chartDonut
      .col-sm-6.chart-cn.type-donut
        p.title-cn #[translate Clientes nuevos / Ofertas totales]
          span &nbsp;
        .chart-data
          donut-general(:valuesData="newData")
          small {{newData.value}}%
      .col-sm-6.chart-cn.type-donut
        p.title-cn #[translate Clientes nuevos / Ofertas totales] #[translate Año anterior]
        .chart-data
          donut-general(:valuesData="newPrevData")
          small {{newPrevData.value}}%
</template>

<script>
import DonutGeneral from '@/components/dashboard/chart/DonutGeneral'

// Srv
import SrvDashboard from '@/services/dashboard'

export default {
  name: 'DashboardOfertas',
  components: { DonutGeneral },
  data () {
    return {
      'idUserDashboard': null,
      'newData': {
        'value': 0,
        'color': '#7fd04e'
      },
      'newPrevData': {
        'value': 0,
        'color': '#f95d5d'
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    if (this.idUserDashboard) {
      this.getOffers()
    }
  },
  computed: {
    dashboardStore () {
      let dashboard = this.$store.getters.dashboard
      if (dashboard) {
        this.idUserDashboard = dashboard.id
      }
      return dashboard
    }
  },
  methods: {
    getOffers () {
      SrvDashboard.getOffers(this.idUserDashboard)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard getOffers: ', res.data)

            let data = res.data

            // Reset
            this.newData = {'value': 0, 'color': '#7fd04e'}
            this.newPrevData = {'value': 0, 'color': '#f95d5d'}

            let newColor = (data.year_new_clients_offers_percentage < 0) ? '#f95d5d' : '#7fd04e'
            let newPrevColor = (data.previous_year_new_clients_offers_percentage < 0) ? '#f95d5d' : '#7fd04e'

            this.newData = {'value': data.year_new_clients_offers_percentage, 'color': newColor}
            this.newPrevData = {'value': data.previous_year_new_clients_offers_percentage, 'color': newPrevColor}
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    dashboardStore () {
      // console.log(`dashboard --> name: ${this.dashboardStore.node_name} , id: ${this.dashboardStore.id}`)
      const dashboard = this.$store.getters.dashboard
      this.idUserDashboard = dashboard.id
      this.getOffers()
    }
  }
}
</script>

<style lang="scss" scoped>
  #dashboardOfertas{
    padding-left: 30px;
  }
  .box-chartDonut{
    padding-top: 50px;
  }
  .chart-data{
    position: relative;
    width: 246px;
    height: 246px;
    margin: 15px auto 24px;

    & > div{
      position: relative;
      height: 100%;
    }

    small{
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      width: 100%;
      height: 22px;
      line-height: 22px;
      margin: auto;
      color: #008bb5;
      font-size: 36px;
      font-weight: $medium;
      text-align:center;
    }
  }

  .type-donut{
    text-align: center;
    border-left: 2px solid #e6eaee;
    &:first-child{
      border: none;
    }

    .title-cn{
      color: #7e7f80;
      font-size: 18px;

      span{
        color:#2c373f;
        display: block;
      }
    }

    .leyenda-cn{
      strong{
        display: block;
        font-size: 24px;
        font-weight: $medium;
        color: #7fd04e;
      }

      &.type-menos{
        strong{
          color: #f95d5d;
        }
      }
    }
  }
</style>
