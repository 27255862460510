<template lang="pug">
  .leads-grid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado Leads].

    .holder-cn(v-if="count")
      .container
        .row.grid-head
          .col-sm-4
            p.name #[translate Nombre del lead]
          .col-sm-3.comercial-cn
              translate.text-center Comercial
              .dropdown.Select-bk
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddLeadCommercial" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{comercial.name}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddLeadCommercial")
                  li(v-for="item in aComerciales" :class="{'disabled': comercial.name == item.name}")
                    span( @click="changeComercial(item)") {{item.name}}
          .col-sm-1
            p.text-center.code-cn(@click="changeOrderingCode()") #[translate Códig]. #[i(class="fa" :class="orderingCodeStyle" aria-hidden="true")]
          .col-sm-2
            p.text-center #[translate Núm. de ofertas]
          .col-sm-2
            p.text-center #[translate Crear ofertas]
          .col-sm-2.hidden
            p.text-center #[translate Eliminar lead]

      .grid-cn(v-if="count")
        .container
          bk-item(v-for="(item, index) in items" :key="item.id" :item="item" :index="index" @toSetLead="toSetLead")

      .container.text-center(v-if="pages > 1")
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")

      bk-modal(ref="modalCommon" :title="modal.title" :content="modal.content" :footer="modal.footer")
      p.hidden
        button(data-toggle="modal" data-target="#modal-common" class="btn-modal" ref="showModal")
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/lead/Item.vue'
import BkModal from '@/components/common/Modal.vue'

// importamos servicios
import SrvCommon from '@/services/common'

export default {
  name: 'LeadsGrid',
  props: ['items', 'message', 'pages', 'count', 'currentPage'],
  components: { BkItem, EkPaginate, BkModal },
  data () {
    return {
      'comercial': {
        'email': '',
        'external_id': '',
        'id': '',
        'locked': '',
        'name': this.$gettext('Todos')
      },
      'aComerciales': [],
      'paginationTxt': {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      'orderingCodeStyle': 'fa-sort-asc',
      'orderingCode': '-external_id_int',
      'modal': {
        'title': '',
        'content': '',
        'footer': ''
      }
    }
  },
  computed: {
  },
  created () {
    this.getSalesPeople()
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    },
    getSalesPeople () {
      SrvCommon.getSalesPeople()
        .then(res => {
          // console.log('getSalesPeople: ', res)
          if (res.status === 200) {
            const data = res.data
            this.aComerciales = [this.comercial, ...data.results]
            // console.log(`aTodas: `, this.aComerciales)
          } else {
            // this.messageError = 'Se ha producido un error. Intentelo más tarde.'
            // window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          // this.messageError = 'Se ha producido un error. Intentelo más tarde.'
          // window.scrollTo(0, 0)
        })
    },
    changeComercial (item) {
      // console.log(`change comercial: `, item)
      this.comercial = item
      this.$emit('toChangeComercial', item)
    },
    changeOrderingCode () {
      // console.log(`changeOrderingCode`)
      this.orderingCodeStyle = (this.orderingCodeStyle === 'fa-sort-desc') ? 'fa-sort-asc' : 'fa-sort-desc'
      this.orderingCode = (this.orderingCode === 'external_id_int') ? '-external_id_int' : 'external_id_int'
      this.$emit('toChangeOrderingCode', [this.orderingCode])
    },
    toSetLead (item) {
      // console.log('--------', item)
      const email = item.email
      if (email) {
        // Enviamos a Store Lead los datos del Lead
        this.$store.dispatch('setLead', item)
        // Lo pasamos a su State
        this.$store.commit('SET_LEAD')
        this.$router.push({'name': 'leadOferta'})
      } else {
        this.showModalMsg(this.$gettext('No es posible crear la oferta si el Lead no tiene email.'))
      }
    },
    showModalMsg (msg) {
      const labelBtn = this.$gettext('Aceptar')
      this.modal.content = `<p style="text-align:center;padding-top:20px;">${msg}</p>
                            <p style="text-align:center;padding:20px 0 10px;"><button type="button" data-dismiss="modal" class="btn btn-action">${labelBtn}</button></p>`
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModal.click()
    }

  }
}
</script>
<style lang="scss" scoped>

  .grid-head{
    margin-top: 6px;
    margin-bottom: 6px;
    & > div{
      padding-bottom: 6px;
      border-bottom: 1px solid #1C2C40;
      font-size: 14px;
      line-height: 34px;

      &:first-child{
        font-weight: $medium;
      }
    }

    p{
      margin: 0;
    }
  }

  .grid-cn{
    margin-bottom: 24px;

    & > *{
      padding:0;
    }

    .leads-list-item.container{
      &:nth-child(even){
        background-color: rgba(#F1F4F7, .38);
      }

      &:last-child{
          border-bottom: 1px solid #1C2C40;
      }
    }
  }

  .alert{
    margin-top:40px;
  }

  .comercial-cn{
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;

    & > span{
      display: inline-block;
      width: 80px;
    }
  }

  .Select-bk.dropdown{
    width: 180px;
    margin-bottom: 0;
    margin-top: -10px;

    .dropdown-toggle {
      height: 30px;
      line-height: 30px;

      i{
        line-height: 28px;
      }

    }
  }

  .code-cn{
    cursor: pointer;

    i{
      color: $blue-light;
      margin-left: 8px;
      vertical-align: middle;
    }
  }


</style>
