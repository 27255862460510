<template lang="pug">
  #helpmessage
    .box-1
      .container
        .row
          .col-sm-12
            h1
              img(:src="img.ayuda" width="20")
              translate Soporte técnico e incidencias
    .container.box-3(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}

    .container.box-2(v-if="!message")
      .row
        .col-sm-10.c-c
          h2 #[translate Por favor completa el siguiente formulario y resolveremos tu consulta lo antes posible].
            strong &nbsp; #[translate Los campos marcados (*) son obligatorios].
      .row(v-if="okMsgShow")
        .col-sm-10.c-c
          div.alert.alert-success.fade.in #[translate Consulta enviada].
      .row(v-if="errorMsgShow")
        .col-sm-10.c-c
          div.alert.alert-danger.fade.in #[translate ¡Error! datos incorrectos].
      .row
        .col-sm-8.c-c
          form(@submit.prevent="validate" @keyup.enter="validate")

            .row
              .col-sm-6
                label #[translate Nombre completo]*:
                input.form-control(type="text" v-model="user.full_name" :placeholder="placeholder.nombre" :class="{ 'error':errorNombre }" @focus="resetError")
              .col-sm-6
                label #[translate Empresa]*:
                input.form-control(type="text" v-model="user.company" :placeholder="placeholder.company" :class="{ 'error':errorCompany }" @focus="resetError")

            .row
              .col-sm-5
                label #[translate Correo electrónico]*:
                input.form-control(type="text" v-model="user.email" :placeholder="placeholder.email" :class="{ 'error':errorEmail }" @focus="resetError")
              .col-sm-2
                label #[translate Teléfono]:
                input.form-control(type="text" v-model="user.phone_number" :placeholder="placeholder.phone")
              .col-sm-5
                label #[translate Asunto]*:
                .dropdown.Select-bk
                  button.form-control(class="btn btn-default dropdown-toggle" :class="{ 'error':errorSubject }" type="button" id="ddSubject" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{subject[user.subject].name}}
                    i(class="fa fa-angle-down" aria-hidden="true")
                  ul(class="dropdown-menu" aria-labelledby="ddSubject")
                    li(v-for="item in subject" :class="{'disabled': user.subject == item.id}")
                      span( @click="changeSubject(item)") {{item.name}}

            p
              label #[translate Comentario]*:
              textarea.form-control(v-model="user.description" :class="{ 'error':errorDescription }" @focus="resetError")

            .row.submit-cn
              .col-sm-6.text-right
                button.btn.btn-cancel( type="button" @click="goBack()") #[translate Cancelar]
              .col-sm-6
                button.btn.btn-action #[translate Enviar]
</template>
<script>
// importamos servicios Users
import SrvUsers from '@/services/users'

export default {
  name: 'Helpmessage',
  data () {
    return {
      user: {'email': '', 'subject': 0},
      errorNombre: false,
      errorCompany: false,
      errorEmail: false,
      errorSubject: false,
      errorDescription: false,
      errorMsgShow: false,
      okMsgShow: false,
      errorMsg: false,
      message: false,
      subject: [
        {'id': 0, 'name': this.$gettext('Selecciona')},
        {'id': 1, 'name': this.$gettext('Incidencia técnica')},
        {'id': 2, 'name': this.$gettext('Mi cuenta')},
        {'id': 3, 'name': this.$gettext('Consulta')},
        {'id': 4, 'name': this.$gettext('Solicitud')},
        {'id': 5, 'name': this.$gettext('Sugerencia')},
        {'id': 6, 'name': this.$gettext('Otros')}
      ],
      placeholder: {
        'nombre': this.$gettext('Persona de contacto'),
        'company': this.$gettext('Empresa'),
        'email': this.$gettext('Correo electrónico'),
        'phone': this.$gettext('Teléfono')
      },
      img: {
        ayuda: require('@/assets/img/icos/ayuda.svg')
      }
    }
  },
  created () {
  },
  methods: {
    validate () {
      const nombre = this.user.full_name
      const company = this.user.company
      const email = this.user.email
      const subject = this.user.subject
      const description = this.user.description

      this.resetError()

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === null || email === '' || !email.match(RegEx)) {
        this.errorMsg = true
        this.errorEmail = true
      }

      console.log(`nombre: ${nombre}, company: ${company}, description: ${description}`)

      if (nombre === undefined || nombre === '') {
        this.errorMsg = true
        this.errorNombre = true
      }

      if (company === undefined || company === '') {
        this.errorMsg = true
        this.errorCompany = true
      }

      if (subject === 0) {
        this.errorMsg = true
        this.errorSubject = true
      }

      if (description === undefined || description === '') {
        this.errorMsg = true
        this.errorDescription = true
      }

      if (!this.errorMsg) {
        SrvUsers.sendHelpMessage(this.user)
          .then(res => {
            // console.log('---> respuesta: ', res.data)
            // Mostramos el mensaje de datos enviados
            this.okMsgShow = true
            this.user = {'full_name': '', 'email': '', 'company': '', 'phone': '', 'subject': 0, 'description': ''}
          }, () => {
            // console.log('----->' + error)
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          })
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    changeSubject (item) {
      // console.log(`change provincia: `, item)
      this.user.subject = item.id
      this.resetError()
    },
    resetError () {
      this.errorNombre = false
      this.errorCompany = false
      this.errorEmail = false
      this.errorSubject = false
      this.errorDescription = false

      this.okMsgShow = false
      this.errorMsg = false
      this.errorMsgShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    position: relative;
    margin-top: 40px;
    background-color: $blue-bg-light;

    &:before{
      content:'';
      position: absolute;
      top: -40px;
      left:0;
      width: 100%;
      height: 40px;
      background-color: rgba($grey-bg, .33);
    }
  }
  h1{
    font-weight: $regular;
    font-size:16px;
    padding: 20px 0;
    margin: 0;

    img{
      margin-right: 14px;
      vertical-align: text-bottom;
    }
  }

  h2{
    font-size: 16px;
    font-weight: $regular;
    margin-top: 44px;

    strong{
      color: #979797;
      font-weight: $regular;
    }
  }

  .c-c{
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .box-2{

    .alert{
      margin: 10px 0;
    }
  }

  form{
    margin:20px auto 26px;
    max-width: $width-page;

    .row + .row{
      margin-top: 12px;
    }

    .btn{
      width:100%;
      max-width: 316px;
      font-weight: $regular;
      text-transform: none;
    }
  }

  label{
    display:block;
    color:#3c3e4b;
    font-size:13px;
    margin-bottom:12px;
    font-weight:$regular;
  }

  input[type="text"]{
    height: 40px;
    font-size: 12px;
    font-weight: $light;
  }

  textarea{
    height: 80px;
  }

  .Select-bk.dropdown{
    width: 100%;
    margin-left: 0;

    .dropdown-toggle{
      height: 40px;
      line-height: 40px;
    }
  }

  .submit-cn{
    margin-top:30px!important;
    padding-top:34px;
    border-top:2px solid $grey-bg;

    .btn{
      width:100%;
      max-width: 170px;
    }
  }

  .alert-warning{
    margin-top: 40px;
  }
</style>
