<template lang="pug">
  #dashboardVentaRankingCenterListItem.container
    .row.box-1
      div.col-sm-6
        p.name {{client.name}}
          em {{client.address}}
      .col-sm-3
        p.objetivosData.text-center {{client.period_delivery_notes | format-money}}
      .col-sm-3
        p.text-center
          button.lupa(@click="goOrderCenter(client.valid_id)" v-if="client.valid_id")
            <i class="fa fa-search" aria-hidden="true"></i>
</template>
<script>

// srv
import SrvCenters from '@/services/center'

export default {
  name: 'ClientListItem',
  props: ['client', 'index'],
  methods: {
    goOrderCenter (id) {
      // console.log(`goOrderCenter - center ID: ${id}`)
      SrvCenters.getCenter(id)
        .then(res => {
          if (res.status === 200) {
            // console.log('--->Data Dashboard VentasRankingCenter Item -> Center: ', res.data)
            // Enviamos a Store Center los datos del Centro seleccionado
            this.$store.dispatch('setCenter', res.data)
            // Lo pasamos a su State
            this.$store.commit('SET_CENTER')

            // Nos vamos a pedidos.
            this.$router.push({name: 'albaranesList'})
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    padding:14px 0;
    border-top: 2px solid #d5d8dc;
    border-bottom: 2px solid #d5d8dc;
    margin-bottom: -2px;
    color: #33363a;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  p{
    position: relative;
    margin: 0;

    &.name{
      text-transform: uppercase;
    }

    em{
      display: block;
      font-size: 14px;
      font-style: normal;
    }
    &.diff, .diff{
      color: #71c061;
    }
    &.menos-cn, .menos-cn{
      color: #f95d5d;
    }
  }

  .ventas{
    small{
      display: block;
    }
  }

  button.lupa{
    border: none;
    background-color: $blue-light;
    color: white;
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include roundAll100(50%);
    @include transition();

    &:hover, &:focus{
      background-color: $blue;
    }
  }
</style>
