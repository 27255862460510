<template lang="pug">
  .combo-common.filterCECO(v-if="listCECOS.length > 1")
    label #[translate CECO]:
    .dropdown.Select-bk
        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{ cecoActive }}
            i(class="fa fa-angle-down" aria-hidden="true")
        ul(class="dropdown-menu" aria-labelledby="ddPortes" )
            li(v-for="(item, index)  in listCECOS" :class="{'disabled': cecoActive == item}")
              span(:value="item" @click="changeFilterCECO(item)") {{item}}
</template>
<script>
import SrvEstadisticas from '@/services/estadisticas'
import * as types from '@/store/mutation-types'

export default {
  name: 'FilterCECO',
  data () {
    return {
      'img': {
        'ver': require('@/assets/img/icos/ver_ranking.svg')
      }
    }
  },
  created () {
    if (this.$store.getters.getOptionsCECO.length < 1) {
      this.getCECOS()
    }
  },
  computed: {
    listCECOS () {
      return this.$store.getters.getOptionsCECO
    },
    cecoActive: {
      get () {
        return this.$store.getters.getFilterCECO || 'Todos'
      },
      set (val) {
        this.$store.commit(types.UPDATE_FILTER_CECO, val)
      }
    }
  },
  methods: {
    changeFilterCECO (item) {
      this.cecoActive = item
    },
    getCECOS () {
      SrvEstadisticas.getCecoForClient()
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data estadísticas Ranking artículos: ', res.data)

            this.$store.commit(types.UPDATE_LIST_CECO, [...['Todos'], ...res.data])
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  }
}
</script>
<style lang="scss" scoped>

  p{
    position: relative;
    margin: 0;
  }
  .filterCECO{
    margin-right: 14px;
  }

</style>
