<template lang="pug">
  #dashboardAlbaranesRankingListItem.container
    .row.box-1
      div(:class="{'col-sm-5': type !== 'sinActividad', 'col-sm-12': type === 'sinActividad'}")
        p.name {{client.name}}
      .col-sm-2(v-if="type !== 'sinActividad'")
        p.objetivosData.text-center {{client.period_delivery_notes | format-money}}
      .col-sm-2(v-if="type !== 'sinActividad'")
        p.objetivosData.text-center {{client.period_sales_target | format-money}}
          small(:class="{'menos-cn': client.period_sales_target_percentage < 100 }") {{client.period_sales_target_percentage}}%
      .col-sm-3(v-if="type !== 'sinActividad'")
        p.text-center
          button.lupa(@click="goRankingCenter()")
            <i class="fa fa-search" aria-hidden="true"></i>
</template>
<script>
export default {
  name: 'ClientListItem',
  props: ['client', 'index', 'type', 'year'],
  methods: {
    goRankingCenter () {
      // console.log(`goRakingcenter - client ID: ${this.client.id}`)
      this.$router.push({name: 'dashboardAlbaranesRankingCenter', params: {id: this.client.id, year: this.year}})
    }
  },
  computed: {
    type_app () {
      return this.$config.type
    }
  }
}
</script>
<style lang="scss" scoped>
  .box-1{
    padding:14px 0;
    border-top: 2px solid #d5d8dc;
    border-bottom: 2px solid #d5d8dc;
    margin-bottom: -2px;
    color: #33363a;
    font-size: 16px;
  }

  p{
    position: relative;
    margin: 0;

    small{
      position:absolute;
      bottom: -20px;
      left:0;
      right: 0;
      color: #71c061;
      font-size: 14px;

      &.menos-cn, .menos-cn{
        color: #f95d5d;
      }
    }
  }

  button.lupa{
    border: none;
    background-color: $blue-light;
    color: white;
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include roundAll100(50%);
    @include transition();

    &:hover, &:focus{
      background-color: $blue;
    }
  }
</style>
