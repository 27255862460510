// Importamos mutaciones
import * as types from '@/store/mutation-types'

const dashboard = {
  state: {
    isDashboard: !!window.localStorage.getItem('_dashboard'),
    dashboard: null
  },
  actions: {
    setDashboard ({commit}, data) {
      window.localStorage.setItem('_dashboard', JSON.stringify(data))
      commit(types.SET_DASHBOARD)
      commit(types.DASHBOARD_SUCCESS)
    },
    removeDashboard ({commit}) {
      window.localStorage.removeItem('_dashboard')
      commit(types.REMOVE_DASHBOARD)
    }
  },
  mutations: {
    [types.DASHBOARD_SUCCESS] (state) {
      state.isDashboard = true
    },
    [types.REMOVE_DASHBOARD] (state) {
      state.dashboard = null
      state.isDashboard = false
    },
    [types.SET_DASHBOARD] (state) {
      state.dashboard = JSON.parse(window.localStorage.getItem('_dashboard'))
      // console.log('-->state.dashboard: ' + state.dashboard)
    }
  },
  getters: {
    isDashboard: state => {
      return state.isDashboard
    },
    dashboard: state => {
      return state.dashboard
    }
  }
}

export default dashboard
