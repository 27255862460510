// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getLeads (querySearch, page, filterComercial, orderingCode) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      filterComercial = filterComercial || ''
      orderingCode = orderingCode || ''

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(`/leads/?search=${querySearch}&page=${page}&sales_person=${filterComercial}&ordering=${orderingCode}`)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  createLead (datos) {
    return new Promise((resolve, reject) => {
      const query = `/leads/`

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.post(query, datos)
        .then(res => {
          // console.log('Create Leads: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  }
}
