<template lang="pug">
  .pedidos-cn
    // Mostramos listado
    div(v-if="$route.name !== 'pedidoSingle'")
      .box-2
        .container
          .row
            .col-sm-8
              bk-search(@toSearch="toSearch")
            .col-sm-4
      .box-3
        .container
          .row
            .col-sm-8
              p.info-cn #[translate Pedidos]
                small(:class="{'fade-in': ordersNum}")  ({{ordersNum}} #[translate Pedidos])
            .col-sm-4.text-right
              date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

      bk-grid(
        :orders="aOrders"
        :message="message"
        :pages="totalPages"
        :count="ordersNum"
        :currentPage="currentPage"
        @toPaginate="pagChange")

    // Mostramos el detalle del pedido.
    div(v-if="$route.name == 'pedidoSingle'")
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
</template>
<script>
import appConfig from '@/config-app.js'
import BkSearch from '@/components/order/Search.vue'
import BkGrid from '@/components/order/Listado.vue'
import DatePicker from 'vue2-datepicker'
// importamos servicios Catálogo
import SrvOrder from '@/services/orders'

export default {
  name: 'PedidosList',
  props: [],
  components: {BkSearch, BkGrid, DatePicker},
  data () {
    return {
      aOrders: '',
      ordersNum: null,
      message: '',
      totalPages: 0,
      init: false, // Para comprobar si es la primera vez que llamamos a la API
      currentPage: 0, // Para saber porque página vamos en la paginación
      query: '', // Donde se guarla la palabra a buscar.
      'calendar': {
        'before': this.$moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        'after': this.$moment().add({'years': -1}).format('YYYY-MM-DD[T]HH:mm:ss'),
        'date': [this.$moment().add({'years': -1}).format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY', // 'MMM YYYY'
        'firstDayOfWeek': 1
      }
    }
  },
  created () {
    this.query = ''
    this.currentPage = 1
    this.search()
  },
  computed: {
    country () {
      return this.$store.getters.userInfo.country
    },
    center () {
      return this.$store.getters.center
    },
    client () {
      return this.$store.getters.client
    }
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.currentPage = pageNum
      this.search()
    },
    toSearch (query) {
      this.query = query
      this.currentPage = 1
      this.search()
    },
    search () {
      window.scrollTo(0, 0) // movemos el scroll arriba
      SrvOrder.getOrders(this.query, this.currentPage, this.calendar.before, this.calendar.after)
        .then(res => {
          if (res.status === 200) {
            this.aOrders = res.data.results
            this.ordersNum = res.data.count
            this.totalPages = res.data.total_pages
            // console.log(`ordersNum: ${this.ordersNum}`)
            // console.log('order:', res.data.results[0])
          } else {
            this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        this.calendar.after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        this.calendar.before = this.$moment(aDate[1]).format('YYYY-MM-DD[T]HH:mm:ss')
        // console.log(`${this.calendar.after} - ${this.calendar.before}`)
        this.currentPage = 1
        this.search()
      }
    }
  },
  watch: {
    country () {
      // actualizamos el valor de los pedidos pendientes.
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    center () {
      // console.log('cambio center:', this.center)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    },
    client () {
      // console.log('----cambio cliente:', this.client)
      this.currentPage = 1 // Inicializamos la páginación
      this.search()
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-2{
    padding: 14px 0;
    background-color: #EFEFEF;
  }

  .box-3{
    padding: 26px 0 20px;

    .row{
      display: flex;
      align-items: center;
    }

    .info-cn{
      font-size: 16px;
      margin: 0;

      small{
        font-size: 16px;
      }
    }
  }

</style>
