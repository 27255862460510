<template lang="pug">
  #EstadisticasCliente
    button.hidden(data-toggle="modal" data-target="#modal-common" class="btn-modal" ref="showModalMsg")

    .container.box-2
      .row
        .col-sm-2.sidebar-left
          bk-sidebar-left( :aTree="aTree" )
        .col-sm-10.content-cn
          transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
            router-view

    bk-msg(v-if="!config_app.popup_aviso" title="" :content="templateModalMsg" footer="" ref="modalMsg")
</template>
<script>
import * as types from '@/store/mutation-types'
import BkSidebarLeft from '@/components/client/estadisticas/Sidebar-left.vue'
import BkMsg from '@/components/common/Modal.vue'
// importamos servicios estadisticas
import SrvEstadisticas from '@/services/estadisticas'

export default {
  name: 'EstadisticasCliente',
  components: { BkSidebarLeft, BkMsg },
  data () {
    return {
      aTree: []
    }
  },
  computed: {
    templateModalMsg () {
      const msg = this.$gettext('Para consultar las estadísticas totales o por centros utiliza el menú de la izquierda.')
      const labelBtn = this.$gettext('Aceptar')
      return `<p style="text-align:center;padding-top:20px;">${msg}</p>
              <p style="text-align:center;padding:20px 0 10px;"><button type="button" data-dismiss="modal" class="btn btn-action">${labelBtn}</button></p>`
    },
    client () {
      return this.$store.getters.client
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    this.getNodeTree()
    this.getfilterCatList()
    // asignamos el periodo por defecto para el filtro.
    this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_DATE, {'after': this.$moment().add(-12, 'months').format('YYYY-MM-DD[T]HH:mm:ss'), 'before': this.$moment().add(-1, 'months').format('YYYY-MM-DD[T]HH:mm:ss')})
  },
  mounted () {
    // En el HTML hay un botón con el atributo ref="showModalMsg" y de esta forma le hacemos click para mostrar la modal.
    if (!this.config_app.popup_aviso) {
      setTimeout(() => {
        this.$refs.showModalMsg.click()
      }, 1000)
    }

    if (!this.client) {
      this.$router.push({name: this.config_app.url_home})
    }
  },
  methods: {
    getNodeTree () {
      // Generamos el menu dle sidebar izquierdo
      SrvEstadisticas.getNodeTree()
        .then(res => {
          if (res.status === 200 && res.data.length) {
            this.aTree = res.data
            // console.log(`ID del primer nodo de estadisticas: (${this.aTree[0].id})`)
            // Reset migas, cogemos el primer nodo del menú
            this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_OPTIONS, {'migasId': [this.aTree[0].id], 'migasName': [this.aTree[0].node_name]})
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    getfilterCatList () {
      // Generamos el menu dle sidebar izquierdo
      SrvEstadisticas.getfilterCatList()
        .then(res => {
          if (res.status === 200) {
            const aTemp = res.data[0].children
            const aList = [{'id': '', 'name': this.$gettext('Todas')}]
            aTemp.map((item, index) => {
              // console.log(`id: ${item.id}, name: ${item.name}`)
              aList.push({'id': item.id, 'name': item.name})
            })
            this.$store.dispatch('setFilterCatList', aList)
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    client () {
      // actualizamos el valor de los pedidos pendientes.
      // console.log('client change:', this.client)
      if (!this.client) {
        this.$router.push({name: this.config_app.url_home})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  #EstadisticasCliente{
    position: relative;
    border-top: 4px solid #F1F1F1;
  }

  .box-2 {
    a{
      color: $black;
    }

    .nav{
      width: 100%;
      background-color: $white;
      border-bottom: 1px solid #eff3f6;
      -moz-box-shadow: 0 15px 15px -15px rgba(74, 74, 74, 0.1);
      -webkit-box-shadow: 0 15px 15px -15px rgba(74, 74, 74, 0.1);
      box-shadow: 0 15px 15px -15px rgba(74, 74, 74, 0.1);

      li{
        display: inline-block;
        padding: 20px 14px 10px;
        font-size: 17px;
      }

      a{
        position: relative;

        &:before{
          content:'';
          position:absolute;
          bottom:-9px;
          left:0;
          right:0;
          margin:0 auto;
          width:0%;
          height:4px;
          @include transition();
        }

        &:hover, &:focus{
          background-color: transparent;

          &:before{
            width:100%;
            background-color: rgba($black, 0.2);
          }
        }

        &.router-link-active{
          font-weight: $medium;
          &:before{
            width:100%;
            background-color: $blue-light;
          }
        }
      }
    }

    .sidebar-left{
      // border-right: 1px solid #eff3f6;
      // background-color: #ffffff;
      height: 1000px;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 1px solid #eff3f6;
      padding-right: 0;
    }
    .content-cn{
      padding: 24px;
      background-color: #FAFAFA;
      min-height: 1000px;
    }
  }
</style>
