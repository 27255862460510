
// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
// axios.defaults.headers.common['accept-language'] = 'es'
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'

export default {
  getProducts (querySearch, page, category, complete, ordering, onlyUsualProducts, isOutlet, isEco, isCovid, isDisinfection, isInnovative, addExtraProducts) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      let suppliers = Stores.getters.catalogueSupplier
      suppliers = suppliers || ''

      ordering = ordering.toString()
      // console.log(`querySearch: ${querySearch}, ordering: ${ordering}`)
      if (querySearch === '' && ordering === 'elastic_relevance') {
        ordering = '-has_image,name'
      } else if (querySearch === '' && ordering !== 'elastic_relevance') {
        ordering = (ordering === '' || ordering === 'name') ? '-has_image,name' : `-has_image,${ordering},name`
      }

      let countryTmp = Stores.getters.userInfo.country
      let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''
      const consumption = 'false' // para que en la respuesta de la API no venga el consumo.
      addExtraProducts = (addExtraProducts === '') ? 'True' : addExtraProducts // Si viene vacío le ponemos a "True" para que muestre los productos por defecto.

      // console.log(`id client: ${idClient}, id center: ${idCenter}, complete: ${complete}, ordering: ${ordering}`)
      const query = `/products/?country=${country}&search=${querySearch}&page=${page}&supplier=${suppliers}&category=${category}&client=${idClient}&center=${idCenter}&complete=${complete}&only_usual_products=${onlyUsualProducts}&ordering=${ordering}&consumption=${consumption}&is_outlet=${isOutlet}&is_eco=${isEco}&is_covid=${isCovid}&is_disinfection=${isDisinfection}&is_innovative=${isInnovative}&add_extra_products=${addExtraProducts}`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      if (Stores.getters.getCancelAxios) {
        Stores.getters.getCancelAxios.cancel('Start new search, stop active search')
      }
      Stores.commit(types.CANCEL_AXIOS, axios.CancelToken.source())
      
      axios.get(query, {
        cancelToken: Stores.getters.getCancelAxios.token })
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          Stores.commit(types.CANCEL_AXIOS, '')
          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('--', error.response.config)
          const status = error && error.response ? error.response.status : null
          // console.log('Status: ' + status + ', error: ' + error)
          if (status === 401) {
            SrvCommon.logout()
          }
          if (!axios.isCancel(error)) {
            reject(error)
          }
        })
    })
  },
  getProductsBuy (querySearch, page, complete, ordering, onlyUsualProducts, category, isOutlet, isEco, isDisinfection, isInnovative, addExtraProducts) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      ordering = ordering.toString()
      // console.log(`querySearch: ${querySearch}, ordering: ${ordering}`)
      if (querySearch === '' && ordering === 'elastic_relevance') {
        ordering = '-has_image,name'
      } else if (querySearch === '' && ordering !== 'elastic_relevance') {
        ordering = (ordering === '' || ordering === 'name') ? '-has_image,name' : `-has_image,${ordering},name`
      }

      let countryTmp = Stores.getters.userInfo.country
      let country = (countryTmp && countryTmp.id !== 'eu') ? countryTmp.id : ''
      let consumption = (oCenter) ? 'True' : 'false' // para que en la respuesta de la API no venga el consumo.
      addExtraProducts = (addExtraProducts === '') ? 'True' : addExtraProducts // Si viene vacío le ponemos a "True" para que muestre los productos por defecto.

      // console.log(`id client: ${idClient}, id center: ${idCenter}, complete: ${complete}, ordering: ${ordering}, category: ${category}`)
      const query = `/products/?country=${country}&search=${querySearch}&page=${page}&category=${category}&client=${idClient}&center=${idCenter}&complete=${complete}&only_usual_products=${onlyUsualProducts}&ordering=${ordering}&consumption=${consumption}&is_outlet=${isOutlet}&is_eco=${isEco}&is_disinfection=${isDisinfection}&is_innovative=${isInnovative}&add_extra_products=${addExtraProducts}&page_size=20`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API de clientas
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('--', error.response.config)
          const status = error.response.status
          // console.log('Status: ' + status + ', error: ' + error)
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getProductsPublic (querySearch, page, category, complete, ordering, onlyUsualProducts, isOutlet, isEco, isCovid, isDisinfection, isInnovative, addExtraProducts) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      page = (page === undefined) ? 1 : page

      ordering = ordering.toString()
      // console.log(`querySearch: ${querySearch}, ordering: ${ordering}`)
      if (querySearch === '' && ordering === 'elastic_relevance') {
        ordering = '-has_image,name'
      } else if (querySearch === '' && ordering !== 'elastic_relevance') {
        ordering = (ordering === '' || ordering === 'name') ? '-has_image,name' : `-has_image,${ordering},name`
      }

      const consumption = 'false'
      addExtraProducts = (addExtraProducts === '') ? 'True' : addExtraProducts // Si viene vacío le ponemos a "True" para que muestre los productos por defecto.

      // console.log(`id client: ${idClient}, id center: ${idCenter}, complete: ${complete}, ordering: ${ordering}`)

      const query = `/public_products/?search=${querySearch}&page=${page}&category=${category}&complete=${complete}&only_usual_products=${onlyUsualProducts}&ordering=${ordering}&consumption=${consumption}&is_outlet=${isOutlet}&is_eco=${isEco}&is_covid=${isCovid}&is_disinfection=${isDisinfection}&is_innovative=${isInnovative}&add_extra_products=${addExtraProducts}`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API de clientas
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          // console.log('--', error.response.config)
          const status = error.response.status
          // console.log('Status: ' + status + ', error: ' + error)
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getProduct (id, type) {
    return new Promise((resolve, reject) => {
      // mostramos Loading

      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      Stores.commit(types.PENDING)

      const consumption = (type !== 'order') ? 'True' : 'false'

      let query = `/products/${id}/?client=${idClient}&center=${idCenter}&consumption=${consumption}`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getProductPublic (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      const consumption = 'false'

      let query = `/public_products/${id}/?consumption=${consumption}`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          /* const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          } */
          reject(error)
        })
    })
  },
  removeProduct (id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      const query = `/cart/remove_cart_item/${id}/`
      // console.log(`Query busqueda producto: ${query}`)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.delete(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  // Actualizar carrito
  updateProduct (product) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      const query = `/cart/update_cart_item/${product.id}/`
      // console.log(`Query busqueda producto: ${query}`)

      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const datos = {
        'client': idClient,
        'description': product.description,
        'quantity': product.quantity,
        'discount': product.discount,
        'billable': product.billable,
        'price_rate_product': product.price_rate_product
      }

      if (product.aditional_measurement_unit_tmp) {
        const id = product.aditional_measurement_unit_tmp.id
        datos.aditional_measurement_unit = id
      } else {
        datos.aditional_measurement_unit = product.aditional_measurement_unit
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }

          var errorResponse = []
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log('error data: ', error.response.data)
            // console.log('error status: ', error.response.status)
            // console.log('error headers: ', error.response.headers)
            errorResponse['status'] = error.response.status
            errorResponse['data'] = error.response.data
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log('error request: ', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
            errorResponse['status'] = 400
            errorResponse['data'] = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          // console.log(error.config)

          // console.log('+++' + error)
          reject(errorResponse)
        })
    })
  },
  updateCart (products) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      const query = `/cart/update/`
      // console.log(`Query busqueda producto: ${query}`)

      const cart = Stores.getters.cart
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''
      let items = []

      products.map(item => {
        items.push({'product': item.product_data.id, 'quantity': item.quantity, 'price': item.price, 'billable': item.billable, 'discount': item.discount, 'description': item.description, 'aditional_measurement_unit': item.aditional_measurement_unit_data ? item.aditional_measurement_unit_data.id : null})
        // ORIGINAL items.push({'product': item.product_data.id, 'quantity': item.quantity, 'price': item.price, 'billable': item.billable, 'discount': item.discount, 'description': item.description, 'aditional_measurement_unit_data': item.aditional_measurement_unit_data})
      })

      const datos = {
        'observations': cart.observations,
        'client_discount_percentage': cart.client_discount_percentage,
        'client_discount': cart.client_discount,
        'items': items,
        'client': idClient,
        'center': null,
        'lead': null,
        'currency_code': cart.currency_code,
        'currency_symbol': cart.moneda
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.put(query, datos)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)

          // console.log('Store res: ', res)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }

          var errorResponse = []
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log('error data: ', error.response.data)
            // console.log('error status: ', error.response.status)
            // console.log('error headers: ', error.response.headers)
            errorResponse['status'] = error.response.status
            errorResponse['data'] = error.response.data
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log('error request: ', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
            errorResponse['status'] = 400
            errorResponse['data'] = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          // console.log(error.config)

          // console.log('+++' + error)
          reject(errorResponse)
        })
    })
  },
  getCart () {
    return new Promise((resolve, reject) => {
      const query = `/cart/`

      // llamada a la API
      const domain = window.location.hostname
      if (domain !== 'localhost') {
        axios.defaults.headers.common['Cache-Control'] = 'no-cache'
        axios.defaults.headers.common['Pragma'] = 'no-cache'
        axios.defaults.headers.common['Expires'] = '0'
      }
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(query)
        .then(res => {
          // console.log('Catalog cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setCart (datos) {
    return new Promise((resolve, reject) => {
      const query = `/cart/add_cart_item/`

      // console.log('SRV setcar ->', datos)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.post(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  deleteCart () {
    return new Promise((resolve, reject) => {
      const query = `/cart/clear/`

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSuppliers (search, cat, complete, onlyUsualProducts, isOutlet, isEco, addExtraProducts) {
    return new Promise((resolve, reject) => {
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''
      addExtraProducts = (addExtraProducts === '') ? 'True' : addExtraProducts // Si viene vacío le ponemos a "True" para que muestre los productos por defecto.
      const query = `/suppliers/?search=${search}&category=${cat}&client=${idClient}&center=${idCenter}&complete=${complete}&only_usual_products=${onlyUsualProducts}&is_outlet=${isOutlet}&is_eco=${isEco}&add_extra_products=${addExtraProducts}&page_size=1000`
      // console.log(`Query (getSuppliers) --------> ${query}`)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.get(query)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setOrder (observations, customOrder = null) {
    return new Promise((resolve, reject) => {
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      const query = `/cart/create_order/`

      const datos = {
        'client': idClient,
        'center': idCenter,
        'observations': observations
      }

      if (customOrder && customOrder !== '') {
        datos.customer_number = customOrder
      }

      var buyerCookie = window.localStorage.getItem('_buyerCookie')
      if (buyerCookie) {
        datos.cookies = document.cookie
        datos.buyer_cookie = buyerCookie
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          // console.log(`setOrder error: ${error.response.status}, error response:`, error.response.data)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  },
  setPunchoutPreOrder (observations) {
    return new Promise((resolve, reject) => {
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      const query = `/cart/create_punchout_order/`

      const datos = {
        'client': idClient,
        'center': idCenter,
        'observations': observations,
        'buyer_cookie': window.localStorage.getItem('_buyerCookie')
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          // console.log(`setOrder error: ${error.response.status}, error response:`, error.response.data)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  },
  setOffers (observations, printFormatsId) {
    return new Promise((resolve, reject) => {
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''

      const oLead = Stores.state.lead.lead
      const idLead = (oLead) ? oLead.id : ''

      const query = `/cart/create_offer/`

      const datos = {
        'client': idClient,
        'center': idCenter,
        'lead': idLead,
        'observations': observations,
        'print_format': printFormatsId
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, datos)
        .then(res => {
          // console.log('setOffers API: ', res)
          resolve(res)
        })
        .catch(error => {
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  },
  setCatalogPDF (data) {
    return new Promise((resolve, reject) => {
      const oClient = Stores.getters.client
      const idClient = (oClient) ? oClient.id : ''

      const oCenter = Stores.getters.center
      const idCenter = (oCenter) ? oCenter.id : ''
      const query = `/cart/create_catalog/`

      data.append('client', idClient)
      data.append('center', idCenter)

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'JWT ' + window.localStorage.getItem('_token')
      axios.patch(query, data)
        .then(res => {
          // console.log('Catalog add cart: ', res)
          resolve(res)
        })
        .catch(error => {
          // console.log(`setOrder error: ${error.response.status}, error response:`, error.response.data)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error.response)
        })
    })
  }
}
