import {mapGetters} from 'vuex'
import SrvEstadisticas from '@/services/estadisticas'

export default {
  computed: {
    showPrice () {
      if (this.config_app.roles_apply_hide_price.includes(this.role) && !this.user.can_see_prices) {
        return false
      }
      return true
    },
    showTotals () {
      if (this.config_app.roles_apply_hide_totals.includes(this.role) && !this.user.can_see_totals) {
        return false
      }
      return true
    },
    user () {
      return this.$store.getters.user
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      return this.$config.configApp
    },
    ...mapGetters([
      'role'
    ])
  },
  methods: {
    urlDownloadXls (name) {
      const migas = this.migasId
      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }
      var url = `/c_dashboard/${name}.xlsx/?client=${this.client.id}&before=${this.calendar.before}&after=${this.calendar.after}&${param}`
      if (this.pagination && this.pagination.currentPage) {
        url += `&page=${this.pagination.currentPage}`
      }

      if (this.filterCatItem && this.filterCatItem.id) {
        url += `&category=${this.filterCatItem.id}`
      }
      if (this.$store.getters.getFilterCECO) {
        url += `&cost_center=${this.$store.getters.getFilterCECO}`
      }
      this.apiSend = true // mostramos el preload
      var nameSlice = this.client.name.slice(0, 10)
      var nameForXlsx = nameSlice.replaceAll('.', '_')
      var dateBefore = this.$moment(this.calendar.before).format('YYYY-MM-DD')
      var dateAfter = this.$moment(this.calendar.after).format('YYYY-MM-DD')
      var filename = this.client.external_id + '-' + nameForXlsx + '_' + dateAfter + '_' + dateBefore + '.xlsx'
      SrvEstadisticas.getXlsx(url)
        .then(res => {
          if (res.status === 200) {
            var url = window.URL.createObjectURL(res.data)
            var a = document.createElement('a')
            a.href = url
            a.download = filename
            a.click()
            a.remove()
            setTimeout(() => window.URL.revokeObjectURL(url), 100)
          }
          this.apiSend = false
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  }
}
