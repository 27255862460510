<template lang="pug">
  #dashboardAlbaranesRankingListGrid
    .container(v-if="message")
      .row
        .col-sm-12
          div(class="alert alert-warning" role="alert") {{message}}
    .container(v-if="count == 0")
      .row
        .col-sm-12
          div(class="alert alert-info" role="alert") #[translate No se han encontrado clientes].
    .holder-cn(v-if="count")
      .container
        .row.box-1
          div(:class="{'col-sm-5': type !== 'sinActividad', 'col-sm-12': type === 'sinActividad'}")
            p.name(@click="changeOrdering('client')") #[translate Cliente] #[i(class="fa" :class="clientOrderingStyle", aria-hidden="true")]
          .col-sm-2(v-if="type !== 'sinActividad'")
            p.text-center.padding-right(@click="changeOrdering('periodSales')") #[translate Albaranes mes] #[i(class="fa" :class="periodSalesOrderingStyle", aria-hidden="true")]
          .col-sm-2(v-if="type !== 'sinActividad'")
            p.text-center.padding-right(@click="changeOrdering('target')") #[translate Objetivo mes] #[i(class="fa" :class="periodSalesOrderingStyle", aria-hidden="true")]
          .col-sm-3(v-if="type !== 'sinActividad'")
            p.text-center #[translate Ver Ranking de Centros]

      bk-item(v-for="(client, index) in clients" :key="client.id" :client="client" :index="index" :type="type" :year="year")
      .container.text-center
        ek-paginate(:page-count="pages"
          :page-range="4"
          :margin-pages="2"
          :click-handler="pagChange"
          :prev-text="paginationTxt.prev"
          :next-text="paginationTxt.next"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :initial-page="currentPage-1"
          v-show="pages")
</template>
<script>
import EkPaginate from 'vuejs-paginate'
import BkItem from '@/components/dashboard/Item-delivery-ranking.vue'

export default {
  name: 'DashboardVentasRankingListGrid',
  props: ['clients', 'message', 'pages', 'count', 'currentPage', 'type', 'year'],
  data () {
    return {
      'paginationTxt': {
        'prev': this.$gettext('Anterior'),
        'next': this.$gettext('Siguiente')
      },
      'aOrderingFull': [], // dónde guardaremos el string con todas las ordenaciones. ordenación.
      'orderingCurrent': null, // donde guardamos el valor único de ordenación.
      'clientOrdering': '',
      'clientOrderingStyle': 'fa-sort',
      'periodSalesOrdering': '',
      'periodSalesOrderingStyle': 'fa-sort',
      'targetOrdering': '',
      'targetOrderingStyle': 'fa-sort',
      'salesOrdering': '',
      'salesOrderingStyle': 'fa-sort'
    }
  },
  components: { BkItem, EkPaginate },
  computed: {
    type_app () {
      return this.$config.type
    }
  },
  created () {
    this.changeOrdering('periodSales') // -period_sales
  },
  methods: {
    pagChange (pageNum) {
      // console.log(pageNum)
      this.$emit('toPaginate', [pageNum])
    },
    changeOrdering (type) {
      // reset estilos de flechas de ordenación para un único criterio de ordenación
      this.clientOrderingStyle = 'fa-sort'
      this.periodSalesOrderingStyle = 'fa-sort'
      this.targetOrderingStyle = 'fa-sort'
      this.salesOrderingStyle = 'fa-sort'

      switch (type) {
        case 'client':
          this.clientOrdering = (this.clientOrdering === '-name') ? 'name' : '-name'
          this.clientOrderingStyle = (this.clientOrdering === '-name') ? 'fa-sort-asc' : 'fa-sort-desc'
          this.aOrderingFull[0] = this.clientOrdering
          this.orderingCurrent = this.clientOrdering
          break
        case 'periodSales':
          this.periodSalesOrdering = (this.periodSalesOrdering === '-period_delivery_notes') ? 'period_delivery_notes' : '-period_delivery_notes'
          this.periodSalesOrderingStyle = (this.periodSalesOrdering === '-period_delivery_notes') ? 'fa-sort-asc' : 'fa-sort-desc'
          this.aOrderingFull[1] = this.periodSalesOrdering
          this.orderingCurrent = this.periodSalesOrdering
          break
        case 'target':
          this.targetOrdering = (this.targetOrdering === '-period_sales_target') ? 'period_sales_target' : '-period_sales_target'
          this.targetOrderingStyle = (this.targetOrdering === '-period_sales_target') ? 'fa-sort-asc' : 'fa-sort-desc'
          this.aOrderingFull[2] = this.targetOrdering
          this.orderingCurrent = this.targetOrdering
          break
        case 'sales':
          this.salesOrdering = (this.salesOrdering === '-previous_period_year_delivery_notes') ? 'previous_period_year_delivery_notes' : '-previous_period_year_delivery_notes'
          this.salesOrderingStyle = (this.salesOrdering === '-previous_period_year_delivery_notes') ? 'fa-sort-asc' : 'fa-sort-desc'
          this.aOrderingFull[3] = this.salesOrdering
          this.orderingCurrent = this.salesOrdering
          break
        default:
      }

      /* envia todos los criterios de ordenación
      // console.log(`aOrderingFull: `, this.aOrderingFull)
      let aTmp = this.aOrderingFull.filter(v => v !== '')
      // console.log(`aOrderingFull limpio: `, aTmp)
      // console.log(`ordenacion: ${aTmp.toString()}`)
      this.$emit('toOrdering', aTmp.toString())
      */

      // envia un solo criterio de ordenación
      this.$emit('toOrdering', this.orderingCurrent)
    }
  },
  watch: {
    currentPage (num) {
      // Si la pagina es uno reseteamos el ordenaci
      if (num === 1) {
        console.log('reset ordering')
        this.aOrderingFull = []
        this.clientOrdering = ''
        this.clientOrderingStyle = 'fa-sort'
        this.periodSalesOrdering = ''
        this.periodSalesOrderingStyle = 'fa-sort'
        this.targetOrdering = ''
        this.targetOrderingStyle = 'fa-sort'
        this.salesOrdering = ''
        this.salesOrderingStyle = 'fa-sort'
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  .box-1{
    padding-top:24px;
    font-size:14px;
    color:#494f58;
    font-weight:$light;

    [class*='col-'] {
      padding-left: 5px;
      padding-right: 5px;
    }

    p{
      position: relative;
      cursor: pointer;

      &.padding-right{
        padding-right: 12px;
      }

      &.name{
        i{
          position: relative;
          margin-left: 8px;
        }
      }

      i{
          position: absolute;
          top:4px;
          right: 0;
          color: $blue-light;
      }

      small{
        display:block;
        font-size: 12px;
      }
    }
  }

  .holder-cn{
    margin-top: 30px;
  }

  .name{
    padding-left:24px;
  }

  .alert{
    margin-top:40px;
  }

</style>
