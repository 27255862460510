<template lang="pug">
  .cotiza-common
    button.btn(v-if="type == 'list'" @click="openModalCotiza()" data-toggle="modal" :data-target="`#modalCotiza-${product.id}`" :id="`cotiza-btn-${product.id}`")
        translate Pedir presupuesto
        em €
        
    div(v-if="type == 'single'" class="cotiza-single")
      p
        img(:src="img.cotiza_single" width="42" alt="" class="img-cn")
      p #[translate ¿Quieres solicitar un presupuesto de este producto?]
      p
        button.btn(@click="openModalCotiza()" data-toggle="modal" data-target="#modalCotizaSingle" data-dismiss="modal")
          translate Pedir presupuesto
    
    bk-modal-cotiza(ref="modalCotiza" classOpen="modalCotiza" :product="product" :type="type" :provinces="provinces")

</template>

<script>
import BkModalCotiza from '@/components/common/Modal-cotiza.vue'

export default {
  name: 'CotizaCommon',
  props: ['product', 'type', 'provinces'],
  components: { BkModalCotiza },
  data () {
    return {
      'tooltip': {
        'msg': this.$gettext('Pedir presupuesto'),
        'position': 'hint-bottom-right'
      },
      'img': {
        'cotiza': require('@/assets/img/icos/cotiza.svg'),
        'cotiza_hover': require('@/assets/img/icos/cotiza_hover.svg'),
        'cotiza_single': require('@/assets/img/icos/cotiza_single.svg')
      }
    }
  },
  computed: {
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isTypeFavorite () {
      return this.config_app.favorite
    }
  },
  created () {
    // console.log('product: ', this.product)
  },
  methods: {
    openModalCotiza () {
      this.$refs.modalCotiza.openModal()
    }
  }
}
</script>

<style lang="scss" scoped>
  .cotiza-common{
    display: inline-block;

    & > button{
      display: inline-block;
      min-width:140px;
      background-color:#eff3f6;
      border: 1px solid #E6E8E9;
      color: $color-text;
      text-align: center;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      font-size: 12px;
      text-transform: uppercase;
      cursor:pointer;
      @include roundAll(4);
      @include transition();

      em{
        color: $orange;
        display: inline-block;
        font-style: initial;
        margin-left: 6px;
      }

      &:hover, &:focus{
        background-color:$orange;
        color:$white;

        em{
          color: $white;
        }
      }
    }
  }

  .cotiza-single{
    width: 250px;
    background-color: #EBF3FE;
    padding: 16px;
    
    p{
      font-size: 14px;
      line-height: 18px;
    }

    .btn{
      background-color: #fff;
      color: $orange;
      text-transform: uppercase;
      font-size: 16px;
      border: 1px solid #E2E2E2;
      @include roundAll(6);
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin-top: 10px;
      padding: 0 10px;
    }
  }
</style>
