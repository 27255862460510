<template lang="pug">
  .leadOfertaCartList
    .box-title
      .container
        .row
          .col-sm-12
            p {{name}}

    .box-1
      .container
        .row
          .col-sm-12
            p #[translate Pedido]: {{numItems}} #[translate productos]
              button( class="btn btn-action" @click="goCatalog()") #[translate Añadir más productos]
              button( class="btn btn-white" @click="deleteCart()") #[translate Vaciar carro]

    .container.holder-cn
      .info-pedido-ok(v-if="messageShow" class="fade in")
        div
          p
            i(class="fa fa-check" aria-hidden="true")
          p
            strong #[translate] La Oferta se ha realizado con éxito.
          // p #[translate] Nº de Oferta: 21724
          // p #[translate] Puede ver el historial de ofertas en
            router-link( :to="{ name: 'ofertasList'}" class="" ) #[translate]Ofertas
          p
            router-link( :to="{ name: 'leadOferta'}" class="btn btn-action" ) #[translate] Volver al inicio
      .row
        .col-sm-9.section-cn
          div(v-if="messageError")
            p(class="alert alert-warning fade in" role="alert") {{messageError}}
          .head-list(v-if="items.length > 0")
            .big #[translate Artículos]
            .other.text-center #[translate Cantidad]
            .other.text-center #[translate Descuento]
            .other.text-center #[translate Facturable]
            .other.text-center #[translate Total]

          .item-list
            draggable( tag="div" :list="items" v-bind="dragOptions" @sort="changeSort" :sort="!dragdrop.senData")
              transition-group( type="transition" :name="!dragdrop.drag ? 'flip-list' : null")
                bk-item(v-for="item in items" :key="item.id" :product="item" :hasFacturable="hasFacturable" @toRemoveProduct="toRemoveProduct" @toUpdateProduct="toUpdateProduct")
          .cargos-list
            bk-cargos( :cargos="aCargos" @toSetCargo="toSetCargo")

          .box-payment(v-if="type_app === 'vario'")
            .container
              .row
                .col-sm-5
                  img(:src="img.payment")
                  translate Payment
                .col-sm-4
                  strong #[translate Payment options]:
                  .dropdown
                    button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPayment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") option 1
                      i(class="fa fa-angle-down" aria-hidden="true")
                    ul(class="dropdown-menu" aria-labelledby="ddPayment")
                      li
                        span option 1
                      li
                        span option 2
                      li
                        span option 3
                .col-sm-3
                  strong #[translate Discount]
                  em -2,58 {{moneda}}

          .container.box-2(v-if="type_app === 'vario'")
            .row
              .col-sm-8

              .col-sm-4
                ul
                  li #[translate Subtotal]: #[small {{base | format-money}} {{moneda}}]
                  li #[translate Discounts]: #[small -X,XX {{moneda}}]
                  li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                  li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]

          .container.box-2(v-else)
            .row
              .col-sm-8.info-centro

              .col-sm-4
                ul
                  li #[translate Subtotal]: #[small {{base | format-money}} {{moneda}}]
                  li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                  li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]

          .container.box-3(v-if="type_app === 'vario'" class="type-vario")
            .row
              .col-sm-8.c-l
                p
                  label #[translate Attach file (PO)]
                  input(type="file")
                p
                  label #[translate Comments]
                  textarea.form-control(:placeholder="placeholder.observaciones" v-model="observations")
              .col-sm-4.c-r
                div
                  label #[translate Save as]:
                  .dropdown
                    button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddSave" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") option 1
                      i(class="fa fa-angle-down" aria-hidden="true")
                    ul(class="dropdown-menu" aria-labelledby="ddSave")
                      li
                        span option 1
                      li
                        span option 2
                      li
                        span option 3
                div
                  label #[translate Send as]:
                  .dropdown
                    button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddSend" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") option 1
                      i(class="fa fa-angle-down" aria-hidden="true")
                    ul(class="dropdown-menu" aria-labelledby="ddSend")
                      li
                        span option 1
                      li
                        span option 2
                      li
                        span option 3

            .row-flex
              p #[translate Our general]&nbsp;
                |
                a(href="#" target="_blank") #[translate terms and conditions]
                |&nbsp;#[translate apply. These include our privacy policy].
              p.submit-cn
                button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                button(class="btn btn-action" :class="{'btn-disabled': !numItems, 'btn-send': enviando}" @click="finalizarPedido()")
                  translate(v-if="!enviando") Enviar
                  translate(v-if="enviando") Enviando

          .container.box-3(v-if="type_app !== 'vario'")
            .row
              .col-sm-7.obser-cn
                label #[translate Observaciones]:
                textarea.form-control(:placeholder="placeholder.observaciones" v-model="observations")
              .col-sm-5.text-right
                div(v-if=" this.role !== 'client'")
                translate Enviar como:
                .dropdown.Select-bk
                  button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{printFormatsName}}
                    i(class="fa fa-angle-down" aria-hidden="true")
                  ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                    li(v-for="item in printFormats" :class="{'disabled': printFormatsName == item.name}")
                      span(:value="item.id" @click="changeOptionPrintFormats(item.id, item.name)") {{item.name}}
                p.submit-cn
                  button(class="btn btn-cancel" @click="goCatalog()") #[translate Cancelar]
                  button(class="btn btn-action" :class="{'btn-disabled': !numItems, 'btn-send': enviando}" @click="finalizarPedido()")
                    translate(v-if="!enviando") Enviar
                    translate(v-if="enviando") Enviando

        .col-sm-3.aside-cn
          div.aside-box
            h4 #[translate Resumen pedido]:
            ul
              li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{moneda}}]
              li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
              li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]

</template>
<script>
import BkItem from '@/components/catalog/Item-cart.vue'
import BkCargos from '@/components/catalog/Cargos-list.vue'
import draggable from 'vuedraggable'

import SrvCatalog from '@/services/catalog'
import SrvOfertas from '@/services/ofertas'

import {mapGetters} from 'vuex'

export default {
  name: 'LeadOfertaCartList',
  components: { BkItem, BkCargos, draggable },
  data () {
    return {
      aCargos: [{}],
      observations: '',
      messageError: null,
      messageShow: null,
      hasFacturable: false,
      enviando: false,
      printFormats: [],
      printFormatsName: this.$gettext('Selecciona'),
      printFormatsId: null,
      placeholder: {
        'observaciones': this.$gettext('Observaciones')
      },
      dragdrop: {
        drag: true,
        senData: false // Si es true, no deja arrastrar. Lo usamos para que cuando se mande la nueva ordenación no se pueda cambiar.
      },
      img: {
        alerta: require('@/assets/img/icos/alerta-01.svg'),
        payment: require(`@/assets/img/icos/payment.svg`)
      }
    }
  },
  created () {
    if (this.role !== 'client') {
      this.getPrintFormats()
    }

    // Activar o desactivar el poder arrastrar los productos en el carrito.
    this.dragdrop.senData = !this.config_app.sort_cart_drag
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    numItems () {
      return this.items.length
    },
    lead () {
      return this.$store.getters.lead
    },
    id () {
      return this.lead.id
    },
    name () {
      return this.lead.name
    },
    // CART
    cart () {
      return this.$store.getters.cart
    },
    idCart () {
      return this.cart.idCart
    },
    base () {
      return this.cart.base
    },
    tax () {
      return this.cart.tax
    },
    total () {
      return this.cart.total
    },
    moneda () {
      return this.cart.moneda
    },
    items () {
      return this.cart.items
    },
    client_discount_percentage () {
      return this.cart.client_discount_percentage
    },
    client_discount () {
      return this.cart.client_discount
    },
    final () {
      return this.cart.final
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    changeOptionPrintFormats (id, name) {
      // console.log(`----- ${v}`)
      this.printFormatsName = name
      this.printFormatsId = id
    },
    goCatalog () {
      this.$router.push({name: 'leadOferta'})
    },
    setCart (item) {
      // console.log(`Añadir product:`, product)
      this.$store.dispatch('addCart', {idProduct: item.product, quantity: item.quantity, product: item})
    },
    deleteCart () {
      this.$store.dispatch('deleteCart')
      this.messageError = this.$gettext('Carro eliminado.')
    },
    toRemoveProduct (product) {
      // console.log(`Remove product: ${product.id}`)
      this.$store.dispatch('removeCartProduct', product)
    },
    toUpdateProduct (product) {
      // console.log(`update product:`, product)
      SrvCatalog.updateProduct(product)
        .then(res => {
          // console.log(res)
          // Actualizamos el carrito.
          this.$store.dispatch('getCart')
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    toSetCargo (cargo) {
      // console.log(`CartList Añadir carros.`, cargo)
      this.setCart(cargo)
    },
    getPrintFormats () {
      SrvOfertas.getPrintFormats()
        .then(res => {
          // console.log('getPrintFormats: ', res)
          if (res.status === 200) {
            const data = res.data
            // console.log('getPrintFormats: ', data)
            this.printFormats = data.results
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    finalizarPedido () {
      this.messageError = null
      this.messageShow = null

      // Si hay 0 elementos, no dejamos hacer pedido
      if (this.numItems && !this.enviando) {
        // console.log(`--- finalizar pedido ---`)
        this.enviando = true
        // console.log(`Envio Oferta`)
        SrvCatalog.setOffers(this.observations, this.printFormatsId)
          .then(res => {
            // console.log(`------> respuesta API finalización oferta: `, res)
            if (res.status === 200) {
              // Si todo va bien,  actualizamos el carrito.
              this.messageShow = true // this.$gettext('Oferta realizada con exito.')
              window.scrollTo(0, 0)
              this.$store.dispatch('deleteCart')
              // eliminamos el lead seleccionado si existe;
              this.$store.dispatch('removeLead')
              this.$store.commit('REMOVE_LEAD')
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              window.scrollTo(0, 0)
            }
            this.enviando = false
          }, () => {
            // console.log('----->' + error)
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
            this.enviando = false
          })
      }
    },
    changeSort () {
      // bloqueamos el arrastre.
      this.dragdrop.senData = true
      this.messageError = ''
      SrvCatalog.updateCart(this.items)
        .then(res => {
          // console.log('Carrito Actualizado..'')
          this.dragdrop.senData = false
        }, (error) => {
          // console.log(error.data.discount + '----->', error)
          if (error.status === 400 && error.data.discount !== undefined) {
            this.messageError = error.data.discount[0]
          } else if (error.status === 400) {
            this.messageError = error.data
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>

.box-title{
  background-color: rgba(#EFEFEF, .33);

  p{
    font-size: 18px;
    font-weight: $medium;
    margin: 14px 0 10px;
  }

}

.info-pedido-ok{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e6f7d4;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;

  &.error{
    background-color: #FFF1D3;
  }
  & > div{
    padding: 40px;
  }

  p{
    text-align: center;
    font-size: 22px;
    color: #5c6783;
    font-weight: $light;
    margin-bottom: 30px;
  }

  i{
    color: #ace174;
    border: 3px solid #ace174;
    @include roundAll100(50%);
    width: 70px;
    height: 70px;
    line-height: 64px;
    font-size: 40px;

    &.orange-ico{
      color: #FEC731;
      border-color: #FEC731;
    }
  }

  a{
    display: inline-block;
    margin-left: 6px;
  }

  strong{
    color: #2b569b;
    font-size: 30px;
    font-weight: $light;
  }
}


.box-1{
  padding: 14px 0;
  background-color: #EFEFEF;
  margin-bottom: 40px;

  p{
    font-size: 14px;
    color: #43425D;
    margin:0;
  }

  .btn-action{
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
  }
}

.section-cn{
  .head-list{
    border-bottom: 2px solid #17296E;
    display: flex;
    align-items: center;

    & > *{
      width: 13%;
      padding: 0 10px 10px;
    }

    .big{
      width: 61%; // 48%;
    }
  }
}

.cargos-list{
  margin-top: 14px;
}

.box-2{
  padding-top: 28px;
  // margin-top: 30px;
  // border-top: 2px solid #17296E;
  padding-bottom: 28px;
  // border-bottom: 1px solid $orange;

  .info-centro{

    & > * {
      display: inline-block;
      vertical-align: top;
      &:first-child{
        width: 124px;
      }
      &:last-child{
        background-color: rgba(#F1F4F7, .38);
        padding: 18px;
        font-size: 14px;
        color: #4D4F5C;
        width: 320px;
        min-height: 100px;
      }
    }

    .btn{
      font-size: 14px;
      white-space: normal;
      text-align: left;
    }
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li{
    font-size: 16px;
    color: #5b5d5e;
    margin-bottom: 14px;

    small{
      font-size: 16px;
      float: right;
    }

    &:last-child{
      font-size: 16px;
      margin-bottom: 0;

      small{
        font-weight: $medium;
        font-size: 16px;
        color: #1C2C40;
      }
    }
  }
}

.box-3{
  padding:20px 0 100px;
  border-top: 2px solid #17296E;

  .obser-cn{
    label{
      font-weight: $regular;
      color: #494f58;
      font-size: 14px;
      vertical-align: top;
      margin-right: 10px;
      width: 130px;
      padding-left: 15px;
    }

    textarea{
      display: inline-block;
      width:320px;
      height: 100px;
      border: 1px solid #d5d8dc;
    }
  }

  .Select-bk{
    width: 158px!important;
    // border: 1px solid #d5d8dc;
    margin-bottom: 16px;

    button{
      width: 100%!important;
    }
  }

  button{
    margin-right: 16px;
    width: 126px;

    &:last-child{
      margin-right: 0;
    }
  }

  .btn-action{
    font-size: 16px;
    font-weight: $regular;
    background-color: $orange;
    text-transform: none;

    &:hover, &:focus{
      background-color: darken($orange, 10%);
    }
  }

  .btn-cancel{
    font-size: 16px;
    font-weight: $regular;
  }

  .submit-cn{
    margin-top: 0;
  }

}

.box-3{
  &.type-vario{
    label{
      display: inline-block;
      font-size: 14px;
    }

    input, textarea{
      display: inline-block;
      width: 80%;
    }

    textarea{
      vertical-align: text-top;
    }

    .row-flex{
      border: 1px solid #707070;
      justify-content: flex-end;
      padding: 26px 14px;
      margin-top: 24px;

      p{
        margin:0 0 0 26px;
      }
    }

    .row{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .c-l{
      label{
        width: 20%;
      }

      & > *{
        margin:0;
        &:not(:last-child){
          margin-bottom: 15px;
        }
      }
    }

    .c-r{
      & > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child){
          margin-bottom: 15px;
        }
      }

      label{
        color: rgba(#1C2C40, .59);
        font-weight: $regular;
      }
    }

    .dropdown{
      width: 200px;
      margin-bottom: 0;
      button{
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.aside-box{
  margin-bottom: 30px;

  h4{
    font-size: 14px;
    font-weight: $medium;
    color:#1C2C40;
    border-bottom: 2px solid #17296E;
    padding: 4px 0 10px;
    margin:0 0 10px;
  }

  h5{
    font-size: 14px;

    & > *{
      display: inline-block;
      vertical-align: middle;
    }

    button{
      font-size: 14px;
      margin-left: 14px;
    }
  }

  ul{
    margin:0;
    padding:18px;
    list-style: none;
    background-color: rgba(#F1F4F7, .38);
  }

  li{
    font-size: 14px;
    font-weight: $regular;
    color: #5b5d5e;
    margin-bottom: 10px;

    &:last-child{
      small{
        font-size: 16px;
        font-weight: $medium;
      }
    }

    small{
      font-size: 14px;
      float:right;
    }
  }

  p{
    padding: 18px;
    font-size: 14px;
    font-weight: $regular;
    color: #4D4F5C;
    margin-bottom: 18px;
    background-color: rgba(#F1F4F7, .38);
  }
}
.dropdown{
  display:inline-block;
  margin-bottom: 8px;
  width: 122px;

  li, button{
    color: #494f58;
    font-size: 14px;
  }

  .dropdown-toggle{
    height: 38px;
    line-height: 38px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 50px;

    i{
      width:40px;
      line-height: 36px;
      background-color: transparent;
      color: $blue-light;
    }
  }
}

.box-payment{
  .row{
    padding: 30px 0;
    border-top: 2px solid #17296E;
    border-bottom: 2px solid #17296E;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      font-size: 14px;
      color: #1C2C40;

      &:first-child{
        padding-left:0;
        font-size: 16px;

        img{
          margin-right: 20px;
        }
      }

      &:last-child{
        text-align: right;
        padding-right: 0;

        em{
          background-color: #E2E2E2;
          min-width: 70px;
          height: 34px;
          line-height: 34px;
          text-align: right;
          display: inline-block;
          @include roundAll(2);
          padding: 0 8px;
          vertical-align: middle;
          font-size: 16px;
          font-weight: $medium;
        }
      }

      strong{
        font-weight: $regular;
        margin-right: 20px;
      }

      em{
        font-style: normal;
        color: #646464;
      }
    }
  }
}

</style>
