<template lang="pug">
  .ClientListItem(:class="{'type-pendiente': typeItem === 'pendientes', 'type-validado': typeItem === 'validados'}")
    .container
      .row.box-1
        .hit-cn.type-top(v-if="isTypeApp !== 'vario'" data-toggle="collapse" :data-target="`#demo-${typeItem}-${index}`" @click="actionShowDetalle")
        .col-sm-4.name-cn
          img(:src="img.derecha" width="8" class="img-cn")
          p {{order.center.name}}
        .col-sm-3.user-cn
          p {{nameUser}}
            a(:href="`mailto:${order.user_creator.email}`" target="_blank") {{order.user_creator.email}}
        .col-sm-1
          p.text-right {{order.number}}
        .col-sm-1
          p.text-center {{order.order_datetime | moment("DD-MM-YYYY")}}
        .col-sm-2
          p.text-right {{order.total | format-money}} {{order.currency_symbol}}
        .col-sm-1.delivery
          div.text-center
            img(v-if="typeItem === 'validados'" :src="img.validada" width="18" @click="goSingle()")
            img(v-if="typeItem === 'pendientes'" :src="img.noValidada" width="18" @click="goSingle()")
            

      .row.box-2(:id="`demo-${typeItem}-${index}`" class="collapse")
        .col-sm-6.col-md-3.c-1
            p #[translate Centro]: #[strong {{checkEmpty(order.center.address)}}]
            p #[translate Código de Centro]: #[strong {{checkEmpty(order.center.external_code)}}]
</template>
<script>
import BkGrid from '@/components/validar-pedidos/Home-listado-detalle.vue'

export default {
  name: 'ValidarPedidosListItem',
  props: ['order', 'typeItem', 'index'],
  components: { BkGrid },
  data () {
    return {
      state: null,
      showDetalle: false, // para que el componente BKGrid no haga la petición y traíga el detalle.
      img: {
        derecha: require('@/assets/img/icos/derecha.svg'),
        noValidada: require('@/assets/img/icos/ver-oferta-validada.svg'),
        validada: require('@/assets/img/icos/ver-oferta.svg')
      }
    }
  },
  created () {
    // TODO: Que se muestren los datos del usario cuando esté la API.
    // console.log(`---> `, order)
    this.state = this.order.state
    // console.log('oferta: ', this.order)
  },
  computed: {
    stateLabel () {
      let result = '-'
      if (this.order.state_values) {
        this.order.state_values.map(item => {
          if (item.code === this.state) {
            result = item.value
          }
        })
      }
      return result
    },
    nameUser () {
      if (this.order.user_creator && this.order.user_creator.first_name) {
        return `${this.order.user_creator.first_name}&nbsp;&nbsp;`
      }
      return ''
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    goSingle () {
      // console.log(`Oferta seleccionada: ${this.order.id}, type: ${this.typeItem}`)
      this.$router.push({name: 'validarOfertasSingle', params: {id: this.order.id, type: this.typeItem}})
    },
    actionShowDetalle () {
      this.showDetalle = true
    },
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    }
  }
}
</script>
<style lang="scss" scoped>
.ClientListItem{
  color:#494f58;

  &.type-pendiente{
    position: relative;
  }
}

.box-1{
  position: relative;
  @include transition();
  font-size: 14px;
  color: #4D4F5C;
  font-weight: $regular;
  border:1px solid #fff;
  // cursor: pointer;

  & > *{
    padding-top:12px;
    padding-bottom:8px;
  }

  .hit-cn{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    z-index: 1;
    cursor:pointer;
  }

  &:hover, &:focus{
    background-color: rgba(#129FE9, .2);
    border:1px solid #00ADFC;

    .name-cn{
      font-weight: $medium;
    }
  }

  p{
    margin:0;
  }

  .user-cn{
    p{
      @include ellipsis();
    }
  }
}

.name-cn{
  position: relative;
  p {
    text-transform: uppercase;
    @include ellipsis();
    cursor: pointer;
    padding-left: 8px;
  }
}

.delivery{
  img {
    cursor:pointer;
  }
}

.img-cn{
  position: absolute;
  top: 15px;
  left: 8px;
  @include transition();
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

[aria-expanded="true"]{
    .img-cn{
      -moz-transform: rotate(90deg) translate(0px);
      -o-transform: rotate(90deg) translate(0px);
      -ms-transform: rotate(90deg) translate(0px);
      -webkit-transform: rotate(90deg) translate(0px);
      transform: rotate(90deg) translate(0px);
    }
}

.date-cn, .num-cn{
  padding-left:0;
  padding-right: 0;
}

.state-cn{
  color: $orange;

  &.SERVIDO, &.S{
    color: $green;
  }
}

.box-2{
  padding: 16px 0 50px;
  background-color: #fff;
  border-bottom: 1px solid #F5F6FA;
  color: #4D4F5C;
  font-weight: $light;

  strong{
    font-weight: $regular;
  }
}
</style>
