<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "EvolutionMainNumberOpinion",
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "yAxes"],
  data: () => ({
    type: "line",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: true,
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 20,
          bottom: 10,
        },
      },
      showTooltips: false,
      clip: { left: 15, top: false, right: -2, bottom: 0 },
      scales: {
        clip: { left: 15, top: false, right: -2, bottom: 0 },
        yAxes: [
          {
            type: "linear",
            position: "left",

            ticks: {
              fontColor: "#567BCD",
              callback: function (value) {
                return value + " €";
              },
            },
            id: "y-axis-1",
          },
          {
            type: "linear",
            position: "right",
            id: "y-axis-2",
            ticks: {
              fontColor: "#AC0C72",
              callback: function (value) {
                return value + " kg";
              },
            },
            datalabels: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  }),
  watch: {
    "chartData.datasets": {
      deep: true,
      handler() {
        this.render();
      },
    },
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      if (this.yAxes) {
        this.options.scales.yAxes = this.yAxes
      }
      this.renderChart(
        JSON.parse(JSON.stringify(this.chartData)),
        this.options
      );
    },
  },
};
</script>
