<template lang="pug">
  #Footer.text-center
    .ft-cn( v-if=" type_app === 'prisma' ")
      ul
        li
          a(href="http://www.prismasl.com/servicios/" target="_blank") #[translate Servicios]
        li
          a(href="http://www.prismasl.com/contacto/" target="_blank") #[translate Contacto]
        li
          a(href="http://www.prismasl.com/calidad/" target="_blank") #[translate Calidad]
        li
          a(href="http://www.prismasl.com/empresa/grupo-orbisinpacs/" target="_blank") #[translate Grupo]
        li
          a(href="http://www.prismasl.com/empresa/nuestro-equipo/" target="_blank") #[translate Nuestro Equipo]
        li
          a(href="http://www.prismasl.com/empresa/nuestra-empresa/" target="_blank") #[translate Nuestra Empresa]
        li
          a(href="http://www.prismasl.com/blog/" target="_blank") #[translate Noticias]
      p
        small &copy; {{year}} #[translate Prisma SL]. #[translate All Rights Reserved].
    .ft-cn( v-if=" type_app === 'pedrosa' && !isPageCatalogoPublic")
      ul
        li
          a(href="http://www.pedrosa.net/servicios.php" target="_blank") #[translate Servicios]
        li
          a(href="http://www.pedrosa.net/contacto.php" target="_blank") #[translate Contacto]
        li
          a(href="http://www.pedrosa.net/marcas.php" target="_blank") #[translate Marca]
        li
          a(href="http://www.pedrosa.net/index.php" target="_blank") #[translate Nuestra Empresa]
        li
          a(href="http://www.pedrosa.net/politica-de-privacidad.php" target="_blank") #[translate Aviso legal y Política de privacidad]
      p
        small #[translate Comercial Pedrosa S.A. Copyright] &copy; {{year}}
    .ft-cn( v-if=" type_app === 'orbishigiene' ")
      ul
        li
          a(href="https://orbishigiene.com/aviso-legal/" target="_blank") #[translate Aviso legal]
        li
          a(href="https://orbishigiene.com/politica-cookies/" target="_blank") #[translate Política de Cookies]
      p
        small &copy; {{year}} #[translate All Rights Reserved]

    .ft-cn( v-if=" type_app === 'maripol' ")
      ul
        li
          a(href="https://www.maripolroyal.net/aviso-legal" target="_blank") #[translate Aviso legal]
        li
          a(href="https://www.maripolroyal.net/politica-de-calidad" target="_blank") #[translate Política de calidad]
      p
        small &copy; {{year}} #[translate All Rights Reserved]
    p.logo-cn
      img(:src="imgLogo" alt="")

    button.hidden(v-if="this.type_app !== 'prisma'" data-toggle="modal" data-target="#modal-common"  class="btn-modal" ref="showModalMsg")
    button.hidden(v-else data-toggle="modal" data-target="#modal-common"  data-keyboard="false" data-backdrop="false" class="btn-modal" ref="showModalMsg")
    bk-msg(:title="titleModalMSg" :content="templateModalMsg" footer="" ref="modalMsg")
</template>
<script>
import BkMsg from '@/components/common/Modal.vue'
import MixinCommons from '@/mixins/commons.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  components: {BkMsg},
  mixins: [MixinCommons],
  data() {
    return {
      imgLogo: require('@/assets/img/l_ecompro.png'),
      titleModalMSg: this.$gettext('¡Aviso importante!'),
      img: {
        alerta: require('@/assets/img/icos/alerta-01.svg')
      }
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ]),
    templateModalMsg() {
      if (this.type_app !== 'prisma') {
        const msg = this.$gettext('Durante la mañana del <strong>jueves 24 de octubre</strong> esta web estará inhabilitada.<br>Pueden ponerse en contacto con nuestro departamento de atención al cliente en el tfno. <strong>902 33 22 20</strong>.<br>Disculpen las molestias.')
        return `<p style="text-align:center;padding-top:20px;"><img src="${this.img.alerta}" width="60" /></p>
                <p style="text-align:center;padding-top:10px;font-size: 16px;">${msg}</p>
                <p style="text-align:center;padding:20px 0 10px;"><button type="button" data-dismiss="modal" class="btn btn-action">Entendido</button></p>`
      } else {
        const msg = this.$gettext('Por motivos técnicos causados por nuestro proveedor de servicios de internet esta web no está disponible temporalmente. Pueden ponerse en contacto con su comercial o administrativo. Disculpen las molestias')
        return `<p style="text-align:center;padding-top:20px;"><img src="${this.img.alerta}" width="60" /></p>
                <p style="text-align:center;padding-top:10px;font-size: 16px;">${msg}</p>
                <p style="text-align:center;padding:20px 0 10px;"></p>`
      }
    },
    config_app() {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    type_app() {
      return this.$config.type
    },
    year() {
      return (new Date()).getFullYear()
    },
    isPageCatalogoPublic() {
      if (
        this.config_app.catalogo_public &&
        (
          this.$route.name === 'catalogoListPublic' ||
          this.$route.name === 'productSinglePublic' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        return true
      }

      return false
    },
    currentToken() {
      return window.localStorage.getItem('_token')
    },
    iframeVariation() {
      return this.$store.getters.variation
    }
  },
  methods: {
    refreshBot() {
      const iframeNode = document.getElementById("bot-iframe");
      iframeNode.src = `https://iadicas.kelmia.com/?token=${this.currentToken}`
    },
  },
  watch: {
    iframeVariation() {
      if (this.iframeVariation > 0) this.refreshBot()
    }
  },
  mounted() {
    if (this.config_app.popup_aviso) {
      setTimeout(() => {
        this.$refs.showModalMsg.click()
      }, 1000)
    }

    const currentUser = this.$store.getters.user
    console.log({currentUser, logging: this.isLoggedIn})
    if (this.isLoggedIn && currentUser?.chatbot) {
      const iframeUrl = 'https://iadicas.kelmia.com'
      const iframeContainerNode = document.getElementById("chat-bot-container")
      const iframeNode = document.createElement("iframe");
      iframeNode.src = `${iframeUrl}?token=${this.currentToken}`
      iframeNode.id = "bot-iframe"
      iframeNode.style.position = "fixed"
      iframeNode.style.bottom = "20px"
      iframeNode.style.right = "20px"
      iframeNode.style.borderRadius = "20px"
      iframeNode.style.zIndex = "100"
      iframeNode.style.maxHeight = "95dvh"
      iframeNode.width = "100px"
      iframeNode.height = "100px"

      if (iframeContainerNode && iframeNode) {
        iframeContainerNode.appendChild(iframeNode)
      }

      // Parent page code
      window.addEventListener('message', (event) => {
        const allowedOrigins = [iframeUrl];
        const {type, payload} = event.data;

        if (type === 'iframe-event') {
          if (!allowedOrigins.includes(event.origin)) {
            console.warn('Origin not allowed:', event.origin);
            return;
          }
          if (payload.message === "OPEN") {
            iframeNode.width = "400px"
            iframeNode.height = "700px"
            iframeNode.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.3)"
          } else {
            iframeNode.width = "100px"
            iframeNode.height = "100px"
            iframeNode.style.boxShadow = "none"
          }
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.ft-cn {
  background-color: $blue-bg;
  padding: 30px 20px 20px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px 14px;

  a {
    font-size: 15px;
    color: $white;
    color: rgba($white, 0.5);
  }
}

small {
  font-size: 14px;
  color: $white;
  color: rgba($white, 0.3);
}

.logo-cn {
  padding: 22px;
}
</style>
