<template lang="pug">
  .facturas-search
    form(@submit.prevent="toSearch" @keyup.enter="toSearch")
      .input-group
        input(ref="search" id="product-search-query" class="form-control" type="search" v-model="q" minlength="2" :placeholder="placeholder.search")
        span.input-group-btn
          button.btn.btn-action(type="submit")
            img(:src="img.lupa" width="16" title="Search")
</template>
<script>
export default {
  name: 'FacturaSearch',
  props: ['num'],
  data () {
    return {
      q: null,
      placeholder: {
        'search': this.$gettext('Buscar factura: Nombre Centro, Nº de factura...')
      },
      img: {
        lupa: require('@/assets/img/icos/buscar.svg')
      }
    }
  },
  computed: {
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    toSearch () {
      let query = this.q

      if (query !== null && query !== '' && query.length > 1) {
        // console.log(`to search: ${query},  ${query.length}`)
        this.$emit('toSearch', [query])
      }
    }
  }
}
</script>
<style lang="scss" scoped>

  input[type="search"]{
    height:40px;
    line-height: 40px;
  }
  .btn{
    padding-top:0;
    padding-bottom:0;
    height:40px;
    line-height: 38px;
    &.type-mb{
      background-color: #E2231A;
      &:hover{
        background-color: #a8160e;
      }
    }
    img{
      max-width: none;
      
    }
  }

</style>
